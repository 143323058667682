
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../config/apiConfig';
import { Link } from 'react-router-dom';

function MainContent() {
    const [searchQuery, setSearchQuery] = useState('');
    const [patientDetails, setPatientDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.patientId) {
            const { patientId } = location.state;
            fetchPatientDetails(patientId);
        } else {
            console.error('No patient ID provided in location state');
        }
    }, [location.state]);

    const fetchPatientDetails = async (patientId) => {
        const key = localStorage.getItem('jwtToken');

        try {
            const response = await fetch(`${BASE_URL}/registerDocHs/get-patient-details-personal?patientId=${patientId}`, {
                headers: {
                    'Auth': `Bearer ${key}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPatientDetails(data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const detailFieldStyle = {
        flex: '1',
        marginRight: '20px',
        color: 'white',
        
        
    };

    const gradientStyle = {
        display: 'flex',
        background: 'linear-gradient(to right, #656565, #ADADAD)',
        alignItems: 'center',
        padding: '6px',
       
       
    };

    const searchInputStyle = {
        marginLeft: 'auto',
        width: '300px',
        height: '30px',
    };

    const buttonContainerStyle = {
        display: 'flex',
        alignItems: 'center',
    };
    const patientDetailsStyle = {
        marginTop: '10px',
    };
    // if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const patientDetailArray = [
        { label: 'Name:', value: patientDetails.patientName },
        { label: 'Number:', value: patientDetails.mobileNo },
        { label: 'Consultation Type:', value: patientDetails.complaint },
        { label: 'Date:', value: patientDetails.localDate },
        { label: 'Case No:', value: patientDetails.caseNo },
        { label: 'Age:', value: patientDetails.age },
        { label: 'Gender:', value: patientDetails.gender },
        { label: 'Blood Group:', value: patientDetails.bloodGroup }
    ];

    return (
        <div className="main-content">
        

        <div className="patient-details" style={patientDetailsStyle}>
                <div className="first-row" style={gradientStyle}>
                    {patientDetailArray.slice(0, 4).map((detail, index) => (
                        <div key={index} className="detail-field" style={detailFieldStyle}>
                            <span>{detail.label}</span> <span>{detail.value || 'N/A'}</span>
                        </div>
                    ))}
                </div>
                <div className="second-row" style={gradientStyle}>
                    {patientDetailArray.slice(4, 8).map((detail, index) => (
                        <div key={index} className="detail-field" style={detailFieldStyle}>
                            <span>{detail.label}</span> <span>{detail.value || 'N/A'}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MainContent;
