// // import React, { useState } from 'react';
// // import styled from 'styled-components';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faUserMd, faUser, faUserPlus, faUserShield } from '@fortawesome/free-solid-svg-icons';
// // import { Line, Pie } from 'react-chartjs-2';
// // import { Chart as ChartJS, Title as ChartTitle, Tooltip, Legend, LineElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';
// // import Sidebar from './Sidebar'; // Adjust the path as necessary
// // import Header from './Header'; // Adjust the path as necessary
// // import Patientlist from './patientlist'
// // // Initialize Chart.js
// // ChartJS.register(ChartTitle, Tooltip, Legend, LineElement, CategoryScale, LinearScale, ArcElement);

// // // Styled-components for the card layout
// // const Card = styled.div`
// //   max-width: 100%;
// //   margin: 20px 0;
// //   padding: 20px;
// // `;

// // const SidebarStyled = styled.div`
// //   position: fixed; /* Fixes the sidebar in place */
// //   top: 0;
// //   left: 0;
// //   height: 100vh; /* Full height of the viewport */
// //   width: 250px; /* Adjust width as needed */



// //   overflow-y: auto; /* Scroll if content overflows */
// //   z-index: 1000; /* Ensure it stays on top of other content */
// // `;

// // const Container = styled.div`
// //   display: flex;
// //   flex-wrap: wrap;
// //   gap: 20px;
// // `;
// // const ChartWrapper = styled.div`
// //   height: 250px; /* Adjust the height to your preference */
// //   width: 100%;
// //   display: flex; /* Use flexbox for centering */
// //   justify-content: center; /* Center horizontally */
// //   align-items: center; /* Center vertically */
// //   margin-top: 20px;
// // `;
// // const ChartContainer = styled.div`
// //   flex: 1 1 calc(50% - 20px); // Flex-grow, flex-shrink, and width calculation
// //   max-width: 500px; // Set a maximum width for the charts
// //   padding: 20px;
// //   background-color: #fff; // Card background color
// //   border-radius: 12px; // Rounded corners
// //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); // Shadow effect
// //   margin-top: 20px;
// //   height: 350px;

// //   @media (max-width: 768px) {
// //     flex: 1 1 100%; // Stack charts on smaller screens
// //   }
// // `;

// // const BaseItem = styled.div`
// //   position: relative;
// //   flex: 1 1 200px;
// //   display: flex;
// //   align-items: center;
// //   padding: 20px;
// //   border: 1px solid #ddd;
// //   border-radius: 5px;
// //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// //   height: 100px;
// //   background-color: #f9f9f9;
// // `;

// // const CountWrapper = styled.div`
// //   position: absolute;
// //   bottom: 10px;
// //   left: 10px;
// //   font-size: 24px;
// //   font-weight: bold;
// //   color: #333;
// // `;

// // const IconWrapper = styled.div`
// //   position: absolute;
// //   top: 10px;
// //   right: 10px;
// //   display: flex;
// //   align-items: center;
// // `;

// // const Text = styled.div`
// //   display: flex;
// //   align-items: center;
// //   flex: 1;
// //   margin-right: 50px;
// //   text-align: left;
// // `;

// // const Layout = styled.div`
// //   display: flex;
// //   background-color: #E0FFFF;
// //   min-height: 100vh;
// //   margin-left: 250px; /* Leave space for the fixed sidebar */
// //   padding: 20px; /* Optional padding for better spacing */
// //   overflow-y: auto; /* Scroll if content overflows */
// // `;

// // const ChartTitleStyled = styled.div`
// //   margin-bottom: 20px;
// //   font-size: 16px;
// // `;

// // const Count = styled.span`
// //   font-size: 24px;
// //   font-weight: bold;
// //   margin-left: 10px;
// // `;
// // // Updated SidebarStyled with fixed positioning and independent scrolling

// // const YellowItem = styled(BaseItem)`
// //   background-color: rgba(218, 165, 32, 0.2); // Light Yellow
// // `;

// // const GreenItem = styled(BaseItem)`
// //   background-color: #C8E6C9; // Light Green
// // `;

// // const RedItem = styled(BaseItem)`
// //   background-color: #FFCDD2; // Light Red
// // `;

// // const BlueItem = styled(BaseItem)`
// //   background-color: #E3F2FD; // Light Blue
// // `;

// // const ResponsiveContainer = styled(Container)`
// //   @media (max-width: 1200px) {
// //     justify-content: space-around;
// //   }

// //   @media (max-width: 800px) {
// //     justify-content: center;
// //   }
// // `;

// // const Content = styled.div`
// //   flex: 1;
// //   padding: 0;
// // `;

// // const HeaderContainer = styled.div`
// //   margin: 0;
// // `;

// // const Icon = styled(FontAwesomeIcon)`
// //   font-size: 24px;
// // `;

// // // Chart component for patient registration by time period
// // const PatientRegistrationChart = ({ data, period }) => {
// //   const chartData = {
// //     labels: period === 'months' ? data.months : data.days,
// //     datasets: [
// //       {
// //         label: 'Male',
// //         data: period === 'months' ? data.monthlyMaleCounts : data.dailyMaleCounts,
// //         borderColor: 'rgba(75, 192, 192, 1)',
// //         backgroundColor: 'rgba(75, 192, 192, 0.2)',
// //         borderWidth: 1,
// //       },
// //       {
// //         label: 'Female',
// //         data: period === 'months' ? data.monthlyFemaleCounts : data.dailyFemaleCounts,
// //         borderColor: 'rgba(255, 99, 132, 1)',
// //         backgroundColor: 'rgba(255, 99, 132, 0.2)',
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   const options = {
// //     responsive: true,
// //     scales: {
// //       x: {
// //         title: {
// //           display: true,
// //           text: period === 'months' ? 'Month' : 'Day',
// //         },
// //       },
// //       y: {
// //         title: {
// //           display: true,
// //           text: 'Number of Patients',
// //         },
// //       },
// //     },
// //   };

// //   return <Line data={chartData} options={options} />;
// // };

// // // New component for gender-based appointments pie chart
// // const GenderPieChart = ({ data }) => {
// //   const chartData = {
// //     labels: ['Male', 'Female'],
// //     datasets: [
// //       {
// //         data: [data.totalMaleAppointments, data.totalFemaleAppointments],
// //         backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
// //         borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   const options = {
// //     responsive: true,
// //     plugins: {
// //       legend: {
// //         position: 'top',
// //       },
// //       tooltip: {
// //         callbacks: {
// //           label: function (context) {
// //             let label = context.label || '';
// //             if (label) {
// //               label += ': ';
// //             }
// //             if (context.parsed !== null) {
// //               label += context.parsed + ' appointments';
// //             }
// //             return label;
// //           },
// //         },
// //       },
// //     },
// //   };

// //   return (
// //     <ChartWrapper>
// //       <Pie data={chartData} options={options} /> {/* Set the height here */}
// //     </ChartWrapper>
// //   );
// // };


// // const TotalCounts = () => {
// //   const [doctorCount, setDoctorCount] = useState(2);
// //   const [patientCount, setPatientCount] = useState(5);
// //   const [newPatientCount, setNewPatientCount] = useState(5);
// //   const [healthOfficerCount, setHealthOfficerCount] = useState(1);
// //   const [data, setData] = useState({
// //     months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
// //     days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
// //     monthlyMaleCounts: [10, 20, 30, 40, 50, 60, 70, 0, 90, 100, 110, 120],
// //     monthlyFemaleCounts: [15, 25, 35, 45, 55, 65, 75, 85, 95, 105, 115, 125],
// //     dailyMaleCounts: [1, 2, 0, 4, 5, 0, 7],
// //     dailyFemaleCounts: [2, 3, 1, 5, 6, 7, 8],
// //     totalMaleAppointments: 60, // Example data for pie chart
// //     totalFemaleAppointments: 90 // Example data for pie chart
// //   });
// //   const [period, setPeriod] = useState('months'); // State to track the selected period

// //   return (
// //     <Layout>
// //        <SidebarStyled>
// //     <Sidebar />
// //   </SidebarStyled> {/* Use SidebarStyled instead of Sidebar */}
// //       <Content>
// //         <HeaderContainer>
// //           <Header />
// //         </HeaderContainer>
// //         <Card>
// //           <ResponsiveContainer>
// //             <YellowItem>
// //               <IconWrapper>
// //                 <Icon icon={faUserMd} />
// //               </IconWrapper>
// //               <Text>
// //                 <ChartTitleStyled>Total Doctors</ChartTitleStyled>
// //               </Text>
// //               <CountWrapper>{doctorCount}</CountWrapper>
// //             </YellowItem>
// //             <GreenItem>
// //               <IconWrapper>
// //                 <Icon icon={faUser} />
// //               </IconWrapper>
// //               <Text>
// //                 <ChartTitleStyled>Total Patients</ChartTitleStyled>
// //               </Text>
// //               <CountWrapper>{patientCount}</CountWrapper>
// //             </GreenItem>
// //             <RedItem>
// //               <IconWrapper>
// //                 <Icon icon={faUserPlus} />
// //               </IconWrapper>
// //               <Text>
// //                 <ChartTitleStyled>New Patients</ChartTitleStyled>
// //               </Text>
// //               <CountWrapper>{newPatientCount}</CountWrapper>
// //             </RedItem>
// //             <BlueItem>
// //               <IconWrapper>
// //                 <Icon icon={faUserShield} />
// //               </IconWrapper>
// //               <Text>
// //                 <ChartTitleStyled>Total Health Officers</ChartTitleStyled>
// //               </Text>
// //               <CountWrapper>{healthOfficerCount}</CountWrapper>
// //             </BlueItem>
// //           </ResponsiveContainer>
// //           <Container>
// //             <ChartContainer>
// //               <ChartTitleStyled>Patient Registration by {period}</ChartTitleStyled>
// //               <PatientRegistrationChart data={data} period={period} />
// //               <button onClick={() => setPeriod('months')}>Monthly</button>
// //               <button onClick={() => setPeriod('days')}>Daily</button>
// //             </ChartContainer>
// //             <ChartContainer>
// //               <ChartTitleStyled>Gender-based Appointments</ChartTitleStyled>
// //               <GenderPieChart data={data} />
// //             </ChartContainer>
// //             <Patientlist />
// //           </Container>
// //         </Card>
// //       </Content>
// //     </Layout>
// //   );
// // };

// // export default TotalCounts;
// // ==========================kowkoko======================================================


// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUserMd, faUser, faUserPlus, faUserShield } from '@fortawesome/free-solid-svg-icons';
// import { Line, Pie } from 'react-chartjs-2';
// import { Chart as ChartJS, Title as ChartTitle, Tooltip, Legend, LineElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';
// import Sidebar from './Sidebar'; // Adjust the path as necessary
// import Header from './Header'; 
// import Patientlist from './patientlist'
// // Initialize Chart.js
// ChartJS.register(ChartTitle, Tooltip, Legend, LineElement, CategoryScale, LinearScale, ArcElement);

// // Styled-components for the card layout
// const Card = styled.div`
//   max-width: 100%;
//   margin: 20px 0;
//   padding: 20px;
// `;

// const SidebarStyled = styled.div`
//   position: fixed; /* Fixes the sidebar in place */
//   top: 0;
//   left: 0;
//   height: 100vh; /* Full height of the viewport */
//   width: 250px; /* Adjust width as needed */



//   overflow-y: auto; /* Scroll if content overflows */
//   z-index: 1000; /* Ensure it stays on top of other content */
// `;

// const Container = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 20px;
// `;
// const ChartWrapper = styled.div`
//   height: 250px; /* Adjust the height to your preference */
//   width: 100%;
//   display: flex; /* Use flexbox for centering */
//   justify-content: center; /* Center horizontally */
//   align-items: center; /* Center vertically */
//   margin-top: 20px;
// `;
// const ChartContainer = styled.div`
//   flex: 1 1 calc(50% - 20px); // Flex-grow, flex-shrink, and width calculation
//   max-width: 500px; // Set a maximum width for the charts
//   padding: 20px;
//   background-color: #fff; // Card background color
//   border-radius: 12px; // Rounded corners
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); // Shadow effect
//   margin-top: 20px;
//   height: 350px;

//   @media (max-width: 768px) {
//     flex: 1 1 100%; // Stack charts on smaller screens
//   }
// `;

// const BaseItem = styled.div`
//   position: relative;
//   flex: 1 1 200px;
//   display: flex;
//   align-items: center;
//   padding: 20px;
//   border: 1px solid #ddd;
//   border-radius: 5px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   height: 100px;
//   background-color: #f9f9f9;
// `;

// const CountWrapper = styled.div`
//   position: absolute;
//   bottom: 10px;
//   left: 10px;
//   font-size: 24px;
//   font-weight: bold;
//   color: #333;
// `;

// const IconWrapper = styled.div`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   display: flex;
//   align-items: center;
// `;

// const Text = styled.div`
//   display: flex;
//   align-items: center;
//   flex: 1;
//   margin-right: 50px;
//   text-align: left;
// `;

// const Layout = styled.div`
//   display: flex;
//   background-color: #E0FFFF;
//   min-height: 100vh;
//   margin-left: 250px; /* Leave space for the fixed sidebar */
//   padding: 20px; /* Optional padding for better spacing */
//   overflow-y: auto; /* Scroll if content overflows */
// `;

// const ChartTitleStyled = styled.div`
//   margin-bottom: 20px;
//   font-size: 16px;
// `;

// const Count = styled.span`
//   font-size: 24px;
//   font-weight: bold;
//   margin-left: 10px;
// `;
// // Updated SidebarStyled with fixed positioning and independent scrolling

// const YellowItem = styled(BaseItem)`
//   background-color: rgba(218, 165, 32, 0.2); // Light Yellow
// `;

// const GreenItem = styled(BaseItem)`
//   background-color: #C8E6C9; // Light Green
// `;

// const RedItem = styled(BaseItem)`
//   background-color: #FFCDD2; // Light Red
// `;

// const BlueItem = styled(BaseItem)`
//   background-color: #E3F2FD; // Light Blue
// `;

// const ResponsiveContainer = styled(Container)`
//   @media (max-width: 1200px) {
//     justify-content: space-around;
//   }

//   @media (max-width: 800px) {
//     justify-content: center;
//   }
// `;

// const Content = styled.div`
//   flex: 1;
//   padding: 0;
// `;

// const HeaderContainer = styled.div`
//   margin: 0;
// `;

// const Icon = styled(FontAwesomeIcon)`
//   font-size: 24px;
// `;

// // Chart component for patient registration by time period
// const PatientRegistrationChart = ({ data, period }) => {
//   const chartData = {
//     labels: period === 'months' ? data.months : data.days,
//     datasets: [
//       {
//         label: 'Male',
//         data: period === 'months' ? data.monthlyMaleCounts : data.dailyMaleCounts,
//         borderColor: 'rgba(75, 192, 192, 1)',
//         backgroundColor: 'rgba(75, 192, 192, 0.2)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Female',
//         data: period === 'months' ? data.monthlyFemaleCounts : data.dailyFemaleCounts,
//         borderColor: 'rgba(255, 99, 132, 1)',
//         backgroundColor: 'rgba(255, 99, 132, 0.2)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: period === 'months' ? 'Month' : 'Day',
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: 'Number of Patients',
//         },
//       },
//     },
//   };

//   return <Line data={chartData} options={options} />;
// };

// const GenderPieChart = ({ data }) => {
//   const chartData = {
//     labels: ['Male', 'Female'],
//     datasets: [
//       {
//         data: [data.totalMaleAppointments, data.totalFemaleAppointments],
//         backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
//         borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
//         borderWidth: 1,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       tooltip: {
//         callbacks: {
//           label: function (context) {
//             let label = context.label || '';
//             if (label) {
//               label += ': ';
//             }
//             if (context.parsed !== null) {
//               label += context.parsed + ' appointments';
//             }
//             return label;
//           },
//         },
//       },
//     },
//   };

//   return (
//     <ChartWrapper>
//       <Pie data={chartData} options={options} />
//     </ChartWrapper>
//   );
// };

// const TotalCounts = () => {
//   const [doctorCount, setDoctorCount] = useState(2);
//   const [patientCount, setPatientCount] = useState(5);
//   const [newPatientCount, setNewPatientCount] = useState(5);
//   const [healthOfficerCount, setHealthOfficerCount] = useState(1);
//   const [data, setData] = useState({
//     totalMaleAppointments: 0,
//     totalFemaleAppointments: 0,
//   });

//   useEffect(() => {
//     const fetchGenderCounts = async () => {
//       try {
//         const response = await fetch('http://localhost:7075/user/genderwise-count', {
//           method: 'GET',
//           headers: {
//             'Auth': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJob3NwaXRhbEBnbWFpbC5jb20iLCJpYXQiOjE3MjQ0NzgwNjEsImV4cCI6MTcyNTA4Mjg2MX0.uB_0aDjOykb31Vz_bxjJg2eTMHz6lhYhxSPCiV5JL8BEkk4lBUbTEYKwNX6575evJcI2zH-ek_l-zMzXpRwjBA',
//           },
//         });
//         const result = await response.json();
//         setData({
//           totalMaleAppointments: result.maleCount,
//           totalFemaleAppointments: result.femaleCount,
//         });
//       } catch (error) {
//         console.error('Error fetching gender-wise counts:', error);
//       }
//     };

//     fetchGenderCounts();
//   }, []);
// useEffect(() => {
//     // Fetch data from the API
//     fetch('http://localhost:7075/user/count', {
//       method: 'GET',
//       headers: {
//         'Auth': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJob3NwaXRhbEBnbWFpbC5jb20iLCJpYXQiOjE3MjQ0NzgwNjEsImV4cCI6MTcyNTA4Mjg2MX0.uB_0aDjOykb31Vz_bxjJg2eTMHz6lhYhxSPCiV5JL8BEkk4lBUbTEYKwNX6575evJcI2zH-ek_l-zMzXpRwjBA'
//       }
//     })
//       .then(response => response.json())
//       .then(data => {
//         // Update state with the fetched data
//         setDoctorCount(data.doctorCount);
//         setPatientCount(data.patientCount);
//         setNewPatientCount(data.patientsRegisteredToday);
//         setHealthOfficerCount(data.healthOfficerCount);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []); 

//   return (
//     <Layout>
//        <SidebarStyled>
//     <Sidebar />
//   </SidebarStyled> {/* Use SidebarStyled instead of Sidebar */}
//       <Content>
//         <HeaderContainer>
//           <Header />
//         </HeaderContainer>
//         <Card>
//           <ResponsiveContainer>
//             <YellowItem>
//               <IconWrapper>
//                 <Icon icon={faUserMd} />
//               </IconWrapper>
//               <Text>
//                 <ChartTitleStyled>Total Doctors</ChartTitleStyled>
//               </Text>
//               <CountWrapper>{doctorCount}</CountWrapper>
//             </YellowItem>
//             <GreenItem>
//               <IconWrapper>
//                 <Icon icon={faUser} />
//               </IconWrapper>
//               <Text>
//                 <ChartTitleStyled>Total Patients</ChartTitleStyled>
//               </Text>
//               <CountWrapper>{patientCount}</CountWrapper>
//             </GreenItem>
//             <RedItem>
//               <IconWrapper>
//                 <Icon icon={faUserPlus} />
//               </IconWrapper>
//               <Text>
//                 <ChartTitleStyled>New Patients</ChartTitleStyled>
//               </Text>
//               <CountWrapper>{newPatientCount}</CountWrapper>
//             </RedItem>
//             <BlueItem>
//               <IconWrapper>
//                 <Icon icon={faUserShield} />
//               </IconWrapper>
//               <Text>
//                 <ChartTitleStyled>Total Health Officers</ChartTitleStyled>
//               </Text>
//               <CountWrapper>{healthOfficerCount}</CountWrapper>
//             </BlueItem>
//           </ResponsiveContainer>
//           <Container>
//             <ChartContainer>
//               <ChartTitleStyled>Patient Registration by {period}</ChartTitleStyled>
//               <PatientRegistrationChart data={data} period={period} />
//               <button onClick={() => setPeriod('months')}>Monthly</button>
//               <button onClick={() => setPeriod('days')}>Daily</button>
//             </ChartContainer>
//             <ChartContainer>
//               <ChartTitleStyled>Gender-based Appointments</ChartTitleStyled>
//               <GenderPieChart data={data} />
//             </ChartContainer>
//             <Patientlist />
//           </Container>
//         </Card>
//       </Content>
//     </Layout>
//   );
// };

// export default TotalCounts;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd, faUser, faUserPlus, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title as ChartTitle, Tooltip, Legend, LineElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';
import Sidebar from './Sidebar'; // Adjust the path as necessary
import Header from './Header'; 
import Patientlist from './patientlist';
import { BASE_URL } from '../config/apiConfig';
ChartJS.register(ChartTitle, Tooltip, Legend, LineElement, CategoryScale, LinearScale, ArcElement);

const Card = styled.div`
  max-width: 100%;
  margin: 20px 0;
  padding: 20px;
`;

const SidebarStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  overflow-y: auto;
  z-index: 1000;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
const ChartWrapper = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const ChartContainer = styled.div`
  flex: 1 1 calc(50% - 20px);
  max-width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  height: 350px;

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

const BaseItem = styled.div`
  position: relative;
  flex: 1 1 200px;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100px;
  background-color: #f9f9f9;
`;

const CountWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 50px;
  text-align: left;
`;

const Layout = styled.div`
  display: flex;
  background-color: #E0FFFF;
  min-height: 100vh;
  margin-left: 250px;
  padding: 20px;
  overflow-y: auto;
`;

const ChartTitleStyled = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
`;

const Count = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-left: 10px;
`;

const YellowItem = styled(BaseItem)`
  background-color: rgba(218, 165, 32, 0.2);
`;

const GreenItem = styled(BaseItem)`
  background-color: #C8E6C9;
`;

const RedItem = styled(BaseItem)`
  background-color: #FFCDD2;
`;

const BlueItem = styled(BaseItem)`
  background-color: #E3F2FD;
`;

const ResponsiveContainer = styled(Container)`
  @media (max-width: 1200px) {
    justify-content: space-around;
  }

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 0;
`;

const HeaderContainer = styled.div`
  margin: 0;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 24px;
`;
const PatientRegistrationChart = ({ period }) => {
  const [data, setData] = useState({
    months: [],
    days: [],
    monthlyMaleCounts: [],
    monthlyFemaleCounts: [],
    dailyMaleCounts: [],
    dailyFemaleCounts: [],
  });
 const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      console.error('No JWT token available for authentication');
      return;
    }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/user/patient/registrations?type=${period}`, {
          method: 'GET',
          headers: {
            'Auth': `Bearer ${jwtToken}`
          },
        });
        const result = await response.json();

        if (period === 'months') {
          const labels = Object.keys(result);
          const maleCounts = labels.map(month => result[month].Male);
          const femaleCounts = labels.map(month => result[month].Female);

          setData({
            months: labels,
            days: [], // Not used for months
            monthlyMaleCounts: maleCounts,
            monthlyFemaleCounts: femaleCounts,
            dailyMaleCounts: [], // Not used for days
            dailyFemaleCounts: [], // Not used for days
          });
        } else if (period === 'days') {
          const labels = Object.keys(result);
          const maleCounts = labels.map(day => result[day].Male);
          const femaleCounts = labels.map(day => result[day].Female);

          setData({
            months: [], // Not used for days
            days: labels,
            monthlyMaleCounts: [], // Not used for months
            monthlyFemaleCounts: [], // Not used for months
            dailyMaleCounts: maleCounts,
            dailyFemaleCounts: femaleCounts,
          });
        }
      } catch (error) {
        console.error('Error fetching patient registrations:', error);
      }
    };

    fetchData();
  }, [period]);

  const chartData = {
    labels: period === 'months' ? data.months : data.days,
    datasets: [
      {
        label: 'Male',
        data: period === 'months' ? data.monthlyMaleCounts : data.dailyMaleCounts,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 1,
      },
      {
        label: 'Female',
        data: period === 'months' ? data.monthlyFemaleCounts : data.dailyFemaleCounts,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: period === 'months' ? 'Month' : 'Day',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Patients',
        },
      },
    },
  };

  return <ChartWrapper><Line data={chartData} options={options} /></ChartWrapper>;
};
const GenderPieChart = ({ data }) => {
  const chartData = {
    labels: ['Male', 'Female'],
    datasets: [
      {
        data: [data.totalMaleAppointments, data.totalFemaleAppointments],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += context.parsed + ' appointments';
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <ChartWrapper>
      <Pie data={chartData} options={options} />
    </ChartWrapper>
  );
};

const TotalCounts = () => {
  const [doctorCount, setDoctorCount] = useState(2);
  const [patientCount, setPatientCount] = useState(5);
  const [newPatientCount, setNewPatientCount] = useState(5);
  const [healthOfficerCount, setHealthOfficerCount] = useState(1);
  const [data, setData] = useState({
    totalMaleAppointments: 0,
    totalFemaleAppointments: 0,
  });
  const [period, setPeriod] = useState('months'); // Define period state
 const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      console.error('No JWT token available for authentication');
      return;
    }

  useEffect(() => {
    const fetchGenderCounts = async () => {
      try {
        const response = await fetch(`${BASE_URL}/user/genderwise-count`, {
          method: 'GET',
          headers: {
            'Auth': `Bearer ${jwtToken}`
          },
        });
        const result = await response.json();
        setData({
          totalMaleAppointments: result.maleCount,
          totalFemaleAppointments: result.femaleCount,
        });
      } catch (error) {
        console.error('Error fetching gender-wise counts:', error);
      }
    };

    fetchGenderCounts();
  }, []);


  useEffect(() => {
    fetch(`${BASE_URL}/user/count`, {
      method: 'GET',
      headers: {
        'Auth': `Bearer ${jwtToken}`
      },
    })
      .then(response => response.json())
      .then(data => {
        setDoctorCount(data.doctorCount);
        setPatientCount(data.patientCount);
        setNewPatientCount(data.newPatientCount);
        setHealthOfficerCount(data.healthOfficerCount);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <Layout>
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <Content>
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <ResponsiveContainer>
          <BlueItem>
            <Text>Total Doctors</Text>
            <IconWrapper>
              <Icon icon={faUserMd} />
            </IconWrapper>
            <CountWrapper>
              <Count>{doctorCount}</Count>
            </CountWrapper>
          </BlueItem>
          <YellowItem>
            <Text>Total Patients</Text>
            <IconWrapper>
              <Icon icon={faUser} />
            </IconWrapper>
            <CountWrapper>
              <Count>{patientCount}</Count>
            </CountWrapper>
          </YellowItem>
          <GreenItem>
            <Text>New Patients</Text>
            <IconWrapper>
              <Icon icon={faUserPlus} />
            </IconWrapper>
            <CountWrapper>
              <Count>{newPatientCount}</Count>
            </CountWrapper>
          </GreenItem>
          <RedItem>
            <Text>Total Health Officers</Text>
            <IconWrapper>
              <Icon icon={faUserShield} />
            </IconWrapper>
            <CountWrapper>
              <Count>{healthOfficerCount}</Count>
            </CountWrapper>
          </RedItem>
        </ResponsiveContainer>

        <Container>
          <ChartContainer>
            <ChartTitleStyled>Patient Registration Counts</ChartTitleStyled>
            <div>
              <button onClick={() => setPeriod('days')}>Days</button>
              <button onClick={() => setPeriod('months')}>Monthly</button>
            </div>
            <PatientRegistrationChart data={data} period={period} />
          </ChartContainer>

          <ChartContainer>
            <ChartTitleStyled>Gender-wise Appointment Distribution</ChartTitleStyled>
            <GenderPieChart data={data} />
          </ChartContainer>
        </Container>

        <Card>
          <Patientlist />
        </Card>
      </Content>
    </Layout>
  );
};

export default TotalCounts;
