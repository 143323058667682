
// import React, { useEffect, useState, useRef } from "react";
// import './webrtc.css';
// import axios from 'axios';
// import { BASE_URL } from '../config/apiConfig';
// import { useNavigate } from "react-router-dom";
// import { useLocation } from 'react-router-dom';
// const WebRTCClient = () => {
// 	const [peerConnection, setPeerConnection] = useState(null);
// 	const [inCall, setInCall] = useState(false);
// 	const navigate = useNavigate();
// 	const [waitingForPeer, setWaitingForPeer] = useState(false);
// 	const [doctors, setDoctors] = useState([]);
// 	const [selectedDoctorId, setSelectedDoctorId] = useState(null);
// 	const [incomingCall, setIncomingCall] = useState(false);
// 	const [callAnswered, setCallAnswered] = useState(false);
// 	const [role, setRole] = useState(null);
// 	const localVideoRef = React.useRef(null);
// 	const remoteVideoRef = React.useRef(null);
// 	const [offer, setOffer] = useState(null);
// 	const [refId, setRefId] = useState(null);
// 	const [answer, setAnswer] = useState(null);
// 	const [isMuted, setIsMuted] = useState(false);
// 	const [isVideoOn, setIsVideoOn] = useState(true);
// 	const localStreamRef = useRef(null);  // Ref to store the local stream
// 	const [patientId, setPatientId] = useState(null);
// 	const location = useLocation();
// 	const streamRef = useRef(null);
// 	const peerConnectionRef = useRef(null);
// 	const [callState, setCallState] = useState('initialState'); // Define state and updater


// 	const styles = {
// 		startCall: { color: 'green', fontSize: '26px' }, // Increase font size
// 		muted: { color: 'red', fontSize: '26px' }, // Increase font size
// 		unmuted: { color: 'blue', fontSize: '26px' }, // Increase font size
// 		videoOn: { color: 'green', fontSize: '26px' }, // Increase font size
// 		videoOff: { color: 'orange', fontSize: '26px' }, // Increase font size
// 		hangup: { color: 'red', fontSize: '26px' } // Increase font size
// 	};
// 	const buttonStyles = {
// 		display: 'flex',
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 		width: '50px', // Adjust as needed
// 		height: '50px', // Adjust as needed
// 		border: 'none',
// 		backgroundColor: 'transparent',
// 		cursor: 'pointer',
// 		margin: '5px'
// 	};
// 	const containerStyles = {
// 		display: 'flex',
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 		backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent black background
// 		borderRadius: '8px', // Adjust the radius as needed
// 		// padding: '10px', // Optional: add padding for better spacing
// 		width: '200px', // Fixed width; adjust as needed
// 		margin: '0 auto',
// 		height: '70px' // Center horizontally
// 	};
// 	useEffect(() => {
// 		fetchDoctors();
// 		createPeerConnection();
// 		answerCalll();

// 		// Get user role from localStorage
// 		const userRole = localStorage.getItem('role');
// 		setRole(userRole);
// 		console.log("User role:", userRole); // Log the role to the console
// 	}, []);


// 	//=========================== patient ID location =======================
// 	useEffect(() => {
// 		if (location.state && location.state.patientId) {
// 			const { patientId } = location.state;
// 			setPatientId(patientId);
// 			console.log("patinet id by location ", patientId);
// 		} else {
// 			console.error('No patient ID provided in location state');
// 		}
// 	}, [location.state])
// 	//==========================fetch doctors list===========================================
// 	const fetchDoctors = async () => {
// 		try {
// 			const response = await axios.get(`${BASE_URL}/registerDocHs/doctorss`);
// 			setDoctors(response.data);
// 		} catch (error) {
// 			console.error('Error fetching doctors:', error);
// 		}
// 	};


// 	const createPeerConnection = async () => {
// 		//	setup local streams
// 		const localStream = await navigator.mediaDevices.getUserMedia({
// 			video: true,
// 			audio: true,
// 		});

// 		//  Update video streams in the DOM
// 		localVideoRef.current.srcObject = localStream;

// 		//	create peer connection
// 		const pc = new RTCPeerConnection();
// 		registerPeerConnectionListeners(pc);

// 		// Create remote stream and add to video element
// 		const remoteStream = new MediaStream();
// 		remoteVideoRef.current.srcObject = remoteStream;

// 		//  Push tracks from local stream to peer connection
// 		if (localStream) {
// 			localStream.getTracks().forEach((track) => {
// 				pc.addTrack(track, localStream);
// 			});
// 		} else {
// 			throw new Error(
// 				"Local stream is not available, please check your camera and microphone"
// 			);
// 		}

// 		//  Pull tracks from remote stream, add to video stream in DOM
// 		if (remoteStream) {
// 			pc.ontrack = async (event) => {
// 				event.streams[0].getTracks().forEach((track) => {
// 					remoteStream.addTrack(track);
// 				});
// 			};
// 		} else {
// 			throw new Error(
// 				"Remote stream is not available, please check with your peer connection"
// 			);
// 		}

// 		setPeerConnection(pc);
// 	};
// 	//======================generate  ice candidate===================================

// 	const generateIceCandidate = async (peerType) => {
// 		if (!peerConnection) {
// 			throw new Error("Peer connection is not available");
// 		}

// 		peerConnection.onicecandidate = (event) => {
// 			if (event.candidate) {
// 				//  when ice candidate is received, we'll update the offer and answer sdp and then send it back to the caller and callee
// 				if (peerType === "caller") {
// 					setOffer(peerConnection?.localDescription);
// 				} else if (peerType === "receiver") {
// 					setAnswer(peerConnection?.localDescription);
// 				} else {
// 					throw new Error(
// 						"Peer type is not available, please look into generating ice candidate"
// 					);
// 				}
// 			}
// 		};
// 	};


// 	//==============================================hangup function ==============================
// 	const hangup = async () => {
// 		if (!peerConnection) {
// 			throw new Error("Peer connection is not available");
// 		}

// 		await peerConnection.close();
// 		setInCall(false);
// 		setOffer(null);
// 		setAnswer(null);

// 		createPeerConnection();
// 	};

// 	// Mute/unmute logic
// 	const muteUnmute = () => {
// 		if (localStreamRef.current) {
// 			localStreamRef.current.getAudioTracks().forEach(track => {
// 				track.enabled = isMuted;
// 			});
// 		}
// 		setIsMuted(!isMuted);
// 	};

// 	// Video on/off logic
// 	const videoOnOff = () => {
// 		if (localVideoRef.current) {
// 			localVideoRef.current.srcObject.getVideoTracks().forEach(track => {
// 				track.enabled = !isVideoOn;
// 			});
// 		}
// 		setIsVideoOn(!isVideoOn);
// 	};
// 	//================================startcall===================================

// 	const startCall = async () => {
// 		if (!peerConnection) {
// 			console.error("Peer connection is not available");
// 			return;
// 		}
// 		if (!selectedDoctorId) {
// 			console.error("No doctor selected");
// 			return;
// 		}
// 		try {
// 			await generateIceCandidate("caller");
// 			const offerDescription = await peerConnection.createOffer();
// 			await peerConnection.setLocalDescription(offerDescription);
// 			const offer = {
// 				sdp: offerDescription.sdp,
// 				type: offerDescription.type,
// 			};
// 			await saveToDatabase(selectedDoctorId, patientId, offer);
// 			setOffer(offer);
// 		} catch (error) {
// 			console.error("Error starting call:", error);
// 		}
// 	};

// 	//======================================save to db=========================================
// 	const saveToDatabase = async (doctorId, patientId, data) => {
// 		const key = localStorage.getItem("jwtToken");
// 		if (!key) {
// 			console.error("No JWT token found in localStorage");
// 			return;
// 		}
// 		try {
// 			await axios.post(`${BASE_URL}/registerDocHs/saveVideoToken`, {
// 				videoToken: JSON.stringify(data), // Ensure it's in the expected format
// 				refId: doctorId,
// 				patientId: patientId // Include patientId in the request payload
// 			}, {
// 				headers: {
// 					'Auth': `Bearer ${key}`,
// 					'Content-Type': 'application/json'
// 				}
// 			});
// 		} catch (error) {
// 			console.error('Error saving offer to database:', error);
// 		}
// 	};
// 	// ============hangup api call========================

// 	const hangupCall = async () => {
// 		const key = localStorage.getItem("jwtToken");
// 		try {
// 			const response = await axios.post(`${BASE_URL}/registerDocHs/hangup`, {}, {
// 				headers: {
// 					'Auth': `Bearer ${key}`,
// 					'Content-Type': 'application/json'
// 				}
// 			});
// 			const hangupResponse = response.data;
// 			console.log("Hangup response:", hangupResponse);
// 		} catch (error) {
// 			console.error('Error fetching video token:', error);
// 		}
// 	};
// 	//============================================================answercall==================================

// 	const onAnswer = async (answer) => {
// 		if (!peerConnection) {
// 			throw new Error("Peer connection is not available");
// 		}

// 		if (peerConnection.currentRemoteDescription) {
// 			console.log("Remote description already set");
// 			return;
// 		}


// 		console.log('Answer received:', answer);
// 		const answerDescription = new RTCSessionDescription(answer);
// 		await peerConnection.setRemoteDescription(answerDescription);
// 		setInCall(true);
// 	};
// 	//==============================================get call offer =================================

// 	useEffect(() => {
// 		const fetchOffer = async () => {
// 			await getCallOffer();
// 		};

// 		// const fetchAnswer = async () => {
// 		// 	await answerCalll();
// 		// };

// 		// const fetchOnAnswer = async () => {
// 		// 	const offer = await getCallOffer();
// 		// 	console.log("modified answer ---------",offer)
// 		// 	// await onAnswer(offer);
// 		// };


// 		let intervalId;

// 		if (role === '[DOCTOR]') {
// 			intervalId = setInterval(fetchOffer, 1000);
// 		}
// 		// if (role === '[HEALTH_OFFICER]') {
// 		// 	// intervalId = setInterval(fetchAnswer, 1000);
// 		// 	intervalId = setInterval(fetchOnAnswer, 1000);
// 		// }
// 		return () => {
// 			clearInterval(intervalId);
// 		};
// 	}, [role]);
// 	const getCallOffer = async () => {
// 		const token = localStorage.getItem("jwtToken");
// 		if (!token) {
// 			console.error("No JWT token found in localStorage");
// 			return null;
// 		}

// 		try {
// 			const response = await axios.get(`${BASE_URL}/registerDocHs/getVideoToken`, {
// 				headers: {
// 					'Auth': `Bearer ${token}`,
// 				}
// 			});

// 			const { videoToken, refId, patientId } = response.data;

// 			setOffer(JSON.parse(videoToken));
// 			setRefId(refId);
// 			setPatientId(patientId);

// 			console.log("refId:", refId);
// 			console.log("videoToken:", videoToken);
// 			console.log("patientId:", patientId);

// 			if (videoToken) {
// 				setIncomingCall(true);
// 				return JSON.parse(videoToken);
// 			} else {
// 				setIncomingCall(false);
// 				console.error("No videoToken found in response");
// 				return null;
// 			}
// 		} catch (error) {
// 			console.error('Error fetching offer:', error);
// 			return null;
// 		}
// 	};



// 	const handleVideoCallClick = () => {
// 		if (patientId) {
// 			navigate('/legal', { state: { patientId } });
// 		} else {
// 			console.error('Patient ID is not available');
// 		}
// 	};

// 	//====================================================anser========================

// 	useEffect(() => {
// 		if (answer && refId) {
// 			saveAnswerToDatabase(refId, answer);
// 		}
// 	}, [answer, refId]);

// 	//=================answer call for doctor side =====================
// 	const answerCall = async (callId) => {
// 		await generateIceCandidate("receiver");
// 		const offer = await getCallOffer(callId);
// 		const offerDescription = new RTCSessionDescription(offer);
// 		await peerConnection.setRemoteDescription(offerDescription);

// 		const answerDescription = await peerConnection.createAnswer();
// 		await peerConnection.setLocalDescription(answerDescription);

// 		const answer = {
// 			sdp: answerDescription.sdp,
// 			type: answerDescription.type,
// 		};

// 		console.log("answer created", answer);


// 		setAnswer(answer);
// 		handleVideoCallClick();
// 	};



// 	const answerCalll = async () => {
// 		const key = localStorage.getItem("jwtToken");
// 		try {
// 			const response = await axios.get(`${BASE_URL}/registerDocHs/getVideoToken`, {
// 				headers: {

// 					'Auth': `Bearer ${key}`,
// 					'Content-Type': 'application/json'
// 				}
// 			});
// 			const videoToken = response.data.videoToken;
// 			console.log("videoToken----------:", videoToken);
// 			setAnswer(JSON.stringify(videoToken, null, 2));

// 			await onAnswer(JSON.parse(videoToken));
// 			await hangupCall();  // Call hangupCall after onAnswer completes
// 		} catch (error) {
// 			console.error('Error fetching video token:', error);


// 		}
// 	};
// 	//============================================save to db ===========================================

// 	const saveAnswerToDatabase = async (refId, answer) => {
// 		console.log("Answer to be saved:", answer);
// 		console.log("refId to be saved:", refId);

// 		const key = localStorage.getItem("jwtToken");
// 		if (!key) {
// 			console.error("No JWT token found in localStorage");
// 			return;
// 		}

// 		try {
// 			await axios.post(`${BASE_URL}/registerDocHs/saveVideoToken`, {
// 				refId: refId,
// 				videoToken: JSON.stringify(answer),
// 				patientId: patientId
// 			}, {
// 				headers: {
// 					'Auth': `Bearer ${key}`,
// 					'Content-Type': 'application/json'
// 				}
// 			});
// 		} catch (error) {
// 			console.error('Error saving answer to database:', error);
// 		}
// 	};
// 	function registerPeerConnectionListeners(peerConnection) {
// 		peerConnection.addEventListener("icegatheringstatechange", () => {
// 			console.log(
// 				`ICE gathering state changed: ${peerConnection.iceGatheringState}`
// 			);
// 		});

// 		peerConnection.addEventListener("connectionstatechange", () => {
// 			console.log(`Connection state change: ${peerConnection.connectionState}`);

// 			// put below logic somewhere else
// 			switch (peerConnection.connectionState) {
// 				case "connecting":
// 					setWaitingForPeer(true);
// 					break;

// 				case "connected":
// 					setInCall(true);
// 					setWaitingForPeer(false);
// 					break;

// 				case "disconnected":
// 					peerConnection.close();
// 					setInCall(false);
// 					setOffer(null);
// 					setAnswer(null);

// 					createPeerConnection();
// 					break;
// 			}
// 		});

// 		peerConnection.addEventListener("signalingstatechange", () => {
// 			console.log(`Signaling state change: ${peerConnection.signalingState}`);
// 		});

// 		peerConnection.addEventListener("iceconnectionstatechange ", () => {
// 			console.log(
// 				`ICE connection state change: ${peerConnection.iceConnectionState}`
// 			);
// 		});
// 	}


// 	return (
// 		<div style={{ display: "grid", gap: "1em", backgroundColor: "#BBD5E0", height: '480px' }}>
// 			{role === '[HEALTH_OFFICER]' ? (
// 				<div style={{ display: "grid", gap: "1em" }}>
// 					<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
// 						<select
// 							id="doctorSelect"
// 							value={selectedDoctorId || ""}
// 							onChange={(e) => {
// 								const newId = e.target.value;
// 								setSelectedDoctorId(newId);
// 								console.log("Selected Doctor ID:", newId);
// 							}}
// 							style={{ marginBottom: "1em", width: "100%", maxWidth: "400px" }}
// 						>
// 							<option value="" disabled>Select a doctor</option>
// 							{doctors.map((doctor) => (
// 								<option key={doctor.id} value={doctor.id}>
// 									{doctor.name}
// 								</option>
// 							))}
// 						</select>
// 						<div
// 							style={{
// 								display: "flex",
// 								flexDirection: "row",
// 								justifyContent: "space-around",
// 								alignItems: "center",
// 								width: "100%",
// 								height: "300px",
// 							}}
// 						>
// 							<div style={{ width: "45%", height: "100%" }}>
// 								<video
// 									style={{ border: "1px solid black", width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px" }}
// 									ref={localVideoRef}
// 									autoPlay
// 								></video>
// 							</div>

// 							<div style={{ width: "45%", height: "100%" }}>
// 								<video
// 									style={{ border: "1px solid black", width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px" }}
// 									ref={remoteVideoRef}
// 									autoPlay
// 								></video>
// 							</div>


// 						</div>
// 					</div>
// 					<div style={containerStyles}>
// 						<button
// 							onClick={() => {
// 								if (!selectedDoctorId) {
// 									alert("Please select a doctor before starting the call.");
// 									return;
// 								}
// 								startCall();
// 							}}
// 							style={{
// 								...buttonStyles,
// 								opacity: !selectedDoctorId ? 0.5 : 1,
// 								cursor: !selectedDoctorId ? "not-allowed" : "pointer",
// 							}}
// 							disabled={!selectedDoctorId}
// 							title={!selectedDoctorId ? "Please select a doctor before starting the call." : ""}
// 						>
// 							<i className="fas fa-phone" style={styles.startCall}></i>
// 						</button>

// 						<button onClick={muteUnmute} style={buttonStyles}>
// 							<i className={isMuted ? "fas fa-microphone-slash" : "fas fa-microphone"} style={isMuted ? styles.muted : styles.unmuted}></i>
// 						</button>
// 						<button onClick={videoOnOff} style={buttonStyles}>
// 							<i className={isVideoOn ? "fas fa-video" : "fas fa-video-slash"} style={isVideoOn ? styles.videoOn : styles.videoOff}></i>
// 						</button>
// 						{inCall && (
// 							<button onClick={hangup} style={buttonStyles}>
// 								<i className="fas fa-phone-slash" style={styles.hangup}></i>
// 							</button>
// 						)}
// 					</div>
// 					<div>
// 						<button onClick={answerCalll}>On Answer</button>
// 					</div>


// 				</div>
// 			)
// 				:

// 				role === '[DOCTOR]' ? (
// 					<div style={{ display: "grid", gap: "2em" }}>
// 						<div
// 							style={{
// 								display: "flex",
// 								flexDirection: "row",
// 								justifyContent: "space-around",
// 								alignItems: "center",
// 								width: "100%",
// 								height: "300px",
// 							}}
// 						>
// 							<div style={{ width: "45%", height: "100%" }}>
// 								<video
// 									style={{ border: "1px solid black", width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px" }}
// 									ref={localVideoRef}
// 									autoPlay
// 								></video>
// 							</div>

// 							<div style={{ width: "45%", height: "100%" }}>
// 								<video
// 									style={{ border: "1px solid black", width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px" }}
// 									ref={remoteVideoRef}
// 									autoPlay
// 								></video>
// 							</div>

// 						</div>

// 						<div style={containerStyles}>

// 							{incomingCall && (
// 								<button onClick={() => answerCall("test")} className="incoming-call-button">
// 									<i className="fas fa-phone"></i>
// 								</button>
// 							)}

// 							<button onClick={muteUnmute} style={buttonStyles}>
// 								<i className={isMuted ? "fas fa-microphone-slash" : "fas fa-microphone"} style={isMuted ? styles.muted : styles.unmuted}></i>
// 							</button>

// 							<button onClick={videoOnOff} style={buttonStyles}>
// 								<i className={isVideoOn ? "fas fa-video" : "fas fa-video-slash"} style={isVideoOn ? styles.videoOn : styles.videoOff}></i>
// 							</button>

// 							{inCall && (
// 								<button onClick={hangup} style={buttonStyles}>
// 									<i className="fas fa-phone-slash" style={styles.hangup}></i>
// 								</button>
// 							)}
// 						</div>


// 					</div>
// 				)
// 					:
// 					(
// 						<p>Invalid role</p>
// 					)


// 			}

// 		</div>
// 	);
// };

// export default WebRTCClient;























// import React, {useRef , useState, useEffect } from 'react';
// import axios from 'axios';
// import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
// import { useLocation } from 'react-router-dom';
// import { BASE_URL } from '../config/apiConfig';
// import './Videocallcs.css'
// import { useNavigate } from "react-router-dom";


// const HomePage = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [selectedDoctor, setSelectedDoctor] = useState('');
//   const [showVideoCall, setShowVideoCall] = useState(false);
//   const [patientId, setPatientId] = useState('');
//   const [videoTokenData, setVideoTokenData] = useState(null);
//   const [userRole, setUserRole] = useState('');
//   const [userId, setUserId] = useState('');
//   const [showIncomingCall, setShowIncomingCall] = useState(false);

// 	const location = useLocation();
// 	const streamRef = useRef(null);
//   const navigate = useNavigate();


//   useEffect(() => {
//     const userRole = localStorage.getItem('role');
//     const userId = localStorage.getItem('userId');
//     setUserRole(userRole);
//     setUserId(userId);
//   }, []);

//   useEffect(() => {
//     const fetchDoctors = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/registerDocHs/doctorss`);
//         setDoctors(response.data);
//       } catch (error) {
//         console.error('Error fetching doctors:', error);
//       }
//     };

//     fetchDoctors();
//   }, []);



// 	//=========================== patient ID location =======================
// 	useEffect(() => {
// 		if (location.state && location.state.patientId) {
// 			const { patientId } = location.state;
// 			setPatientId(patientId);
// 			console.log("patinet id by location ", patientId);
// 		} else {
// 			console.error('No patient ID provided in location state');
// 		}
// 	}, [location.state])




//   const handleVideoCallClick = () => {
//   // Use the patientId from videoTokenData if available
//   const patientIdToUse = videoTokenData?.patientId || patientId;

//   if (patientIdToUse) {
//     navigate('/legal', { state: { patientId: patientIdToUse } });
//   } else {
//     console.error('Patient ID is not available');
//   }
// };



// const fetchVideoTokenData = async () => {
//   const key = localStorage.getItem("jwtToken");
//   if (!key) {
//     console.error("No JWT token found in localStorage");
//     return;
//   }
//   try {
//     const response = await axios.get(`${BASE_URL}/registerDocHs/getVideoToken`, {
//       headers: {
//         'Auth': `Bearer ${key}`,
//         'Content-Type': 'application/json',
//       },
//     });
//     if (response.data && response.data.videoToken) {
//       console.log('Fetched video token data:', response.data); // Debug log
//       setVideoTokenData(response.data);
//       setShowIncomingCall(true);
//       if (response.data.patientId) {
//         setPatientId(response.data.patientId);
//       }
//     } else {
//       setVideoTokenData(null);
//       setShowIncomingCall(false);
//     }
//   } catch (error) {
//     console.error('Error fetching video token data:', error);
//     setVideoTokenData(null);
//     setShowIncomingCall(false);
//   }
// };



//   const saveVideoToken = async (videoTokenData) => {
//     const key = localStorage.getItem("jwtToken");
//     if (!key) {
//       console.error("No JWT token found in localStorage");
//       return;
//     }
//     try {
//       const response = await axios.post(
//         `${BASE_URL}/registerDocHs/saveVideoToken`,
//         videoTokenData,
//         {
//           headers: {
//             'Auth': `Bearer ${key}`,
//             'Content-Type': 'application/json',
//           },
//         }
//       );
//       console.log("Video token data saved successfully", response.data);
//     } catch (error) {
//       console.error('Error saving video token data:', error);
//     }
//   };

//   const hangupVideoToken = async () => {
//     const key = localStorage.getItem("jwtToken");
//     if (!key) {
//       console.error("No JWT token found in localStorage");
//       return;
//     }
//     try {
//       await axios.post(
//         `${BASE_URL}/registerDocHs/hangup`,
//         {},
//         {
//           headers: {
//             'Auth': `Bearer ${key}`,
//             'Content-Type': 'application/json',
//           },
//         }
//       );
//       console.log("Video token data deleted successfully");
//     } catch (error) {
//       console.error('Error deleting video token data:', error);
//     }
//   };

//  const submitHandler = async () => {
//   const roomID = userRole === '[DOCTOR]' ? userId : selectedDoctor;

//   if (roomID) {
//     // Create a meeting link or token for the video call
//     const meetingLink = `${window.location.protocol}//${window.location.host}/legal?roomID=${roomID}`;
//     const appID = 709268713;
//     const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
//     const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
//       appID,
//       serverSecret,
//       roomID,
//       Date.now().toString(),
//       'o2i'
//     );

//     const videoTokenData = {
//       refId: roomID,
//       patientId: patientId,
//       videoToken: meetingLink,
//     };

//     await saveVideoToken(videoTokenData);

//     // Automatically show the video call by rendering the component
//     setShowVideoCall(true);
//   }
// };


//   const handleAcceptCall = async () => {
//     await hangupVideoToken(); // Delete video token when accepting the call
//     setShowIncomingCall(false);  // Close the popup
//     setShowVideoCall(true);      // Start the video call
//     handleVideoCallClick();
//   };



//   const handleDeclineCall = async () => {
//     await hangupVideoToken(); // Delete video token when declining the call
//     setShowIncomingCall(false);  // Close the popup
//     // Optionally send WebSocket message to notify caller that the call was declined
//       navigate('/Hovideo'); // Replace '/desired-page' with the actual path you want to navigate to

//   };


  


// const renderVideoCall = () => {
//   const roomID = userRole === '[DOCTOR]' ? userId : selectedDoctor;

//   const myMeeting = async (element) => {
//     const appID = 709268713;
//     const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
//     const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
//       appID,
//       serverSecret,
//       roomID,
//       Date.now().toString(),
//       'o2i'
//     );

//     const zp = ZegoUIKitPrebuilt.create(kitToken);

//     zp.joinRoom({
//       container: element,
//       scenario: {
//         mode: ZegoUIKitPrebuilt.OneONoneCall,
//       },
//       turnOnMicrophoneWhenJoining: true,
//       turnOnCameraWhenJoining: true,
//       showMyCameraToggleButton: true,
//       showMyMicrophoneToggleButton: true,
//       showAudioVideoSettingsButton: false,
//       showTextChat: true,
//       showUserList: false,
//       maxUsers: 2,
//       layout: "Auto",
//       showLayoutButton: false,
//       showPreJoinView: false,
//       showScreenSharingButton: false,
//     });
//   };

//   return (
//     <div ref={myMeeting} >
//     </div>
//   );
// };



//   useEffect(() => {
//     if (userRole === '[DOCTOR]') {
//       // Start an interval to check for incoming calls
//       const intervalId = setInterval(() => {
//         fetchVideoTokenData(); // Check for incoming calls
//       }, 1000);

//       return () => clearInterval(intervalId); // Clear the interval on component unmount
//     }
//   }, [userRole]);

//  const IncomingCallPopup = ({ videoTokenData, onAccept, onDecline }) => (
//   <div style={{
//     position: 'fixed',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     padding: '20px',
//     backgroundColor: 'white',
//     border: '1px solid #ccc',
//     boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
//     zIndex: 999,
//     textAlign: 'center'
//   }}>
//     <h3>Incoming Call from Health Officer</h3>
//     <p><strong>Caller ID:</strong> {videoTokenData?.refId}</p>
//     <p><strong>Patient ID:</strong> {videoTokenData?.patientId}</p>
//     <button
//       onClick={onAccept}
//       style={{
//         margin: '10px',
//         padding: '10px 20px',
//         fontSize: '16px',
//         backgroundColor: '#4CAF50',
//         color: 'white',
//         border: 'none',
//         cursor: 'pointer',
//       }}
//     >
//       Accept
//     </button>
//     <button
//       onClick={onDecline}
//       style={{
//         margin: '10px',
//         padding: '10px 20px',
//         fontSize: '16px',
//         backgroundColor: '#f44336',
//         color: 'white',
//         border: 'none',
//         cursor: 'pointer',
//       }}
//     >
//       Decline
//     </button>
//   </div>
// );

//   if (userRole === '[DOCTOR]') {
    
//     return (
//       <div className="sCsSbKP9yxvw4LQAeaTz">
//       <div>
//         {showIncomingCall && videoTokenData && (
//           <IncomingCallPopup
//             videoTokenData={videoTokenData}
//             onAccept={handleAcceptCall}
//             onDecline={handleDeclineCall}
//           />
//         )}
//         {!showVideoCall ? (
//           <div>
//             {/* <button onClick={submitHandler}>Start Video Call</button> */}
//             {videoTokenData && (
//               <div style={{ border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
//                 <h3>Video Token Data:</h3>
//                 <p><strong>Room Link:</strong> <a href={videoTokenData.videoToken}>{videoTokenData.videoToken}</a></p>
//                 <p><strong>Doctor ID (refId):</strong> {videoTokenData.refId}</p>
//                 <p><strong>Patient ID:</strong> {videoTokenData.patientId}</p>
//               </div>
//             )}
//           </div>
//         ) : (
//           renderVideoCall()
//         )}
//       </div>
//       </div>
//     );
//   }

//   if (userRole === '[HEALTH_OFFICER]') {
//    return (

//     <div className="sCsSbKP9yxvw4LQAeaTz">
//   <div>
//     {!showVideoCall ? (
//       <div>
//         <select
//           value={selectedDoctor}
//           onChange={(e) => setSelectedDoctor(e.target.value)}
//           style={{
//             padding: '10px',
//             fontSize: '16px',
//             margin: '10px 0',
//           }}
//         >
//           <option value="">Select a doctor</option>
//           {doctors.map((doctor) => (
//             <option key={doctor.id} value={doctor.id}>
//               {doctor.name}
//             </option>
//           ))}
//         </select>
//         <button
//           onClick={submitHandler}
//           style={{
//             padding: '10px 20px',
//             fontSize: '16px',
//             backgroundColor: '#4CAF50',
//             color: 'white',
//             border: 'none',
//             cursor: 'pointer',
//           }}
//         >
//           Start Video Call
//         </button>
//       </div>
      
//     ) : (
//       renderVideoCall()
//     )}
//   </div>
//   </div>
// );

//   }

//   return <div>Loading...</div>;
// };

// export default HomePage;








import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config/apiConfig';
import './Videocallcs.css';

const HomePage = () => {
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [showVideoCall, setShowVideoCall] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [videoTokenData, setVideoTokenData] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [userId, setUserId] = useState('');
  const [showIncomingCall, setShowIncomingCall] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const userRole = localStorage.getItem('role');
    const userId = localStorage.getItem('userId');
    setUserRole(userRole);
    setUserId(userId);
  }, []);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/registerDocHs/doctorss`);
        setDoctors(response.data);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };

    fetchDoctors();
  }, []);

  useEffect(() => {
    if (location.state && location.state.patientId) {
      const { patientId } = location.state;
      setPatientId(patientId);
      console.log("patient id by location ", patientId);
    } else {
      console.error('No patient ID provided in location state');
    }
  }, [location.state]);

  const handleVideoCallClick = () => {
    const patientIdToUse = videoTokenData?.patientId || patientId;

    if (patientIdToUse) {
      navigate('/legal', { state: { patientId: patientIdToUse } });
    } else {
      console.error('Patient ID is not available');
    }
  };

  const fetchVideoTokenData = async () => {
    const key = localStorage.getItem("jwtToken");
    if (!key) {
      console.error("No JWT token found in localStorage");
      return;
    }
    try {
      const response = await axios.get(`${BASE_URL}/registerDocHs/getVideoToken`, {
        headers: {
          'Auth': `Bearer ${key}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.data && response.data.videoToken) {
        console.log('Fetched video token data:', response.data); // Debug log
        setVideoTokenData(response.data);
        setShowIncomingCall(true);
        if (response.data.patientId) {
          setPatientId(response.data.patientId);
        }
      } else {
        setVideoTokenData(null);
        setShowIncomingCall(false);
      }
    } catch (error) {
      console.error('Error fetching video token data:', error);
      setVideoTokenData(null);
      setShowIncomingCall(false);
    }
  };

  const saveVideoToken = async (videoTokenData) => {
    const key = localStorage.getItem("jwtToken");
    if (!key) {
      console.error("No JWT token found in localStorage");
      return;
    }
    try {
      const response = await axios.post(
        `${BASE_URL}/registerDocHs/saveVideoToken`,
        videoTokenData,
        {
          headers: {
            'Auth': `Bearer ${key}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log("Video token data saved successfully", response.data);
    } catch (error) {
      console.error('Error saving video token data:', error);
    }
  };

  const hangupVideoToken = async () => {
    const key = localStorage.getItem("jwtToken");
    if (!key) {
      console.error("No JWT token found in localStorage");
      return;
    }
    try {
      await axios.post(
        `${BASE_URL}/registerDocHs/hangup`,
        {},
        {
          headers: {
            'Auth': `Bearer ${key}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log("Video token data deleted successfully");
    } catch (error) {
      console.error('Error deleting video token data:', error);
    }
  };

  const submitHandler = async () => {
    const roomID = userRole === '[DOCTOR]' ? userId : selectedDoctor;

    if (roomID) {
      const meetingLink = `${window.location.protocol}//${window.location.host}/legal?roomID=${roomID}`;
      const appID = 709268713;
      const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        appID,
        serverSecret,
        roomID,
        Date.now().toString(),
        'o2i'
      );

      const videoTokenData = {
        refId: roomID,
        patientId: patientId,
        videoToken: meetingLink,
      };

      await saveVideoToken(videoTokenData);
      setShowVideoCall(true);
    }
  };

  const handleAcceptCall = async () => {
    await hangupVideoToken(); // Delete video token when accepting the call
    setShowIncomingCall(false);  // Close the popup
    setShowVideoCall(true);      // Start the video call
    handleVideoCallClick();
  };

  const handleDeclineCall = async () => {
    await hangupVideoToken(); // Delete video token when declining the call
    setShowIncomingCall(false);  // Close the popup
    // navigate('/legal');       // Redirect to /Hovideo page
    
  };

  const renderVideoCall = () => {
    const roomID = userRole === '[DOCTOR]' ? userId : selectedDoctor;

    const myMeeting = async (element) => {
      const appID = 709268713;
      const serverSecret = '5a24fd89403d07d27d7640c1c28be33d';
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        appID,
        serverSecret,
        roomID,
        Date.now().toString(),
        'o2i'
      );

      const zp = ZegoUIKitPrebuilt.create(kitToken);

      zp.joinRoom({
        container: element,
        scenario: {
          mode: ZegoUIKitPrebuilt.OneONoneCall,
        },
        turnOnMicrophoneWhenJoining: true,
        turnOnCameraWhenJoining: true,
        showMyCameraToggleButton: true,
        showMyMicrophoneToggleButton: true,
        showAudioVideoSettingsButton: false,
        showTextChat: true,
        showUserList: false,
        maxUsers: 2,
        layout: "Auto",
        showLayoutButton: false,
        showPreJoinView: false,
        showScreenSharingButton: false,
        // onLeaveRoom: () => {
        //   // Redirect to /Hovideo page when leaving the room
        //   navigate('/legal');
        // }
         onLeaveRoom: () => { // Add this to handle room leaving
        handleLeaveRoom();
      }
      });
    };

    return (
      <div ref={myMeeting} >
      </div>
    );
  };



const handleLeaveRoom = () => {
  // Check the user's role
  if (userRole === '[HEALTH_OFFICER]') {
    // Navigate to '/list' if the role is HEALTH_OFFICER
    // navigate('/list');
//  window.location.href = '/Hovideo';
window.location.href = '/list';
  setTimeout(() => {
      window.location.reload();
    }, 100);
  } else if (userRole === '[DOCTOR]') {
    // Navigate to '/legal' if the role is DOCTOR
    // navigate('/legal');
    window.location.href = '/legal';
  } else {
    // Optionally handle other roles or errors
    console.error('Unknown user role:', userRole);
  }
};

// const handleLeaveRoom = () => {
//     //   localStorage.removeItem('z_appconfig_709268713_1');
//     // localStorage.removeItem('z_appconfig_etag_709268713_1');
//     // localStorage.removeItem('z_deviceID');
//     // localStorage.removeItem('z_net_access_servers_709268713_1');
//     // localStorage.removeItem('z_rtc_domains_709268713_1');
//     // localStorage.removeItem('z_s_c_709268713');
//     // localStorage.removeItem('z_store_keys');
//   // Check the user's role
//   if (userRole === '[HEALTH_OFFICER]') {
//     // Navigate to '/list' if the role is HEALTH_OFFICER
//      // Remove ZEGO Cloud related details from localStorage
//     // localStorage.removeItem('z_appconfig_709268713_1');
//     // localStorage.removeItem('z_appconfig_etag_709268713_1');
//     // localStorage.removeItem('z_deviceID');
//     // localStorage.removeItem('z_net_access_servers_709268713_1');
//     // localStorage.removeItem('z_rtc_domains_709268713_1');
//     // localStorage.removeItem('z_s_c_709268713');
//     // localStorage.removeItem('z_store_keys');
//     navigate('/list');
//   } else if (userRole === '[DOCTOR]') {
//     // Navigate to '/legal' if the role is DOCTOR
//      // Remove ZEGO Cloud related details from localStorage
//     // localStorage.removeItem('z_appconfig_709268713_1');
//     // localStorage.removeItem('z_appconfig_etag_709268713_1');
//     // localStorage.removeItem('z_deviceID');
//     // localStorage.removeItem('z_net_access_servers_709268713_1');
//     // localStorage.removeItem('z_rtc_domains_709268713_1');
//     // localStorage.removeItem('z_s_c_709268713');
//     // localStorage.removeItem('z_store_keys');
//     navigate('/legal');
//   } else {
//     // Optionally handle other roles or errors
//     console.error('Unknown user role:', userRole);
//   }
// };


  useEffect(() => {
    if (userRole === '[DOCTOR]') {
      const intervalId = setInterval(() => {
        fetchVideoTokenData(); // Check for incoming calls
      }, 1000);

      return () => clearInterval(intervalId); // Clear the interval on component unmount
    }
  }, [userRole]);

  const IncomingCallPopup = ({ videoTokenData, onAccept, onDecline }) => (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      backgroundColor: 'white',
      border: '1px solid #ccc',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      zIndex: 999,
      textAlign: 'center'
    }}>
      <h3>Incoming Call from Health Officer</h3>
      <p><strong>Caller ID:</strong> {videoTokenData?.refId}</p>
      <p><strong>Patient ID:</strong> {videoTokenData?.patientId}</p>
      <button
        onClick={onAccept}
        style={{
          margin: '10px',
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Accept
      </button>
      <button
        onClick={onDecline}
        style={{
          margin: '10px',
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#f44336',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Decline
      </button>
    </div>
  );

  if (userRole === '[DOCTOR]') {
    
    return (
      <div className="sCsSbKP9yxvw4LQAeaTz">
      <div>
        {showIncomingCall && videoTokenData && (
          <IncomingCallPopup
            videoTokenData={videoTokenData}
            onAccept={handleAcceptCall}
            onDecline={handleDeclineCall}
          />
        )}
        {!showVideoCall ? (
          <div>
            {/* <button onClick={submitHandler}>Start Video Call</button> */}
            {videoTokenData && (
              <div style={{ border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
                <h3>Video Token Data:</h3>
                <p><strong>Room Link:</strong> <a href={videoTokenData.videoToken}>{videoTokenData.videoToken}</a></p>
                <p><strong>Doctor ID (refId):</strong> {videoTokenData.refId}</p>
                <p><strong>Patient ID:</strong> {videoTokenData.patientId}</p>
              </div>
            )}
          </div>
        ) : (
          renderVideoCall()
        )}
      </div>
      </div>
    );
  }

  if (userRole === '[HEALTH_OFFICER]') {
   return (

    <div className="sCsSbKP9yxvw4LQAeaTz">
  <div>
    {!showVideoCall ? (
      <div>
        <select
          value={selectedDoctor}
          onChange={(e) => setSelectedDoctor(e.target.value)}
          style={{
            padding: '10px',
            fontSize: '16px',
            margin: '10px 0',
          }}
        >
          <option value="">Select a doctor</option>
          {doctors.map((doctor) => (
            <option key={doctor.id} value={doctor.id}>
              {doctor.name}
            </option>
          ))}
        </select>
        <button
          onClick={submitHandler}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Start Video Call
        </button>
      </div>
      
    ) : (
      renderVideoCall()
    )}
  </div>
  </div>
);

  }

  return <div>Loading...</div>;
};

export default HomePage;



