import React, { useState } from "react";

import Logout from "../Assets/Logout.png"; // Corrected variable name
import Homeicon from "../Assets/Home icon.png"; 
import Testaccount from "../Assets/Test Account.png"; 
import logo from "../Assets/O2I_LOGO.png"; // Import your image here
import { Link, useNavigate } from "react-router-dom";
function Navbar() {
  const [showNotification, setShowNotification] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
 const navigate = useNavigate();
  const toggleNotification = () => {
    setShowNotification(!showNotification); // Toggle notification box visibility
  };

  const togglePopup = () => {
    setShowPopup(!showPopup); // Toggle popup visibility
  };

   const logout = () => {
    localStorage.removeItem("token"); // Remove token from localStorage
    navigate("/"); // Redirect to login page
  };

  return (
    <div className="navbar-section">
      {/* <h1 className="navbar-title">
        <Link to="/dashdoc"> 
          O2I <span className="navbar-sign">+</span>
        </Link>
      </h1> */}
<div className="navbar-title" style={{ marginLeft: '10px' }}>
    <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
</div>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/userdashboard"> 
            <img src={Homeicon} alt="Home Icon" className="history-icon" />
          </Link>
        </li>
        {/* <li>
          <img src={Testaccount} alt="Notification Icon" className="history-icon" onClick={toggleNotification} />
          {showNotification && (
            <div className="notification-box">
             
            </div>
          )}
        </li> */}
        <li>
          <img 
            src={Logout} 
            alt="Setting Icon" 
            className="history-icon" 
            onClick={logout}
          />
          {showPopup && (
            <div className="popup show">
             
            </div>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Navbar;

