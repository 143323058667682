





import React from "react";
import styled from "styled-components";
import Videoheader from "../Components/Video/Videoheader";
import Drpatienthistory from "../Components/Drpatienthistory";
import Header from "../Components/Header";
import Footer from "../Components/Footer";


const AppWrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #DCE7EA, #C3E2EB);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
`;

const ContentWrapper = styled.div`
  padding: 2px;
`;

const HeaderWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

function Casehistory() {
  return (
    <AppWrapper>
      
      <Background />
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <ContentWrapper>
        <Videoheader />
        <Drpatienthistory />
        <Footer />
      </ContentWrapper>
    </AppWrapper>
  );
}

export default Casehistory;
