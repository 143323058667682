import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Legal from '../../Pages/Videoconsultation'; // Ensure Legal.js exists in the same directory
// import CaseHistory from '../../Pages/Casehistory'; // Ensure CaseHistory.js exists in the same directory
// import DrAppointment from '../../Pages/Drappoinment'; // Ensure DrAppointment.js exists in the same directory
function Header() {
    const location = useLocation();

    const getActiveTab = () => {
        switch (location.pathname) {
            case '/userdashboard':
                return "Appointment History";
            case '/vitals':
                return "Vitals";
            case '/testresult':
                return "Test Results";
            
            default:
                return "Appointment History";
        }
    };

    const [activeTab, setActiveTab] = useState(getActiveTab);

    useEffect(() => {
        setActiveTab(getActiveTab);
    }, [location]);

    return (
        <div>
            <header style={styles.header}>
                <Link
                    to="/Userdashboard"
                    style={activeTab === "Appointment History" ? {...styles.tab, ...styles.active} : styles.tab}
                    onClick={() => setActiveTab("Video Consultation")}
                >
                  Appointment History
                </Link>
                <Link
                    to="/vitals"
                    style={activeTab === "Vitals" ? {...styles.tab, ...styles.active} : styles.tab}
                    onClick={() => setActiveTab("Vitals")}
                >
                    Vitals
                </Link>
                <Link
                    to="/testresult"
                    style={activeTab === "Test Results" ? {...styles.tab, ...styles.active} : styles.tab}
                    onClick={() => setActiveTab("Test Results")}
                >
                    Test Results
                </Link>
               
            </header>
        </div>
    );
}

const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#C3C3C3',
        padding: '5px 0',
        marginBottom: '5px',

    },
    tab: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        padding: '10px 20px',
        transition: 'background-color 0.3s ease',
        color: 'white',
        textDecoration: 'none',
    },
    active: {
        backgroundColor: '#007bff',
        borderRadius: '5px',
    },
};

export default Header;
