



import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';
import Header from "../../Hoheader";
import Footer from "../../Footer";

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '10px',
    border: '2px solid #ccc',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column', // Arrange children in a column
    position: 'relative', // Ensure positioning context for absolutely positioned children
  },
  contentWrapper: {
    flexGrow: 1, // Allow content to grow and take up remaining space
    overflowY: 'auto', // Add overflowY auto to show scrollbar at the bottom
    marginBottom: '20px', // Margin to separate from save button
    padding: '10px', // Padding for content area
  },
  dateTimeContainer: {
    display: 'flex',// Allow to content fix in one line 
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
    marginRight: '10px',
    backgroundColor: '#0F60B3',
    maxWidth: '450px',
    borderRadius: '5px',
    overflow: 'hidden', // Add overflow hidden to prevent content from overflowing
  },
  
  label: {
    marginRight: '8px',
    fontWeight: 'bold',
    color: 'white',
    marginLeft: '20px',
  },
  customInput: {
    width: '150px',
    padding: '5px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  input: {
    width: '50%',
    padding: '5px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginTop: '10px',
  },
  inlineInputsContainer: {
    overflowX: 'auto',
    maxWidth: '100%',
    marginBottom: '20px',
  },
  inlineInputs: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  inlineInput: {
    flex: '0 1 calc(50% - 10px)',// content fix in one line 
    maxWidth: '200px',
    padding: '5px',
    marginBottom: '10px',
  },
  updatehealth: {
    textAlign: 'center',
    color: '#324E6A',
    fontSize: '50px',
    textShadow: '0px 0px 32.1px #BB1111', // set the textshadow of heading tag h3
  },
  background: {
    marginTop: '20px',
    backgroundColor: '#0F60B3',
    border: '3px solid #ccc',
    borderRadius: '5px',
    marginBottom: '10px',
    padding: '20px',
  },
  saveButton: {
    alignSelf: 'flex-end',  // save button fix in end of the row
    
    padding: '10px 15px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#0F60B3',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '90px', // Fixed width for the button
  },

  footer: {
    marginTop: '20px', // Adjust as needed to create space between content and footer
    textAlign: 'center',
    backgroundColor: '#ddd',
    padding: '10px',
  },
};

const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
  <div style={{ position: 'relative' }}>
    <input
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
      style={styles.customInput}
    />
    {icon && (
      <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
        {icon}
      </div>
    )}
  </div>
));

const UpdateHealthRecord = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleSubmit = () => {
    console.log('Search Term:', searchTerm);
    console.log('Selected Date:', selectedDate);
    console.log('Selected Time:', selectedTime);
  };

  return (
    <div style={{ background: 'linear-gradient(135deg, #7CB2D0, #D0E0E5)' }}>
      <Header />
      <h2 style={styles.updatehealth}>Update Health Record</h2>
      <div style={styles.container}>
        <div style={styles.dateTimeContainer}>
          <div style={styles.fieldWrapper}>
            <label style={styles.label}>Search Patient</label>
            <input
              type="text"
              placeholder="Enter patient name or ID"
              value={searchTerm}
              onChange={handleSearchChange}
              style={styles.input}
            />
          </div>
          <div style={{ ...styles.fieldWrapper, flex: '0 1 100%', marginTop: '10px' }}>
            <label style={styles.label}>Date</label>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              customInput={<CustomInput icon={<FaCalendarAlt />} />}
            />
        
            <label style={styles.label}>Time</label>
            <DatePicker
              selected={selectedTime}
              onChange={handleTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              customInput={<CustomInput icon={<FaClock />} />}
            />
          </div>
        </div>
        <div style={styles.inlineInputsContainer}>
          <div style={styles.inlineInputs}>
            <input
              type="text"
              placeholder="First Name"
              style={styles.inlineInput}
            />
            <input
              type="text"
              placeholder="Last Name"
              style={styles.inlineInput}
            />
            <input
              type="text"
              placeholder="Mobile No."
              style={styles.inlineInput}
            />
            <input
              type="text"
              placeholder="Email"
              style={styles.inlineInput}
            />
          </div>
        </div>
        <div style={{ fontSize:'20px', marginTop:'16px',textAlign: 'center'}}>Enter the results below to update in Health Records</div>
        <div style={styles.background}>
          <div style={styles.inlineInputs}>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>Height (cm)</label>
              <input
                type="text"
                style={styles.inlineInput}
              />
            </div>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>Weight (kg)</label>
              <input
                type="text"
                style={styles.inlineInput}
              />
            </div>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>BMI (kg/m2)</label>
              <input
                type="text"
                style={styles.inlineInput}
              />
            </div>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>Systolic BP (mmHg)</label>
              <input
                type="text"
                style={styles.inlineInput}
              />
            </div>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>Diastolic BP (mmHg)</label>
              <input
                type="text"
                style={styles.inlineInput}
              />
            </div>
          </div>
          <div style={styles.inlineInputs}>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>SpO2 (%)</label>
              <input
                type="text"
                style={styles.inlineInput}
              />
            </div>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>Respiration Rate (/min)</label>
              <input
                type="text"
                style={styles.inlineInput}
              />
            </div>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>Temp (°F)</label>
              <input
                type="text"
                style={styles.inlineInput}
              />
            </div>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>Blood Sugar(mg/dl)</label>
              <input
                type="text"
                style={styles.inlineInput}

              />
            </div>
            <div style={{ ...styles.inlineInput, width: '100%' }}>
              <label style={{ color: 'white', marginBottom: '5px' }}>Hemoglobin(gm/dl)</label>
              <input
                type="text"
                style={styles.inlineInput}
              />
            </div>
          </div>
        </div>
 <button style={styles.saveButton} onClick={handleSubmit}>Save</button>
      </div>
      <Footer />
    </div>
  );
};

export default UpdateHealthRecord;

