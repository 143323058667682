// // // // Header.js
// // // import React from 'react';
// // // import styled from 'styled-components';
// // // import { Link } from 'react-router-dom';
// // // import { FaUserPlus, FaUserMd, FaUserCircle } from 'react-icons/fa';

// // // const HeaderContainer = styled.div`
// // //   width: 100%;
// // //   height: 60px;
// // //   background-color: #7CB2D0;
// // //   display: flex;
// // //   align-items: center;
// // //   justify-content: space-between;
// // //   padding: 0 20px;
// // //   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
// // // `;

// // // const HeaderTitle = styled.h1`
// // //   color: #ffffff;
// // //   font-size: 24px;
// // // `;

// // // const NavLinks = styled.div`
// // //   display: flex;
// // //   align-items: center;
// // // `;

// // // const NavLink = styled(Link)`
// // //   color: #ffffff;
// // //   text-decoration: none;
// // //   margin-left: 20px;
// // //   display: flex;
// // //   align-items: center;

// // //   &:hover {
// // //     color: #D0E0E5;
// // //   }

// // //   & > svg {
// // //     margin-right: 5px;
// // //   }
// // // `;

// // // const Header = () => {
// // //   return (
// // //     <HeaderContainer>
// // //       <HeaderTitle>Hospital Dashboard</HeaderTitle>
// // //       <NavLinks>
// // //         <NavLink to="/Register">
// // //           <FaUserPlus />
// // //           Register Doctor/Health Officer
// // //         </NavLink>
// // //         {/* <NavLink to="/register-doctor">
// // //           <FaUserMd />
// // //           Register Doctor
// // //         </NavLink> */}
// // //         <NavLink to="/profile">
// // //           <FaUserCircle />
        
// // //         </NavLink>
// // //       </NavLinks>
// // //     </HeaderContainer>
// // //   );
// // // };

// // // export default Header;
// // // Header.js
// // import React, { useState } from 'react';
// // import styled from 'styled-components';
// // import { Link } from 'react-router-dom';
// // import { FaUserPlus, FaUserCircle } from 'react-icons/fa';
// // import Profile from './profile';

// // const HeaderContainer = styled.div`
// //   width: 100%;
// //   height: 60px;
// //   background-color: #7CB2D0;
// //   display: flex;
// //   align-items: center;
// //   justify-content: space-between;
// //   padding: 0 20px;
// //   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
// // `;

// // const HeaderTitle = styled.h1`
// //   color: #ffffff;
// //   font-size: 24px;
// // `;

// // const NavLinks = styled.div`
// //   display: flex;
// //   align-items: center;
// // `;

// // const NavLink = styled(Link)`
// //   color: #ffffff;
// //   text-decoration: none;
// //   margin-left: 20px;
// //   display: flex;
// //   align-items: center;

// //   &:hover {
// //     color: #D0E0E5;
// //   }

// //   & > svg {
// //     margin-right: 5px;
// //   }
// // `;

// // const Modal = styled.div`
// //   position: fixed;
// //   top: 0;
// //   left: 0;
// //   right: 0;
// //   bottom: 0;
// //   background: rgba(0, 0, 0, 0.5);
// //   display: flex;
// //   align-items: center;
// //   justify-content: center;
// //   z-index: 1000;
// // `;

// // const ModalContent = styled.div`
// //   background: #fff;
// //   padding: 20px;
// //   border-radius: 8px;
// //   width: 400px;
// //   max-width: 100%;
// // `;

// // const Header = () => {
// //   const [isModalOpen, setIsModalOpen] = useState(false);

// //   const toggleModal = () => {
// //     setIsModalOpen(!isModalOpen);
// //   };

// //   return (
// //     <>
// //       <HeaderContainer>
// //         <HeaderTitle>Hospital Dashboard</HeaderTitle>
// //         <NavLinks>
// //           <NavLink to="/Register">
// //             <FaUserPlus />
// //             Register Doctor/Health Officer
// //           </NavLink>
// //           <NavLink onClick={toggleModal}>
// //             <FaUserCircle />
// //             Profile
// //           </NavLink>
// //         </NavLinks>
// //       </HeaderContainer>
// //       {isModalOpen && (
// //         <Modal onClick={toggleModal}>
// //           <ModalContent onClick={(e) => e.stopPropagation()}>
// //             <Profile />
// //           </ModalContent>
// //         </Modal>
// //       )}
// //     </>
// //   );
// // };

// // export default Header;
// // Header.js
// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import { FaUserPlus, FaUserCircle } from 'react-icons/fa';
// import axios from 'axios';

// const HeaderContainer = styled.div`
//   width: 100%;
//   height: 60px;
//   background-color: #7CB2D0;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 0 20px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
// `;

// const HeaderTitle = styled.h1`
//   color: #ffffff;
//   font-size: 24px;
// `;

// const NavLinks = styled.div`
//   display: flex;
//   align-items: center;
//   position: relative; /* For positioning the dropdown */
// `;

// const NavLink = styled(Link)`
//   color: #ffffff;
//   text-decoration: none;
//   margin-left: 20px;
//   display: flex;
//   align-items: center;
//   cursor: pointer;

//   &:hover {
//     color: #D0E0E5;
//   }

//   & > svg {
//     margin-right: 5px;
//   }
// `;

// const ProfileDropdown = styled.div`
//   position: absolute;
//   top: 60px; /* Adjust based on the header height */
//   right: 0;
//   background-color: #ffffff;
//   border: 1px solid #ddd;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   padding: 10px;
//   width: 200px;
//   display: ${({ show }) => (show ? 'block' : 'none')};
// `;

// const ProfileItem = styled.div`
//   margin-bottom: 10px;
// `;

// const Header = () => {
//   const [isDropdownVisible, setIsDropdownVisible] = useState(false);
//   const [profile, setProfile] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     if (isDropdownVisible) {
//       // Fetch profile data from the API when dropdown is visible
//       const fetchProfile = async () => {
//         try {
//           const response = await axios.get('http://localhost:7075/user/hospital-details', {
//             headers: {
//               'Authorization': 'Bearer YOUR_JWT_TOKEN', // Replace with actual JWT token
//             },
//           });
//           setProfile(response.data);
//         } catch (err) {
//           setError(err.message);
//         } finally {
//           setLoading(false);
//         }
//       };

//       fetchProfile();
//     }
//   }, [isDropdownVisible]);

//   const toggleDropdown = () => {
//     setIsDropdownVisible(!isDropdownVisible);
//   };

//   return (
//     <HeaderContainer>
//       <HeaderTitle>Hospital Dashboard</HeaderTitle>
//       <NavLinks>
//         <NavLink to="/Register">
//           <FaUserPlus />
//           Register Doctor/Health Officer
//         </NavLink>
//         <NavLink onClick={toggleDropdown}>
//           <FaUserCircle />
//           Profile
//         </NavLink>
//         <ProfileDropdown show={isDropdownVisible}>
//           {loading ? (
//             <p>Loading...</p>
//           ) : error ? (
//             <p>Error: {error}</p>
//           ) : profile ? (
//             <div>
//               <ProfileItem><strong>Username:</strong> {profile.userName}</ProfileItem>
//               <ProfileItem><strong>Email:</strong> {profile.email}</ProfileItem>
//               <ProfileItem><strong>Mobile No:</strong> {profile.mobileNo}</ProfileItem>
//             </div>
//           ) : (
//             <p>No profile data available</p>
//           )}
//         </ProfileDropdown>
//       </NavLinks>
//     </HeaderContainer>
//   );
// };

// export default Header;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaUserPlus, FaUserCircle } from 'react-icons/fa';
import axios from 'axios';
import { BASE_URL } from '../config/apiConfig';
const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  background-color: #7CB2D0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* Make sure the dropdown is positioned relative to the header */
`;

const HeaderTitle = styled.h1`
  color: #ffffff;
  font-size: 24px;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #D0E0E5;
  }

  & > svg {
    margin-right: 5px;
  }
`;

const ProfileDropdown = styled.div`
  position: absolute;
  top: 60px; /* Adjust based on the header height */
  right: 20px; /* Adjust spacing from the right */
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 200px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 1000; /* Ensure the dropdown is above other content */
`;

const ProfileItem = styled.div`
  margin-bottom: 10px;
`;

const Header = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      console.error('No JWT token available for authentication');
      return;
    }

  useEffect(() => {
    if (isDropdownVisible) {
      // Fetch profile data from the API when dropdown is visible
      const fetchProfile = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/user/hospital-details`, {
            headers: {
           'Auth': `Bearer ${jwtToken}`
          }
          });
          setProfile(response.data);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchProfile();
    }
  }, [isDropdownVisible]);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <HeaderContainer>
      <HeaderTitle>Hospital Dashboard</HeaderTitle>
      <NavLinks>
        <NavLink to="/Register">
          <FaUserPlus />
          Register Doctor/Health Officer
        </NavLink>
        <NavLink onClick={toggleDropdown}>
          <FaUserCircle />
          Profile
        </NavLink>
        <ProfileDropdown show={isDropdownVisible}>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : profile ? (
            <div>
              <ProfileItem><strong>Username:</strong> {profile.userName}</ProfileItem>
              <ProfileItem><strong>Email:</strong> {profile.email}</ProfileItem>
              <ProfileItem><strong>Mobile No:</strong> {profile.mobileNo}</ProfileItem>
            </div>
          ) : (
            <p>No profile data available</p>
          )}
        </ProfileDropdown>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
