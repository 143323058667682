
import React from 'react';

function Footer() {
  const footerStyle = {
    backgroundColor: '#4c9fc5',
    color: '#fff',
    padding: '20px',
    width: '100%',
    zIndex: 3,
  };

  const navStyle = {
    listStyle: 'none',
    padding: 0,
    margin: 0
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none'
  };

  return (
    <footer style={footerStyle}>
      <nav>
        <ul style={navStyle}>
          <li style={{ display: 'inline-block', marginRight: '20px' }}><a href="/about" style={linkStyle}>About Us</a></li>
          <li style={{ display: 'inline-block', marginRight: '20px' }}><a href="/contact" style={linkStyle}>Contact</a></li>
          <li style={{ display: 'inline-block' }}><a href="/faq" style={linkStyle}>FAQ</a></li>
        </ul>
      </nav>
      <div>
        <p>&copy; {new Date().getFullYear()} Your Company Name | <a href="/privacy-policy" style={linkStyle}>Privacy Policy</a> | <a href="/terms-of-service" style={linkStyle}>Terms of Service</a></p>
      </div>
      <style>
        {`
          @media (max-width: 576px) {
            footer {
              display: none;
            }
          }
        `}
      </style>
    </footer>
  );
}

export default Footer;
