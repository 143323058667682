import React from "react";
import Footer from '../../Footer';
import Header from "../../Hoheader";
import Changepassword from "../../changepassword";

function Appointment() {
  return (
    <div>
      <Header />
      <Changepassword />;
      <Footer />
    </div>
  );
}

export default Appointment;
