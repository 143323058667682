// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Sidebar from './Sidebar';
// const DoctorsList = () => {
//   const [doctors, setDoctors] = useState([]);

//   useEffect(() => {
//     // Fetch doctors data from API
//     const fetchDoctors = async () => {
//       try {
//         const response = await axios.get('http://localhost:7075/user/doctors', {
//           headers: {
//             'Auth': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJob3NwaXRhbEBnbWFpbC5jb20iLCJpYXQiOjE3MjQ0NzgwNjEsImV4cCI6MTcyNTA4Mjg2MX0.uB_0aDjOykb31Vz_bxjJg2eTMHz6lhYhxSPCiV5JL8BEkk4lBUbTEYKwNX6575evJcI2zH-ek_l-zMzXpRwjBA'
//           }
//         });
//         setDoctors(response.data);
//       } catch (error) {
//         console.error('Error fetching doctors:', error);
//       }
//     };

//     fetchDoctors();
//   }, []);

//   return (
//     <div className="doctors-list">
//           <SidebarStyled>
//     <Sidebar />
//   </SidebarStyled>
//       <style>
//         {`
//           .doctors-list {
//             padding: 20px;
//             max-width: 1000px;
//             margin: auto;
//             font-family: Arial, sans-serif;
//           }

//           h1 {
//             text-align: center;
//             margin-bottom: 20px;
//           }

//           table {
//             width: 100%;
//             border-collapse: collapse;
//           }

//           th, td {
//             padding: 10px;
//             border: 1px solid #ddd;
//             text-align: left;
//           }

//           thead {
//             background-color: #f4f4f4;
//           }

//           tr:nth-child(even) {
//             background-color: #f9f9f9;
//           }
            
//         `}
//       </style>
//       <h1>Doctors List</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Email</th>
//             <th>Mobile No</th>
//             <th>Specialist</th>
//           </tr>
//         </thead>
//         <tbody>
//           {doctors.map((doctor, index) => (
//             <tr key={index}>
//               <td>{doctor.name}</td>
//               <td>{doctor.email}</td>
//               <td>{doctor.mobileNo}</td>
//               <td>{doctor.specialist}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default DoctorsList;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import { BASE_URL } from '../config/apiConfig';
import Header from './Header'; 
const SidebarStyled = styled.div`
  position: fixed; /* Fixes the sidebar in place */
  top: 0;
  left: 0;
  height: 100vh; /* Full height of the viewport */
  width: 250px; /* Adjust width as needed */
  background-color: #f4f4f4;
  overflow-y: auto; /* Scroll if content overflows */
  z-index: 1000; /* Ensure it stays on top of other content */

`;

const MainContent = styled.div`
  margin-left: 250px; /* Space for sidebar */
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #f4f4f4;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
 const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      console.error('No JWT token available for authentication');
      return;
    }

  useEffect(() => {
    // Fetch doctors data from API
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/doctors`, {
          headers: {
            'Auth': `Bearer ${jwtToken}`
          }
        });
        setDoctors(response.data);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };

    fetchDoctors();
  }, []);

  return (
    <div className="doctors-list">
       <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
        <h1>Doctors List</h1>
        <Table>
          <thead>
            <tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Mobile No</Th>
              <Th>Specialist</Th>
            </tr>
          </thead>
          <tbody>
            {doctors.map((doctor, index) => (
              <Tr key={index}>
                <Td>{doctor.name}</Td>
                <Td>{doctor.email}</Td>
                <Td>{doctor.mobileNo}</Td>
                <Td>{doctor.specialist}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </MainContent>
    </div>
  );
};

export default DoctorsList;
