import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import Header from './Header'; 
import { BASE_URL } from '../config/apiConfig';

const SidebarStyled = styled.div`
  position: fixed; /* Fixes the sidebar in place */
  top: 0;
  left: 0;
  height: 100vh; /* Full height of the viewport */
  width: 250px; /* Adjust width as needed */
  background-color: #f4f4f4;
  overflow-y: auto; /* Scroll if content overflows */
  z-index: 1000; /* Ensure it stays on top of other content */

`;

const MainContent = styled.div`
  margin-left: 250px; /* Space for sidebar */
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #f4f4f4;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const HealthOfficersList = () => {
  const [healthOfficers, setHealthOfficers] = useState([]);
 const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      console.error('No JWT token available for authentication');
      return;
    }

  useEffect(() => {
    // Fetch health officers data from API
    const fetchHealthOfficers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/health-officer`, {
          headers: {
           'Auth': `Bearer ${jwtToken}`
          }
        });
        setHealthOfficers(response.data);
      } catch (error) {
        console.error('Error fetching health officers:', error);
      }
    };

    fetchHealthOfficers();
  }, []);

  return (
    <div className="health-officers-list">
       <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
        <h1>Health Officers List</h1>
        <Table>
          <thead>
            <tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Mobile No</Th>
              <Th>Specialist</Th>
            </tr>
          </thead>
          <tbody>
            {healthOfficers.map((officer, index) => (
              <Tr key={index}>
                <Td>{officer.name}</Td>
                <Td>{officer.email}</Td>
                <Td>{officer.mobileNo}</Td>
                <Td>{officer.specialist}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </MainContent>
    </div>
  );
};

export default HealthOfficersList;
