
import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js/auto';
import styled from 'styled-components';
import Footer from "../../Footer";
import Header from "../../Patientheader";

const GraphContainer = styled.div`
  padding: 0;
  background: linear-gradient(90deg, #7CB2D0, #D0E0E5);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
  margin-top: 10px; /* Adjust based on the height of your header */
  margin-bottom: 10px; /* Adjust based on the height of your footer */
  padding: 20px;
`;

const Heading = styled.h1`
  text-align: center;
  color: rgb(50, 78, 106);
  font-size: 40px;
  text-shadow: rgb(187, 17, 17) 0px 0px 32.1px;
`;

const SearchBar = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 250px; /* Set a specific width for the search bar */
`;

const ControlPanel = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 20px; /* Add some space between the control panel and the charts */
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: rgb(50, 78, 106);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px; /* Add margin to the left */
  &:hover {
    background-color: #5a9bbd;
  }
`;

const ChartsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const ChartWrapper = styled.div`
  width: calc(50% - 10px);
  min-width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.h3`
  text-align: center;
  color: rgb(50, 78, 106);
  font-size: 24px;
  margin-bottom: 10px;
`;

const StyledHeader = styled(Header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px; /* Adjust the height as needed */
  background-color: #ffffff; /* Add background color */
  z-index: 1000; /* Ensure it is above other content */
`;

const StyledFooter = styled(Footer)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px; /* Adjust the height as needed */
  background-color: #ffffff; /* Add background color */
  z-index: 1000; /* Ensure it is above other content */
`;

const HeartRateGraph = () => {
  const pulseRateChartRef = useRef(null);
  const respirationRateChartRef = useRef(null);
  const spo2ChartRef = useRef(null);
  const temperatureChartRef = useRef(null);
  const heartRateChartRef = useRef(null); // Added reference for Heart Rate chart
  const BloodpressureChartRef = useRef(null);
  const [timeFrame, setTimeFrame] = useState('week');
  const [searchTerm, setSearchTerm] = useState('');

  const [data, setData] = useState({
    week: {
      labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      pulseRate: [65, 59, 80, 81, 56, 55, 40],
      respirationRate: [30, 35, 40, 42, 38, 36, 34],
      spo2: [95, 93, 91, 92, 94, 96, 97],
      temperature: [36.5, 36.6, 36.7, 36.8, 36.9, 37.0, 36.9],
      heartRate: [70, 72, 75, 78, 80, 82, 85], // Added Heart Rate data
      systolic: [120, 115, 125, 122, 118, 123, 119],
      diastolic: [80, 78, 82, 79, 77, 81, 79],
    },
    month: {
      labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
      pulseRate: [65, 59, 80, 81],
      respirationRate: [30, 35, 40, 42],
      spo2: [95, 93, 91, 92],
      temperature: [36.5, 36.6, 36.7, 36.8],
      heartRate: [70, 72, 75, 78],
      systolic: [120, 115, 125, 122],
      diastolic: [80, 78, 82, 79],
    },
    year: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      pulseRate: [65, 59, 80, 81, 56, 55, 40, 42, 41, 50, 60, 70],
      respirationRate: [30, 35, 40, 42, 38, 36, 34, 37, 39, 35, 33, 32],
      spo2: [95, 93, 91, 92, 94, 96, 97, 98, 96, 95, 94, 93],
      temperature: [36.5, 36.6, 36.7, 36.8, 36.9, 37.0, 36.9, 36.8, 36.7, 36.6, 36.5, 36.4],
      heartRate: [70, 72, 75, 78, 80, 82, 85, 88, 90, 92, 95, 98],
      systolic: [120, 115, 125, 122, 118, 123, 119, 117, 121, 124, 120, 122],
      diastolic: [80, 78, 82, 79, 77, 81, 79, 76, 80, 83, 78, 81],
    },
  });

  const options = {
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Value',
        },
      },
    },
  };
//=========================================pulserate=================================================
  useEffect(() => {
    const labels = data[timeFrame].labels;
    const pulseRateData = {
      labels: labels,
      datasets: [
        {
          label: 'Pulse Rate',
          data: data[timeFrame].pulseRate,
          fill: true,
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          tension: 0.1,
        },
      ],
    };
//=============================================respiration=============================
    const respirationRateData = {
      labels: labels,
      datasets: [
        {
          label: 'Respiration Rate',
          data: data[timeFrame].respirationRate,
          fill: true,
          backgroundColor: 'rgba(54,162,235,0.2)',
          borderColor: 'rgba(54,162,235,1)',
          tension: 0.1,
        },
      ],
    };
//===========================================spo2=========================================
    const spo2Data = {
      labels: labels,
      datasets: [
        {
          label: 'SpO2',
          data: data[timeFrame].spo2,
          fill: true,
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          tension: 0.1,
        },
      ],
    };
//=======================================================temp==========================================
    const temperatureData = {
      labels: labels,
      datasets: [
        {
          label: 'Temperature',
          data: data[timeFrame].temperature,
          fill: true,
          backgroundColor: 'rgba(153,102,255,0.2)',
          borderColor: 'rgba(153,102,255,1)',
          tension: 0.1,
        },
      ],
    };
//==================================================heartrate==============================
    const heartRateData = {
      labels: labels,
      datasets: [
        {
          label: 'Heart Rate',
          data: data[timeFrame].heartRate,
          fill: true,
          backgroundColor: 'rgba(255,159,64,0.2)',
          borderColor: 'rgba(255,159,64,1)',
          tension: 0.1,
        },
      ],
    };
    //====================================bloodPressure====================================
    const bloodpressureData = {
      labels: labels,
      datasets: [
          {
            label: 'Systolic BP',
            data: data[timeFrame].systolic,
            fill: true,
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            tension: 0.1,
          },
          {
            label: 'Diastolic BP',
            data: data[timeFrame].diastolic,
            fill: true,
            backgroundColor: 'rgba(54,162,235,0.2)',
            borderColor: 'rgba(54,162,235,1)',
            tension: 0.1,
          },
        ],
      };
    const pulseRateChartInstance = new Chart(pulseRateChartRef.current, {
      type: 'line',
      data: pulseRateData,
      options: options,
    });

    const respirationRateChartInstance = new Chart(respirationRateChartRef.current, {
      type: 'line',
      data: respirationRateData,
      options: options,
    });

    const spo2ChartInstance = new Chart(spo2ChartRef.current, {
      type: 'line',
      data: spo2Data,
      options: options,
    });

    const temperatureChartInstance = new Chart(temperatureChartRef.current, {
      type: 'line',
      data: temperatureData,
      options: options,
    });

    const heartRateChartInstance = new Chart(heartRateChartRef.current, {
      type: 'line',
      data: heartRateData,
      options: options,
    });
    const bloodpressureInstance = new Chart(BloodpressureChartRef.current, {
      type: 'line',
      data: bloodpressureData,
      options: options,
    });
    return () => {
      pulseRateChartInstance.destroy();
      respirationRateChartInstance.destroy();
      spo2ChartInstance.destroy();
      temperatureChartInstance.destroy();
      heartRateChartInstance.destroy();
      bloodpressureInstance.destroy();
    };
  }, [data, timeFrame]);

  return (
    <GraphContainer>
      <StyledHeader />
      <Content>
        <Heading>Health Metrics Dashboard</Heading>
        <ControlPanel>
          <SearchBar
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div>
            <Button onClick={() => setTimeFrame('week')}>Week</Button>
            <Button onClick={() => setTimeFrame('month')}>Month</Button>
            <Button onClick={() => setTimeFrame('year')}>Year</Button>
          </div>
        </ControlPanel>
        <ChartsContainer>
          <ChartWrapper>
            <Title>Pulse Rate</Title>
            <canvas ref={pulseRateChartRef} />
          </ChartWrapper>
          <ChartWrapper>
            <Title>Respiration Rate</Title>
            <canvas ref={respirationRateChartRef} />
          </ChartWrapper>
          <ChartWrapper>
            <Title>SpO2</Title>
            <canvas ref={spo2ChartRef} />
          </ChartWrapper>
          <ChartWrapper>
            <Title>Temperature</Title>
            <canvas ref={temperatureChartRef} />
          </ChartWrapper>
          <ChartWrapper>
            <Title>Heart Rate</Title>
            <canvas ref={heartRateChartRef} />
          </ChartWrapper>
          <ChartWrapper>
            <Title>Blood </Title>
            <canvas ref={BloodpressureChartRef} />
          </ChartWrapper>
        </ChartsContainer>
      </Content>
      <StyledFooter />
    </GraphContainer>
  );
};

export default HeartRateGraph;
