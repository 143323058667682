
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from '../../config/apiConfig';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'; // Import useLocation
function MainContent() {
    const [patientDetails, setPatientDetails] = useState({
        name: '',
        email: '',
        caseNo: ''
    });
    
    
	const location = useLocation();
    useEffect(() => {
        if (location.state && location.state.patientId) {
            const { patientId } = location.state;
            fetchPatientDetails(patientId);
        } else {
            console.error('No patient ID provided in location state');
        }
    }, [location.state]);

   
        const fetchPatientDetails = async (patientId) => {

            const jwtToken = localStorage.getItem('jwtToken');
            try {
                const response = await axios.get(`${BASE_URL}/registerDocHs/get-patient-details-personal?patientId=${patientId}`, {
                    headers: {
                         'Auth': `Bearer ${jwtToken}`
                    }
                });
                const data = response.data;
                setPatientDetails({
                    name: data.patientName,
                    email: data.email,
                    caseNo: data.caseNo
                });
            } catch (error) {
                console.error('Error fetching patient details:', error);
            }
        };

        fetchPatientDetails();
   

    const detailFieldStyle = {
        flex: '1',
        marginRight: '20px',
        padding: '10px',
        color: '#434343',
        background: '#D9D9D9',
        borderRadius: '5px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        border: '1px solid #434343'
    };

    const mainContentStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
    };

    return (
        <div className="main-content">
            <div style={mainContentStyle}>
                <div className="patient-details" style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                    <div className="detail-field" style={detailFieldStyle}>
                        <span>Name: </span> <span>{patientDetails.name}</span>
                    </div>
                    <div className="detail-field" style={detailFieldStyle}>
                        <span>Email: </span> <span>{patientDetails.email}</span>
                    </div>
                    <div className="detail-field" style={detailFieldStyle}>
                        <span>Case No: </span> <span>{patientDetails.caseNo}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContent;
