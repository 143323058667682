
// // import React, { useState, useEffect, forwardRef } from "react";
// import Calendar from "../../../Assets/Calendar.png";
// import Patientheader from "../../Patientheader";
// import Footer from "../../Footer";
// import Header from "../Header/Header";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// // import BASE_URL from '../../config/apiConfig';


// // const Modal = ({ isOpen, onClose, data }) => {
// //   if (!isOpen) return null;

// //   const prescription = data.prescriptions ? data.prescriptions[0] : null; // Assuming one prescription per data entry

// //   return (
// //     <div style={styles.modalBackground}>
// //       <div style={styles.modalContent}>
// //         <button onClick={onClose} style={styles.closeButton}>×</button>
// //         <div style={styles.header}>Prescription Details</div>
// //         {prescription ? (
// //           <div style={styles.detailsContainer}>
// //             <div style={styles.detailItem}>
// //               <strong>Chief Complaints:</strong> {prescription.chiefComplaints || 'N/A'}
// //             </div>
// //             <div style={styles.detailItem}>
// //               <strong>Symptoms:</strong> {prescription.symptoms || 'N/A'}
// //             </div>
// //             <div style={styles.detailItem}>
// //               <strong>Advice:</strong> {prescription.advice || 'N/A'}
// //             </div>
// //             <div style={styles.detailItem}>
// //               <strong>Provisional Diagnosis:</strong> {prescription.provisionalDiagnosis || 'N/A'}
// //             </div>
// //             <div style={styles.detailItem}>
// //               <strong>Allergy:</strong> {prescription.allergy || 'N/A'}
// //             </div>
// //             <div style={styles.detailItem}>
// //               <strong>Comorbidity:</strong> {prescription.comorbidity || 'N/A'}
// //             </div>
// //             <div style={styles.detailItem}>
// //               <strong>Follow-up Date:</strong> {prescription.followUpDate || 'N/A'}
// //             </div>
// //             <div style={styles.detailItem}>
// //               <strong>Other Illnesses:</strong> {prescription.otherIllnesses ? prescription.otherIllnesses.join(', ') : 'N/A'}
// //             </div>
// //             <div style={styles.detailItem}>
// //               <strong>Medications:</strong>
// //               <ul style={styles.list}>
// //                 {prescription.medications && prescription.medications.map((med, index) => (
// //                   <li key={index} style={styles.listItem}>
// //                     {med.medication} - {med.frequency}, {med.dosage} for {med.days} days
// //                   </li>
// //                 ))}
// //               </ul>
// //             </div>
// //             <div style={styles.detailItem}>
// //               <strong>Laboratory Tests:</strong>
// //               <ul style={styles.list}>
// //                 {prescription.laboratory && prescription.laboratory.map((lab, index) => (
// //                   <li key={index} style={styles.listItem}>
// //                     {lab.laboratory}
// //                   </li>
// //                 ))}
// //               </ul>
// //             </div>
// //           </div>
// //         ) : (
// //           <div>No prescription details available.</div>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };
// // // Component Styles
// const styles = {
//   appointmentHistory: {
//     textAlign: 'center',
//     color: '#324E6A',
//     fontSize: '50px',
//     textShadow: '0px 0px 32.1px #BB1111',
//   },
//   tableContainer: {
//     maxWidth: '1200px',
//     margin: '0 auto',
//     padding: '20px',
//     backgroundColor: '#DCE7EB',
//     border: '2px solid #104E8D',
//     borderBottom: '2px solid #104E8D',
//     overflowY: 'auto',
//     marginBottom: '10px',
//     height: 'auto',
//     position: 'relative',
//   },
//   table: {
//     width: '100%',
//     borderCollapse: 'separate',
//     border: '2px solid #D9D9D9',
//   },
//   th: {
//     backgroundColor: '#0F60B3',
//     color: 'white',
//     padding: '12px 8px',
//     fontWeight: 'bold',
//     textAlign: 'left',
//     borderBottom: '1px solid #fff',
//     borderRight: '2px solid #fff',
//   },
//   td: {
//     padding: '8px',
//     borderBottom: '1px solid #ddd',
//     textAlign: 'left',
//     borderRight: '2px solid #ddd',
//   },
//   lastColumn: {
//     borderRight: '2px solid #fff',
//   },
//   columnStyles: {
//     patientUid: { maxWidth: '100px' },
//     doctor: { maxWidth: '80px' },
//     remarks: { maxWidth: '150px' },
//     appointmentTime: { maxWidth: '100px' },
//     status: { maxWidth: '100px' },
//     prescription: { maxWidth: '100px' },
//   },
//   modalBackground: {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0,0,0,0.5)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   modalContent: {
//     backgroundColor: 'white',
//     padding: '20px',
//     borderRadius: '8px',
//     width: '80%',
//     maxWidth: '600px',
//   },
//   closeButton: {
//     marginTop: '10px',
//     padding: '10px',
//     backgroundColor: '#007BFF',
//     color: 'white',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//   },
//   datePickerContainer: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   customInput: {
//     display: 'flex',
//     alignItems: 'center',
//     cursor: 'pointer',
//   },
//   calendarIcon: {
//     width: '40px',
//     height: '40px',
//     marginRight: '10px',
//   },
//   input: {
//     backgroundColor: '#D9D9D9',
//     border: 'none',
//     outline: 'none',
//     borderRadius: '5px',
//     padding: '5px',
//     marginBottom: '5px',
//   },
// };

// const CustomInput = forwardRef(({ value, onClick }, ref) => (
//   <div onClick={onClick} ref={ref} style={styles.customInput}>
//     <img src={Calendar} alt="Calendar" style={styles.calendarIcon} />
//     <input
//       type="text"
//       value={value}
//       readOnly
//       style={styles.input}
//     />
//   </div>
// ));

// // function AppointmentHistory() {
// //   const [range, setRange] = useState("1-2");
  // const [startDate, setStartDate] = useState(null);
  // const [data, setData] = useState([]);
// //   const [selectedPrescription, setSelectedPrescription] = useState(null);
// //   const [modalOpen, setModalOpen] = useState(false);

// //   useEffect(() => {
// //     const jwtToken = localStorage.getItem('jwtToken');
// //     console.log('JWT Token=======================:', jwtToken);
// //     // Fetch data from API
// //     fetch(`${BASE_URL}/api/patients/patient-all-pre-consult`, {
// //       method: 'GET',
// //       headers: {
// //         'Content-Type': 'application/json',
// //        'Auth': `Bearer ${jwtToken}`
// //       }
// //     })
// //     .then(response => response.json())
// //     .then(data => {
// //       setData(data);
// //     })
// //     .catch(error => {
// //       console.error('Error fetching data:', error);
// //     });
// //   }, []);

// //   const handleRangeChange = (event) => {
// //     setRange(event.target.value);
// //   };

// //   const handlePrescriptionClick = (prescription) => {
// //     setSelectedPrescription(prescription);
// //     setModalOpen(true);
// //   };

// //   const handleCloseModal = () => {
// //     setModalOpen(false);
// //     setSelectedPrescription(null);
// //   };

// //   const rowHeight = 45;
// //   const numberOfDataRows = 10;
// //   const headerHeight = 100;
// //   const totalRows = 2;
// //   const displayedRows = Math.max(totalRows, numberOfDataRows);
// //   const containerHeight = headerHeight + rowHeight * displayedRows;

// //   return (
// //     <div style={{ background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)', minHeight: '100vh' }}>
      // <Patientheader />
      // <div style={styles.appointmentHistory}>
      //   User Dashboard
      // </div>
      // <div style={{ ...styles.tableContainer, height: containerHeight }}>
      //   <Header />
      //   <div style={styles.datePickerContainer}>
      //     <div style={styles.datePickerContainer}>
      //       <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
      //       <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
      //         <option value="1-2">1-2</option>
      //         <option value="3-4">3-4</option>
      //       </select>
      //       <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
      //     </div>
      //     <span style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', color: '#324E6A' }}>
      //       <DatePicker
      //         selected={startDate}
      //         onChange={(date) => setStartDate(date)}
      //         customInput={<CustomInput />}
      //       />
      //     </span>
      //   </div>
// //         <table style={styles.table}>
// //           <thead>
// //             <tr>
// //               <th style={{ ...styles.th, ...styles.columnStyles.patientUid }}>Patient UID</th>
// //               <th style={{ ...styles.th, ...styles.columnStyles.doctor }}>Doctor</th>
// //               <th style={{ ...styles.th, ...styles.columnStyles.remarks }}>Remarks</th>
// //               <th style={{ ...styles.th, ...styles.columnStyles.appointmentTime }}>Appointment Time</th>
// //               <th style={{ ...styles.th, ...styles.columnStyles.status }}>Status</th>
// //               <th style={{ ...styles.th, ...styles.columnStyles.prescription, ...styles.lastColumn }}>Prescription</th>
// //             </tr>
// //           </thead>
// //           <tbody style={{ overflowX: 'auto' }}>
// //             {data.map((item, index) => (
// //               <tr key={index} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.patientUid }}>{item.caseNo}</td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.doctor }}>{item.doctor}</td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.remarks }}>{item.complaints}</td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.appointmentTime }}>{item.localDate}</td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.status }}>{item.status}</td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.prescription, ...styles.lastColumn }} onClick={() => handlePrescriptionClick(item)}>
// //                   {item.prescription || 'view'}
// //                 </td>
// //               </tr>
// //             ))}
// //             {[...Array(displayedRows - data.length)].map((_, index) => (
// //               <tr key={index + data.length} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.patientUid }}></td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.doctor }}></td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.remarks }}></td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.appointmentTime }}></td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.status }}></td>
// //                 <td style={{ ...styles.td, ...styles.columnStyles.prescription, ...styles.lastColumn }}></td>
// //               </tr>
// //             ))}
// //           </tbody>
// //         </table>
// //       </div>
// //       <Footer />
// //       <Modal isOpen={modalOpen} onClose={handleCloseModal} data={selectedPrescription} />
// //     </div>
// //   );
// // }

// // export default AppointmentHistory;

import React, { useState, useEffect, forwardRef } from "react";
import axios from "axios";
import Patientheader from "../../Patientheader";
import Footer from "../../Footer";
import Header from "../Header/Header";
import { BASE_URL } from '../../config/apiConfig';
import 'jspdf-autotable';
import jsPDF from "jspdf";
import logo from "../../../Assets/logo.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Calendar from "../../../Assets/Calendar.png";
const pageStyle = {
  background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)',
};

const appointmentHistoryStyle = {
  textAlign: 'center',
  color: '#324E6A',
  fontSize: '50px',
  textShadow: '0px 0px 32.1px #BB1111',
};

const tableContainerStyle = {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#DCE7EB',


  overflowY: 'auto',
  marginBottom: '10px',
  maxHeight: '600px',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'separate',
  border: '2px solid #D9D9D9',
};

const thStyle = {
  backgroundColor: '#0F60B3',
  color: 'white',
  padding: '12px 8px',
  fontWeight: 'bold',
  textAlign: 'left',
  borderBottom: '1px solid #fff',
  borderRight: '2px solid #fff',
};

const tdStyle = {
  padding: '8px',
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  borderRight: '2px solid #ddd',
  cursor: 'pointer',
};

const lastColumnStyle = {
  borderRight: '2px solid #fff',
};
const styles = {
  appointmentHistory: {
    textAlign: 'center',
    color: '#324E6A',
    fontSize: '50px',
    textShadow: '0px 0px 32.1px #BB1111',
  },
  tableContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#DCE7EB',
    border: '2px solid #104E8D',
    borderBottom: '2px solid #104E8D',
    overflowY: 'auto',
    marginBottom: '10px',
    height: 'auto',
    position: 'relative',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    border: '2px solid #D9D9D9',
  },
  th: {
    backgroundColor: '#0F60B3',
    color: 'white',
    padding: '12px 8px',
    fontWeight: 'bold',
    textAlign: 'left',
    borderBottom: '1px solid #fff',
    borderRight: '2px solid #fff',
  },
  td: {
    padding: '8px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    borderRight: '2px solid #ddd',
  },
  lastColumn: {
    borderRight: '2px solid #fff',
  },

 

  closeButton: {
    marginTop: '10px',
    padding: '10px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  customInput: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  calendarIcon: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
  },
  input: {
    backgroundColor: '#D9D9D9',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    padding: '5px',
    marginBottom: '5px',
  },
};

const columnStyles = {
  patient: { maxWidth: '100px' },
  doctor: { maxWidth: '80px' },
  complaint: { maxWidth: '150px' },
  appointmentTime: { maxWidth: '100px' },
  status: { maxWidth: '100px' },
  action: { maxWidth: '100px' },
};

const popupStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  padding: '20px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  width: '700px',
  borderRadius: '10px',
};

const popupOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  fontSize: '20px',
};

const generatePDF = (data) => {
  const doc = new jsPDF();
  doc.setFontSize(12);
  let y = 10;

  const img = new Image();
  img.src = logo;
  img.onload = function () {
    const personalInfoX = 10;
    const logoX = 90;
    const hospitalInfoX = 150;

    doc.setFontSize(12);
    doc.text(`ID: ${data.patientId} - ${data.patientName} (${data.gender} / ${data.age} Y)`, personalInfoX, y + 5);
    doc.text(`Mob. No.: ${data.mobileNumber}`, personalInfoX, y + 10);
    doc.text(`Address: ${data.patientAddress}`, personalInfoX, y + 15);
    doc.text(`Weight: ${data.weight} Kg, Height: ${data.height} Cm`, personalInfoX, y + 20);
    doc.text(`Blood Group: ${data.bloodGroup}, BP: ${data.diastolicBP} mmHg`, personalInfoX, y + 25);

    doc.addImage(img, 'PNG', logoX, y, 30, 30);

    doc.setFontSize(14);
    doc.text(`Dr. ${data.doctorName}`, hospitalInfoX, y + 5);
    doc.setFontSize(10);
    doc.text(`${data.hospitalName}`, hospitalInfoX, y + 10);
    doc.text(`${data.hospitalAddress}`, hospitalInfoX, y + 15);
    doc.text(`Ph: ${data.hospitalMobileNumber}`, hospitalInfoX, y + 20);
    doc.text(`Timing: ${data.hospitalTiming}`, hospitalInfoX, y + 25);
    doc.text(`Closed: ${data.hospitalClosedDays}`, hospitalInfoX, y + 30);

    y += 40;

    doc.line(10, y, 200, y);

    y += 10;
    doc.setFontSize(12);
    doc.text('Chief Complaints:', 10, y);
    y += 5;
    doc.text(`- ${data.prescriptions[0].chiefComplaints}`, 15, y);
    y += 10;
    doc.text('Diagnosis:', 10, y);
    y += 5;
    doc.text(`- ${data.prescriptions[0].provisionalDiagnosis}`, 15, y);

    y += 10;
    doc.line(10, y, 200, y);

    y += 10;
    doc.text('Medications:', 10, y);
    y += 5;

    doc.autoTable({
      startY: y,
      head: [['#', 'Medicine Name', 'Dosage', 'Duration']],
      body: data.prescriptions[0].medications.map((med, index) => [
        index + 1,
        med.medication,
        med.dosage,
        `${med.days} Days`,
      ]),
      theme: 'plain',
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { textColor: [0, 0, 0] },
    });

    y = doc.autoTable.previous.finalY + 10;
    doc.line(10, y, 200, y);

    y += 10;
    doc.text('Laboratory Tests:', 10, y);
    y += 5;

    doc.autoTable({
      startY: y,
      head: [['#', 'Test Name']],
      body: data.prescriptions[0].laboratory.map((lab, index) => [
        index + 1,
        lab.laboratory,
      ]),
      theme: 'plain',
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { textColor: [0, 0, 0] },
    });

    y = doc.autoTable.previous.finalY + 10;
    doc.line(10, y, 200, y);

    y += 10;
    doc.text('Advice:', 10, y);
    y += 5;
    doc.text(`- ${data.prescriptions[0].advice}`, 15, y);
    y += 10;
    doc.text(`Follow-up Date: ${data.prescriptions[0].followUpDate}`, 10, y);

    doc.save('prescription.pdf');
  };
};


const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div onClick={onClick} ref={ref} style={styles.customInput}>
    <img src={Calendar} alt="Calendar" style={styles.calendarIcon} />
    <input
      type="text"
      value={value}
      readOnly
      style={styles.input}
    />
  </div>
));

function AppointmentHistory() {
  const [range, setRange] = useState("1-2");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [appointmentHistory, setAppointmentHistory] = useState([]);
    const [startDate, setStartDate] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const jwtToken = localStorage.getItem('jwtToken');
    
      if (!jwtToken) {
        console.error('No JWT token available for authentication');
        return;
      }
      
      try {
        const response = await axios.get(`${BASE_URL}/api/patients/patient-all-pre-consult`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${jwtToken}`,
          }
        });
        setAppointmentHistory(response.data);
      } catch (error) {
        console.error('Error fetching appointment history:', error);
      }
    };

    fetchData();
  }, []);

  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };

  const handlePrescriptionClick = (data) => {
    setSelectedData(data);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedData(null);
  };

  const rowHeight = 45;
  const numberOfDataRows = 10;
  const headerHeight = 100;
  const totalRows = appointmentHistory.length;
  const displayedRows = Math.max(totalRows, numberOfDataRows);
  const containerHeight = headerHeight + rowHeight * displayedRows;

  return (
    <div style={pageStyle}>
            <Patientheader />
      <div style={styles.appointmentHistory}>
        User Dashboard
      </div>
      <div style={{ ...styles.tableContainer, height: containerHeight }}>
        <Header />
        <div style={styles.datePickerContainer}>
          <div style={styles.datePickerContainer}>
            <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
            <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
            </select>
            <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
          </div>
          <span style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', color: '#324E6A' }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<CustomInput />}
            />
          </span>
        </div>
        <div style={{ ...tableContainerStyle, height: `${containerHeight}px` }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, ...columnStyles.patient }}>Patient ID</th>
                <th style={{ ...thStyle, ...columnStyles.doctor }}>Doctor Name</th>
                <th style={{ ...thStyle, ...columnStyles.complaint }}>Complaint</th>
                <th style={{ ...thStyle, ...columnStyles.appointmentTime }}>Appointment Time</th>
                <th style={{ ...thStyle, ...columnStyles.status }}>Status</th>
                <th style={{ ...thStyle, ...lastColumnStyle }}>Prescription</th>
              </tr>
            </thead>
          <tbody style={{ overflowX: 'auto' }}>
            {appointmentHistory.map((data, index) => (
              <tr key={index} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                  <td style={{ ...tdStyle, ...columnStyles.patient }}>{data.id}</td>
                  <td style={{ ...tdStyle, ...columnStyles.doctor }}>{data.doctor}</td>
                  <td style={{ ...tdStyle, ...columnStyles.complaint }}>{data.complaints}</td>
                  <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}>{data.localDate}</td>
                  <td style={{ ...tdStyle, ...columnStyles.status }}>{data.status}</td>
                  <td style={{ ...tdStyle, ...lastColumnStyle }}>
                    
                    <button
  onClick={() => handlePrescriptionClick(data)}
  style={{
    color: '#FFFFFF',       // White text color
    backgroundColor: '#0000FF', // Blue background color
    border: 'none',          // Optional: removes the default border
    padding: '8px 16px',     // Optional: adds some padding
    borderRadius: '4px',     // Optional: rounds the corners
    cursor: 'pointer'        // Optional: changes the cursor to a pointer
  }}
>
  View
</button>

                  </td>
                </tr>
              ))}


 {[...Array(displayedRows - totalRows)].map((_, index) => (
              <tr key={index + totalRows} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.patient }}></td>
                <td style={{ ...tdStyle, ...columnStyles.doctor }}></td>
                <td style={{ ...tdStyle, ...columnStyles.complaint }}></td>
                <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}></td>
                <td style={{ ...tdStyle, ...columnStyles.status }}></td>
                <td style={{ ...tdStyle, ...columnStyles.action, ...lastColumnStyle }}></td>
              </tr>
            ))}

              
            </tbody>
          </table>
        </div>
      </div>
      <Footer />

     {showPopup && (
  <div style={popupOverlayStyle}>
    <div style={{ ...popupStyle, maxWidth: '800px', maxHeight: '80vh', overflow: 'auto' }}>
      <button onClick={closePopup} style={closeButtonStyle}>×</button>

      {/* Flex container to align Doctor Info on the left and Patient Info on the right */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
  
        {/* Patient Information */}
        <div style={{ flex: '1', textAlign: 'left', marginRight: '20px' }}>
          <div><strong>ID:</strong> {selectedData.patientId} - {selectedData.patientName} ({selectedData.gender} / {selectedData.age} Y)</div>
          <div><strong>Mob. No.:</strong> {selectedData.mobileNumber}</div>
          <div><strong>Address:</strong> {selectedData.patientAddress}</div>
          <div><strong>Weight:</strong> {selectedData.weight} Kg, <strong>Height:</strong> {selectedData.height} Cm</div>
          <div><strong>Blood Group:</strong> {selectedData.bloodGroup}, <strong>BP:</strong> {selectedData.diastolicBP} mmHg</div>
        </div>

        {/* Logo */}
        <div style={{ flex: '0', textAlign: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '80px', margin: '0 20px' }} />
        </div>

        {/* Header with Doctor and Hospital Info */}
        <div style={{ flex: '1', textAlign: 'right' }}>
          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>Dr. {selectedData.doctorName}</div>
          <div style={{ fontSize: '14px' }}>{selectedData.hospitalName}</div>
          <div style={{ fontSize: '12px' }}>{selectedData.hospitalAddress}</div>
          <div style={{ fontSize: '12px', marginTop: '5px' }}>Ph: {selectedData.hospitalMobileNumber}</div>
          <div style={{ fontSize: '12px' }}>Timing: {selectedData.hospitalTiming}</div>
          <div style={{ fontSize: '12px' }}>Closed: {selectedData.hospitalClosedDays}</div>
        </div>

      </div>

      {/* Clinical Information */}
      <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
        <div style={{ marginBottom: '10px' }}>
          <strong>Chief Complaints:</strong>
          <ul>
            <li>{selectedData.prescriptions[0].chiefComplaints}</li>
          </ul>
       
          <strong>Diagnosis:</strong>
          <ul>
            <li>{selectedData.prescriptions[0].provisionalDiagnosis}</li>
          </ul>
        </div>
      </div>

      {/* Medication List */}
      <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
        <div style={{ marginBottom: '15px' }}>
          <strong>Medications:</strong>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '5px' }}>#</th>
                <th style={{ border: '1px solid black', padding: '5px' }}>Medicine Name</th>
                <th style={{ border: '1px solid black', padding: '5px' }}>Dosage</th>
                <th style={{ border: '1px solid black', padding: '5px' }}>Duration</th>
              </tr>
            </thead>
            <tbody>
              {selectedData.prescriptions[0].medications.map((med, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '5px' }}>{med.medication}</td>
                  <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{med.dosage}</td>
                  <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{med.days} Days</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Laboratory List */}
      <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
        <div style={{ marginBottom: '15px' }}>
          <strong>Laboratory Tests:</strong>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '5px' }}>#</th>
                <th style={{ border: '1px solid black', padding: '5px' }}>Test Name</th>
              </tr>
            </thead>
            <tbody>
              {selectedData.prescriptions[0].laboratory.map((lab, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '5px' }}>{lab.laboratory}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Advice and Follow-up */}
      <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
        <strong>Advice:</strong>
        <ul>
          <li>{selectedData.prescriptions[0].advice}</li>
        </ul>
        <strong>Follow-up:</strong> {selectedData.prescriptions[0].followUpDate}
      </div>

      {/* Download Button */}
      <button onClick={() => generatePDF(selectedData)} style={{ marginTop: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
        Download PDF
      </button>
    </div>
  </div>
)}
    </div>
  );
}

export default AppointmentHistory;
