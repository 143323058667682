
// // // // // // // // // // // import Homeicon from "../Assets/Home icon.png"; 
// // // // // // // // // // // import settingicon from "../Assets/setting icon.png"; 
// // // // // // // // // // // import React, { useState } from "react";
// // // // // // // // // // // import { Link, useNavigate } from "react-router-dom";
// // // // // // // // // // // import notificationIcon from "../Assets/notification icon.png"; // Corrected variable name
// // // // // // // // // // // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // // // // // // // // // // function Navbar() {
// // // // // // // // // // //   const [showNotification, setShowNotification] = useState(false);
// // // // // // // // // // //   const [showPopup, setShowPopup] = useState(false);
// // // // // // // // // // //   const navigate = useNavigate();

// // // // // // // // // // //   const toggleNotification = () => {
// // // // // // // // // // //     setShowNotification(!showNotification); // Toggle notification box visibility
// // // // // // // // // // //   };

// // // // // // // // // // //   const togglePopup = () => {
// // // // // // // // // // //     setShowPopup(!showPopup); // Toggle popup visibility
// // // // // // // // // // //   };

// // // // // // // // // // //   const logout = () => {
// // // // // // // // // // //     localStorage.removeItem("token"); // Remove token from localStorage
// // // // // // // // // // //     navigate("/"); // Redirect to login page
// // // // // // // // // // //   };

// // // // // // // // // // //   return (
// // // // // // // // // // //     <div className="navbar-section">
// // // // // // // // // // //       {/* <h1 className="navbar-title">
// // // // // // // // // // //         <Link to="/dashdoc">
// // // // // // // // // // //           O2I <span className="navbar-sign">+</span>
// // // // // // // // // // //         </Link>
// // // // // // // // // // //       </h1> */}
// // // // // // // // // // // <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // // // // // // // // // //     <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // // // // // // // // // // </div>

// // // // // // // // // // //       {/* Desktop */}
// // // // // // // // // // //       <ul className="navbar-items">
// // // // // // // // // // //         <li>
// // // // // // // // // // //           <Link to="/dashdoc"> {/* Added Link wrapper for the Home icon */}
// // // // // // // // // // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // // // // // // // // // //           </Link>
// // // // // // // // // // //         </li>
// // // // // // // // // // //         <li>
// // // // // // // // // // //           <img src={notificationIcon} alt="Notification Icon" className="history-icon" onClick={toggleNotification} />
// // // // // // // // // // //           {showNotification && (
// // // // // // // // // // //             <div className="notification-box">
// // // // // // // // // // //               <ul>
// // // // // // // // // // //                 <li>Appointment Scheduled</li>
// // // // // // // // // // //               </ul>
// // // // // // // // // // //             </div>
// // // // // // // // // // //           )}
// // // // // // // // // // //         </li>
// // // // // // // // // // //         <li>
// // // // // // // // // // //           <img 
// // // // // // // // // // //             src={settingicon} 
// // // // // // // // // // //             alt="Setting Icon" 
// // // // // // // // // // //             className="history-icon" 
// // // // // // // // // // //             onClick={togglePopup}
// // // // // // // // // // //           />
// // // // // // // // // // //           {showPopup && (
// // // // // // // // // // //             <div className="popup show">
// // // // // // // // // // //               <ul>
// // // // // // // // // // //                 <li>Dr.</li>
// // // // // // // // // // //                 {/* <li><Link to="/userdashboard">PatientData</Link></li> */}
// // // // // // // // // // //                 <li><Link to="/profile">My Profile</Link></li>
// // // // // // // // // // //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// // // // // // // // // // //                 <li><Link to="/" onClick={logout}>Logout</Link></li> {/* Use button for logout */}
// // // // // // // // // // //               </ul>
// // // // // // // // // // //             </div>
// // // // // // // // // // //           )}
// // // // // // // // // // //         </li>
// // // // // // // // // // //       </ul>
// // // // // // // // // // //     </div>
// // // // // // // // // // //   );
// // // // // // // // // // // }

// // // // // // // // // // // export default Navbar;


// // // // // // // // // // //==========================testing=============================================28/08/
// // // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // // import { Link, useNavigate } from "react-router-dom";
// // // // // // // // // // import Homeicon from "../Assets/Home icon.png";
// // // // // // // // // // import settingicon from "../Assets/setting icon.png";
// // // // // // // // // // import notificationIcon from "../Assets/notification icon.png";
// // // // // // // // // // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // // // // // // // // // import { BASE_URL } from './config/apiConfig';


// // // // // // // // // // function Navbar() {
// // // // // // // // // //   const [showNotification, setShowNotification] = useState(false);
// // // // // // // // // //   const [showPopup, setShowPopup] = useState(false);
// // // // // // // // // //   const [doctorName, setDoctorName] = useState(""); // State to store doctor's name
// // // // // // // // // //   const navigate = useNavigate();

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     const fetchDoctorDetails = async () => {
// // // // // // // // // //       try {
// // // // // // // // // //         const token = localStorage.getItem('jwtToken'); // Adjust the key if needed

// // // // // // // // // //         if (!token) {
// // // // // // // // // //           console.error('No JWT token found in localStorage');
// // // // // // // // // //           return;
// // // // // // // // // //         }

// // // // // // // // // //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// // // // // // // // // //           headers: {
// // // // // // // // // //             'Auth': `Bearer ${token}`,
// // // // // // // // // //             'Content-Type': 'application/json'
// // // // // // // // // //           }
// // // // // // // // // //         });

// // // // // // // // // //         if (!response.ok) {
// // // // // // // // // //           throw new Error(`HTTP error! Status: ${response.status}`);
// // // // // // // // // //         }

// // // // // // // // // //         const data = await response.json();
// // // // // // // // // //         const doctor = data.name; // Adjust based on your API response structure
// // // // // // // // // //         if (doctor) {
// // // // // // // // // //           const capitalizedDoctorName = doctor.charAt(0).toUpperCase() + doctor.slice(1);
// // // // // // // // // //           setDoctorName(capitalizedDoctorName);
// // // // // // // // // //         }
// // // // // // // // // //       } catch (error) {
// // // // // // // // // //         console.error('Error fetching doctor details:', error);
// // // // // // // // // //       }
// // // // // // // // // //     };

// // // // // // // // // //     fetchDoctorDetails();
// // // // // // // // // //   }, []);

// // // // // // // // // //   const toggleNotification = () => {
// // // // // // // // // //     setShowNotification(!showNotification);
// // // // // // // // // //   };

// // // // // // // // // //   const togglePopup = () => {
// // // // // // // // // //     setShowPopup(!showPopup); // Toggle popup visibility
// // // // // // // // // //   };

// // // // // // // // // //   const logout = () => {
// // // // // // // // // //     localStorage.removeItem("jwtToken"); // Remove token from localStorage
// // // // // // // // // //     navigate("/"); // Redirect to login page
// // // // // // // // // //   };

// // // // // // // // // //   return (
// // // // // // // // // //     <div className="navbar-section">
// // // // // // // // // //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // // // // // // // // //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // // // // // // // // //       </div>

// // // // // // // // // //       {/* Desktop */}
// // // // // // // // // //       <ul className="navbar-items">
// // // // // // // // // //         <li>
// // // // // // // // // //           <Link to="/dashdoc"> {/* Added Link wrapper for the Home icon */}
// // // // // // // // // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // // // // // // // // //           </Link>
// // // // // // // // // //         </li>
// // // // // // // // // //         <li>
// // // // // // // // // //           <img
// // // // // // // // // //             src={notificationIcon}
// // // // // // // // // //             alt="Notification Icon"
// // // // // // // // // //             className="history-icon"
// // // // // // // // // //             onClick={toggleNotification}
// // // // // // // // // //           />
// // // // // // // // // //           {showNotification && (
// // // // // // // // // //             <div className="notification-box">
// // // // // // // // // //               <ul>
// // // // // // // // // //                 <li>Appointment Scheduled</li>
// // // // // // // // // //               </ul>
// // // // // // // // // //             </div>
// // // // // // // // // //           )}
// // // // // // // // // //         </li>
// // // // // // // // // //         <li>
// // // // // // // // // //           <img
// // // // // // // // // //             src={settingicon}
// // // // // // // // // //             alt="Setting Icon"
// // // // // // // // // //             className="history-icon"
// // // // // // // // // //             onClick={togglePopup}
// // // // // // // // // //           />
// // // // // // // // // //           {showPopup && (
// // // // // // // // // //             <div className="popup show">
// // // // // // // // // //               <ul>
// // // // // // // // // //                 <li>{doctorName ? `Dr. ${doctorName}` : 'Dr.'}</li>
// // // // // // // // // //                 <li><Link to="/profile">My Profile</Link></li>
// // // // // // // // // //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// // // // // // // // // //                 <li><Link to="/" onClick={logout}>Logout</Link></li> {/* Use button for logout */}
// // // // // // // // // //               </ul>
// // // // // // // // // //             </div>
// // // // // // // // // //           )}
// // // // // // // // // //         </li>
// // // // // // // // // //       </ul>
// // // // // // // // // //     </div>
// // // // // // // // // //   );
// // // // // // // // // // }

// // // // // // // // // // export default Navbar;
// // // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // // import { Link, useNavigate } from "react-router-dom";
// // // // // // // // // import Homeicon from "../Assets/Home icon.png";
// // // // // // // // // import settingicon from "../Assets/setting icon.png";
// // // // // // // // // import notificationIcon from "../Assets/notification icon.png";
// // // // // // // // // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // // // // // // // // import { BASE_URL } from './config/apiConfig';

// // // // // // // // // function Navbar() {
// // // // // // // // //   const [showNotification, setShowNotification] = useState(false);
// // // // // // // // //   const [showPopup, setShowPopup] = useState(false);
// // // // // // // // //   const [doctorInfo, setDoctorInfo] = useState({}); // State to store doctor information
// // // // // // // // //   const navigate = useNavigate();

// // // // // // // // //   useEffect(() => {
// // // // // // // // //     const fetchDoctorDetails = async () => {
// // // // // // // // //       try {
// // // // // // // // //         const token = localStorage.getItem('jwtToken'); // Adjust the key if needed

// // // // // // // // //         if (!token) {
// // // // // // // // //           console.error('No JWT token found in localStorage');
// // // // // // // // //           return;
// // // // // // // // //         }

// // // // // // // // //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// // // // // // // // //           headers: {
// // // // // // // // //             'Auth': `Bearer ${token}`,
// // // // // // // // //             'Content-Type': 'application/json'
// // // // // // // // //           }
// // // // // // // // //         });

// // // // // // // // //         if (!response.ok) {
// // // // // // // // //           throw new Error(`HTTP error! Status: ${response.status}`);
// // // // // // // // //         }

// // // // // // // // //         const data = await response.json();
// // // // // // // // //         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
// // // // // // // // //         setDoctorInfo({
// // // // // // // // //           name: capitalizedDoctorName,
// // // // // // // // //           email: data.email,
// // // // // // // // //           hospitalName: data.hospitalName,
// // // // // // // // //           hospitalAddress: data.hospitalAddress,
// // // // // // // // //           degree: data.docHsProfileDegree,
// // // // // // // // //           specialist: data.docHsProfileSpecilist,
// // // // // // // // //           image: data.docHsProfileImage,
// // // // // // // // //           experience: data.docHsProfileExperience,
// // // // // // // // //           address: data.docHsProfileAddress,
// // // // // // // // //           age: data.docHsProfileHsAge,
// // // // // // // // //           gender: data.docHsProfileGender,
// // // // // // // // //           bloodGroup: data.docHsProfileBloodGroup,
// // // // // // // // //         });
// // // // // // // // //       } catch (error) {
// // // // // // // // //         console.error('Error fetching doctor details:', error);
// // // // // // // // //       }
// // // // // // // // //     };

// // // // // // // // //     fetchDoctorDetails();
// // // // // // // // //   }, []);

// // // // // // // // //   const toggleNotification = () => {
// // // // // // // // //     setShowNotification(!showNotification);
// // // // // // // // //   };

// // // // // // // // //   const togglePopup = () => {
// // // // // // // // //     setShowPopup(!showPopup); // Toggle popup visibility
// // // // // // // // //   };

// // // // // // // // //   const logout = () => {
// // // // // // // // //     localStorage.removeItem("jwtToken"); // Remove token from localStorage
// // // // // // // // //     navigate("/"); // Redirect to login page
// // // // // // // // //   };

// // // // // // // // //   return (
// // // // // // // // //     <div className="navbar-section">
// // // // // // // // //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // // // // // // // //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // // // // // // // //       </div>

// // // // // // // // //       {/* Desktop */}
// // // // // // // // //       <ul className="navbar-items">
// // // // // // // // //         <li>
// // // // // // // // //           <Link to="/dashdoc"> {/* Added Link wrapper for the Home icon */}
// // // // // // // // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // // // // // // // //           </Link>
// // // // // // // // //         </li>
// // // // // // // // //         <li>
// // // // // // // // //           <img
// // // // // // // // //             src={notificationIcon}
// // // // // // // // //             alt="Notification Icon"
// // // // // // // // //             className="history-icon"
// // // // // // // // //             onClick={toggleNotification}
// // // // // // // // //           />
// // // // // // // // //           {showNotification && (
// // // // // // // // //             <div className="notification-box">
// // // // // // // // //               <ul>
// // // // // // // // //                 <li>Appointment Scheduled</li>
// // // // // // // // //               </ul>
// // // // // // // // //             </div>
// // // // // // // // //           )}
// // // // // // // // //         </li>
// // // // // // // // //         <li>
// // // // // // // // //           <img
// // // // // // // // //             src={settingicon}
// // // // // // // // //             alt="Setting Icon"
// // // // // // // // //             className="history-icon"
// // // // // // // // //             onClick={togglePopup}
// // // // // // // // //           />
// // // // // // // // //           {showPopup && (
// // // // // // // // //             <div className="popup show">
// // // // // // // // //               <ul>
// // // // // // // // //                 <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
// // // // // // // // //                 <li><Link to="/profile">My Profile</Link></li>
// // // // // // // // //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// // // // // // // // //                 <li><button onClick={logout}>Logout</button></li>
// // // // // // // // //               </ul>
// // // // // // // // //               {showPopup && (
// // // // // // // // //                 <div className="doctor-details">
// // // // // // // // //                   <p><strong>Email:</strong> {doctorInfo.email}</p>
// // // // // // // // //                   <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName}</p>
// // // // // // // // //                   <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress}</p>
// // // // // // // // //                   <p><strong>Degree:</strong> {doctorInfo.degree}</p>
// // // // // // // // //                   <p><strong>Specialist:</strong> {doctorInfo.specialist}</p>
// // // // // // // // //                   <p><strong>Experience:</strong> {doctorInfo.experience}</p>
// // // // // // // // //                   <p><strong>Address:</strong> {doctorInfo.address}</p>
// // // // // // // // //                   <p><strong>Age:</strong> {doctorInfo.age}</p>
// // // // // // // // //                   <p><strong>Gender:</strong> {doctorInfo.gender}</p>
// // // // // // // // //                   <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup}</p>
// // // // // // // // //                 </div>
// // // // // // // // //               )}
// // // // // // // // //             </div>
// // // // // // // // //           )}
// // // // // // // // //         </li>
// // // // // // // // //       </ul>
// // // // // // // // //     </div>
// // // // // // // // //   );
// // // // // // // // // }

// // // // // // // // // export default Navbar;
// // // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // // import { Link, useNavigate } from "react-router-dom";
// // // // // // // // import Homeicon from "../Assets/Home icon.png";
// // // // // // // // import settingicon from "../Assets/setting icon.png";
// // // // // // // // import notificationIcon from "../Assets/notification icon.png";
// // // // // // // // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // // // // // // // import { BASE_URL } from './config/apiConfig';

// // // // // // // // function Navbar() {
// // // // // // // //   const [showNotification, setShowNotification] = useState(false);
// // // // // // // //   const [showSettingsPopup, setShowSettingsPopup] = useState(false);
// // // // // // // //   const [showProfilePopup, setShowProfilePopup] = useState(false);
// // // // // // // //   const [doctorInfo, setDoctorInfo] = useState({}); // State to store doctor information
// // // // // // // //   const navigate = useNavigate();


  

// // // // // // // //   useEffect(() => {
// // // // // // // //     const fetchDoctorDetails = async () => {
// // // // // // // //       try {
// // // // // // // //         const token = localStorage.getItem('jwtToken'); // Adjust the key if needed

// // // // // // // //         if (!token) {
// // // // // // // //           console.error('No JWT token found in localStorage');
// // // // // // // //           return;
// // // // // // // //         }

// // // // // // // //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// // // // // // // //           headers: {
// // // // // // // //             'Auth': `Bearer ${token}`,
// // // // // // // //             'Content-Type': 'application/json'
// // // // // // // //           }
// // // // // // // //         });

// // // // // // // //         if (!response.ok) {
// // // // // // // //           throw new Error(`HTTP error! Status: ${response.status}`);
// // // // // // // //         }

// // // // // // // //         const data = await response.json();
// // // // // // // //         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
// // // // // // // //         setDoctorInfo({
// // // // // // // //           name: capitalizedDoctorName,
// // // // // // // //           email: data.email,
// // // // // // // //           hospitalName: data.hospitalName,
// // // // // // // //           hospitalAddress: data.hospitalAddress,
// // // // // // // //           degree: data.docHsProfileDegree,
// // // // // // // //           specialist: data.docHsProfileSpecilist,
// // // // // // // //           image: data.docHsProfileImage,
// // // // // // // //           experience: data.docHsProfileExperience,
// // // // // // // //           address: data.docHsProfileAddress,
// // // // // // // //           age: data.docHsProfileHsAge,
// // // // // // // //           gender: data.docHsProfileGender,
// // // // // // // //           bloodGroup: data.docHsProfileBloodGroup,
// // // // // // // //         });
// // // // // // // //       } catch (error) {
// // // // // // // //         console.error('Error fetching doctor details:', error);
// // // // // // // //       }
// // // // // // // //     };

// // // // // // // //     fetchDoctorDetails();
// // // // // // // //   }, []);

// // // // // // // //   const toggleNotification = () => {
// // // // // // // //     setShowNotification(!showNotification);
// // // // // // // //   };

// // // // // // // //   const toggleSettingsPopup = () => {
// // // // // // // //     setShowSettingsPopup(!showSettingsPopup);
// // // // // // // //     setShowProfilePopup(false); // Ensure the profile popup is closed
// // // // // // // //   };

// // // // // // // //   const toggleProfilePopup = () => {
// // // // // // // //     setShowProfilePopup(!showProfilePopup);
// // // // // // // //     setShowSettingsPopup(false); // Ensure the settings popup is closed
// // // // // // // //   };

// // // // // // // //   const logout = () => {
// // // // // // // //     localStorage.removeItem("jwtToken"); // Remove token from localStorage
// // // // // // // //     navigate("/"); // Redirect to login page
// // // // // // // //   };

// // // // // // // //   return (
// // // // // // // //     <div className="navbar-section">
// // // // // // // //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // // // // // // //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // // // // // // //       </div>

// // // // // // // //       {/* Desktop */}
// // // // // // // //       <ul className="navbar-items">
// // // // // // // //         <li>
// // // // // // // //           <Link to="/dashdoc"> {/* Added Link wrapper for the Home icon */}
// // // // // // // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // // // // // // //           </Link>
// // // // // // // //         </li>
// // // // // // // //         <li>
// // // // // // // //           <img
// // // // // // // //             src={notificationIcon}
// // // // // // // //             alt="Notification Icon"
// // // // // // // //             className="history-icon"
// // // // // // // //             onClick={toggleNotification}
// // // // // // // //           />
// // // // // // // //           {showNotification && (
// // // // // // // //             <div className="notification-box">
// // // // // // // //               <ul>
// // // // // // // //                 <li>Appointment Scheduled</li>
// // // // // // // //               </ul>
// // // // // // // //             </div>
// // // // // // // //           )}
// // // // // // // //         </li>
// // // // // // // //         <li>
// // // // // // // //           <img
// // // // // // // //             src={settingicon}
// // // // // // // //             alt="Setting Icon"
// // // // // // // //             className="history-icon"
// // // // // // // //             onClick={toggleSettingsPopup}
// // // // // // // //           />
// // // // // // // //           {showSettingsPopup && (
// // // // // // // //             <div className="popup show">
// // // // // // // //               <ul>
// // // // // // // //                 <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
// // // // // // // //                 <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
// // // // // // // //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// // // // // // // //                 <li><Link to="/" onClick={logout}>Logout</Link></li>
// // // // // // // //               </ul>
// // // // // // // //             </div>
// // // // // // // //           )}
// // // // // // // //         </li>
// // // // // // // //       </ul>

// // // // // // // //       {/* Profile Details Popup */}
// // // // // // // //       {showProfilePopup && (
// // // // // // // //         <div className="profile-popup show">
// // // // // // // //           <div className="doctor-details">
// // // // // // // //             <p><strong>Email:</strong> {doctorInfo.email}</p>
// // // // // // // //             <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName}</p>
// // // // // // // //             <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress}</p>
// // // // // // // //             <p><strong>Degree:</strong> {doctorInfo.degree}</p>
// // // // // // // //             <p><strong>Specialist:</strong> {doctorInfo.specialist}</p>
// // // // // // // //             <p><strong>Experience:</strong> {doctorInfo.experience}</p>
// // // // // // // //             <p><strong>Address:</strong> {doctorInfo.address}</p>
// // // // // // // //             <p><strong>Age:</strong> {doctorInfo.age}</p>
// // // // // // // //             <p><strong>Gender:</strong> {doctorInfo.gender}</p>
// // // // // // // //             <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup}</p>
// // // // // // // //           </div>
// // // // // // // //         </div>
// // // // // // // //       )}
// // // // // // // //     </div>
// // // // // // // //   );
// // // // // // // // }

// // // // // // // // export default Navbar;
// // // // // // // import React, { useState, useEffect } from "react";
// // // // // // // import { Link, useNavigate } from "react-router-dom";
// // // // // // // import Homeicon from "../Assets/Home icon.png";
// // // // // // // import settingicon from "../Assets/setting icon.png";
// // // // // // // import notificationIcon from "../Assets/notification icon.png";
// // // // // // // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // // // // // // import { BASE_URL } from './config/apiConfig';

// // // // // // // function Navbar() {
// // // // // // //   const [showNotification, setShowNotification] = useState(false);
// // // // // // //   const [showSettingsPopup, setShowSettingsPopup] = useState(false);
// // // // // // //   const [showProfilePopup, setShowProfilePopup] = useState(false);
// // // // // // //   const [editMode, setEditMode] = useState(false); // State to handle edit mode
// // // // // // //   const [doctorInfo, setDoctorInfo] = useState({});
// // // // // // //   const navigate = useNavigate();

// // // // // // //   useEffect(() => {
// // // // // // //     const fetchDoctorDetails = async () => {
// // // // // // //       try {
// // // // // // //         const token = localStorage.getItem('jwtToken');
// // // // // // //         if (!token) {
// // // // // // //           console.error('No JWT token found in localStorage');
// // // // // // //           return;
// // // // // // //         }
// // // // // // //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// // // // // // //           headers: {
// // // // // // //             'Auth': `Bearer ${token}`,
// // // // // // //             'Content-Type': 'application/json'
// // // // // // //           }
// // // // // // //         });

// // // // // // //         if (!response.ok) {
// // // // // // //           throw new Error(`HTTP error! Status: ${response.status}`);
// // // // // // //         }

// // // // // // //         const data = await response.json();
// // // // // // //         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
// // // // // // //         setDoctorInfo({
// // // // // // //           name: capitalizedDoctorName,
// // // // // // //           email: data.email,
// // // // // // //           hospitalName: data.hospitalName,
// // // // // // //           hospitalAddress: data.hospitalAddress,
// // // // // // //           degree: data.docHsProfileDegree,
// // // // // // //           specialist: data.docHsProfileSpecilist,
// // // // // // //           image: data.docHsProfileImage,
// // // // // // //           experience: data.docHsProfileExperience,
// // // // // // //           address: data.docHsProfileAddress,
// // // // // // //           age: data.docHsProfileHsAge,
// // // // // // //           gender: data.docHsProfileGender,
// // // // // // //           bloodGroup: data.docHsProfileBloodGroup,
// // // // // // //         });
// // // // // // //       } catch (error) {
// // // // // // //         console.error('Error fetching doctor details:', error);
// // // // // // //       }
// // // // // // //     };

// // // // // // //     fetchDoctorDetails();
// // // // // // //   }, []);

// // // // // // //   const toggleNotification = () => {
// // // // // // //     setShowNotification(!showNotification);
// // // // // // //   };

// // // // // // //   const toggleSettingsPopup = () => {
// // // // // // //     setShowSettingsPopup(!showSettingsPopup);
// // // // // // //     setShowProfilePopup(false);
// // // // // // //   };

// // // // // // //   const toggleProfilePopup = () => {
// // // // // // //     setShowProfilePopup(!showProfilePopup);
// // // // // // //     setShowSettingsPopup(false);
// // // // // // //   };

// // // // // // //   const handleInputChange = (e) => {
// // // // // // //     const { name, value } = e.target;
// // // // // // //     setDoctorInfo(prevInfo => ({
// // // // // // //       ...prevInfo,
// // // // // // //       [name]: value
// // // // // // //     }));
// // // // // // //   };

// // // // // // //   const handleUpdateProfile = async () => {
// // // // // // //     try {
// // // // // // //       const token = localStorage.getItem('jwtToken');
// // // // // // //       if (!token) {
// // // // // // //         console.error('No JWT token found in localStorage');
// // // // // // //         return;
// // // // // // //       }
// // // // // // //       const response = await fetch(`${BASE_URL}/registerDocHs/update-doctor`, {
// // // // // // //         method: 'POST',
// // // // // // //         headers: {
// // // // // // //           'Auth': `Bearer ${token}`,
// // // // // // //           'Content-Type': 'application/json'
// // // // // // //         },
// // // // // // //         body: JSON.stringify(doctorInfo)
// // // // // // //       });

// // // // // // //       if (!response.ok) {
// // // // // // //         throw new Error(`HTTP error! Status: ${response.status}`);
// // // // // // //       }

// // // // // // //       const data = await response.json();
// // // // // // //       console.log('Profile updated successfully:', data);
// // // // // // //       setEditMode(false); // Exit edit mode after updating
// // // // // // //     } catch (error) {
// // // // // // //       console.error('Error updating doctor details:', error);
// // // // // // //     }
// // // // // // //   };

// // // // // // //   const logout = () => {
// // // // // // //     localStorage.removeItem("jwtToken");
// // // // // // //     navigate("/");
// // // // // // //   };

// // // // // // //   return (
// // // // // // //     <div className="navbar-section">
// // // // // // //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // // // // // //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // // // // // //       </div>

// // // // // // //       {/* Desktop */}
// // // // // // //       <ul className="navbar-items">
// // // // // // //         <li>
// // // // // // //           <Link to="/dashdoc">
// // // // // // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // // // // // //           </Link>
// // // // // // //         </li>
// // // // // // //         <li>
// // // // // // //           <img
// // // // // // //             src={notificationIcon}
// // // // // // //             alt="Notification Icon"
// // // // // // //             className="history-icon"
// // // // // // //             onClick={toggleNotification}
// // // // // // //           />
// // // // // // //           {showNotification && (
// // // // // // //             <div className="notification-box">
// // // // // // //               <ul>
// // // // // // //                 <li>Appointment Scheduled</li>
// // // // // // //               </ul>
// // // // // // //             </div>
// // // // // // //           )}
// // // // // // //         </li>
// // // // // // //         <li>
// // // // // // //           <img
// // // // // // //             src={settingicon}
// // // // // // //             alt="Setting Icon"
// // // // // // //             className="history-icon"
// // // // // // //             onClick={toggleSettingsPopup}
// // // // // // //           />
// // // // // // //           {showSettingsPopup && (
// // // // // // //             <div className="popup show">
// // // // // // //               <ul>
// // // // // // //                 <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
// // // // // // //                 <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
// // // // // // //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// // // // // // //                 <li><Link to="/" onClick={logout}>Logout</Link></li>
// // // // // // //               </ul>
// // // // // // //             </div>
// // // // // // //           )}
// // // // // // //         </li>
// // // // // // //       </ul>

// // // // // // //       {/* Profile Details Popup */}
// // // // // // //       {showProfilePopup && (
// // // // // // //         <div className="profile-popup show">
// // // // // // //           <div className="doctor-details">
// // // // // // //             {editMode ? (
// // // // // // //               <>
// // // // // // //                 <p><strong>Email:</strong> <input type="email" name="email" value={doctorInfo.email} onChange={handleInputChange} /></p>
// // // // // // //                 <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={doctorInfo.hospitalName} onChange={handleInputChange} /></p>
// // // // // // //                 <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={doctorInfo.hospitalAddress} onChange={handleInputChange} /></p>
// // // // // // //                 <p><strong>Degree:</strong> <input type="text" name="degree" value={doctorInfo.degree} onChange={handleInputChange} /></p>
// // // // // // //                 <p><strong>Specialist:</strong> <input type="text" name="specialist" value={doctorInfo.specialist} onChange={handleInputChange} /></p>
// // // // // // //                 <p><strong>Experience:</strong> <input type="text" name="experience" value={doctorInfo.experience} onChange={handleInputChange} /></p>
// // // // // // //                 <p><strong>Address:</strong> <input type="text" name="address" value={doctorInfo.address} onChange={handleInputChange} /></p>
// // // // // // //                 <p><strong>Age:</strong> <input type="number" name="age" value={doctorInfo.age} onChange={handleInputChange} /></p>
// // // // // // //                 <p><strong>Gender:</strong> <input type="text" name="gender" value={doctorInfo.gender} onChange={handleInputChange} /></p>
// // // // // // //                 <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={doctorInfo.bloodGroup} onChange={handleInputChange} /></p>
// // // // // // //                 <button onClick={handleUpdateProfile}>Update Profile</button>
// // // // // // //                 <button onClick={() => setEditMode(false)}>Cancel</button>
// // // // // // //               </>
// // // // // // //             ) : (
// // // // // // //               <>
// // // // // // //                 <p><strong>Email:</strong> {doctorInfo.email}</p>
// // // // // // //                 <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName}</p>
// // // // // // //                 <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress}</p>
// // // // // // //                 <p><strong>Degree:</strong> {doctorInfo.degree}</p>
// // // // // // //                 <p><strong>Specialist:</strong> {doctorInfo.specialist}</p>
// // // // // // //                 <p><strong>Experience:</strong> {doctorInfo.experience}</p>
// // // // // // //                 <p><strong>Address:</strong> {doctorInfo.address}</p>
// // // // // // //                 <p><strong>Age:</strong> {doctorInfo.age}</p>
// // // // // // //                 <p><strong>Gender:</strong> {doctorInfo.gender}</p>
// // // // // // //                 <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup}</p>
// // // // // // //                 <button onClick={() => setEditMode(true)}>Edit Profile</button>
// // // // // // //               </>
// // // // // // //             )}
// // // // // // //           </div>
// // // // // // //         </div>
// // // // // // //       )}
// // // // // // //     </div>
// // // // // // //   );
// // // // // // // }

// // // // // // // export default Navbar;
// // // // // // import React, { useState, useEffect } from "react";
// // // // // // import { Link, useNavigate } from "react-router-dom";
// // // // // // import Homeicon from "../Assets/Home icon.png";
// // // // // // import settingicon from "../Assets/setting icon.png";
// // // // // // import notificationIcon from "../Assets/notification icon.png";
// // // // // // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // // // // // import { BASE_URL } from './config/apiConfig';

// // // // // // function Navbar() {
// // // // // //   const [showNotification, setShowNotification] = useState(false);
// // // // // //   const [showSettingsPopup, setShowSettingsPopup] = useState(false);
// // // // // //   const [showProfilePopup, setShowProfilePopup] = useState(false);
// // // // // //   const [editMode, setEditMode] = useState(false); // State to handle edit mode
// // // // // //   const [doctorInfo, setDoctorInfo] = useState({});
// // // // // //   const navigate = useNavigate();

// // // // // //   useEffect(() => {
// // // // // //     const fetchDoctorDetails = async () => {
// // // // // //       try {
// // // // // //         const token = localStorage.getItem('jwtToken');
// // // // // //         if (!token) {
// // // // // //           console.error('No JWT token found in localStorage');
// // // // // //           return;
// // // // // //         }
// // // // // //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// // // // // //           headers: {
// // // // // //             'Auth': `Bearer ${token}`,
// // // // // //             'Content-Type': 'application/json'
// // // // // //           }
// // // // // //         });

// // // // // //         if (!response.ok) {
// // // // // //           throw new Error(`HTTP error! Status: ${response.status}`);
// // // // // //         }

// // // // // //         const data = await response.json();
// // // // // //         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
// // // // // //         setDoctorInfo({
// // // // // //           name: capitalizedDoctorName,
// // // // // //           email: data.email,
// // // // // //           hospitalName: data.hospitalName,
// // // // // //           hospitalAddress: data.hospitalAddress,
// // // // // //           degree: data.docHsProfileDegree,
// // // // // //           specialist: data.docHsProfileSpecilist,
// // // // // //           image: data.docHsProfileImage,
// // // // // //           experience: data.docHsProfileExperience,
// // // // // //           address: data.docHsProfileAddress,
// // // // // //           age: data.docHsProfileHsAge,
// // // // // //           gender: data.docHsProfileGender,
// // // // // //           bloodGroup: data.docHsProfileBloodGroup,
// // // // // //         });
// // // // // //       } catch (error) {
// // // // // //         console.error('Error fetching doctor details:', error);
// // // // // //       }
// // // // // //     };

// // // // // //     fetchDoctorDetails();
// // // // // //   }, []);

// // // // // //   const toggleNotification = () => {
// // // // // //     setShowNotification(!showNotification);
// // // // // //   };

// // // // // //   const toggleSettingsPopup = () => {
// // // // // //     setShowSettingsPopup(!showSettingsPopup);
// // // // // //     setShowProfilePopup(false);
// // // // // //   };

// // // // // //   const toggleProfilePopup = () => {
// // // // // //     setShowProfilePopup(!showProfilePopup);
// // // // // //     setShowSettingsPopup(false);
// // // // // //   };

// // // // // //   const handleInputChange = (e) => {
// // // // // //     const { name, value } = e.target;
// // // // // //     setDoctorInfo(prevInfo => ({
// // // // // //       ...prevInfo,
// // // // // //       [name]: value
// // // // // //     }));
// // // // // //   };

// // // // // //   const handleUpdateProfile = async () => {
// // // // // //     try {
// // // // // //       const token = localStorage.getItem('jwtToken');
// // // // // //       if (!token) {
// // // // // //         console.error('No JWT token found in localStorage');
// // // // // //         return;
// // // // // //       }
// // // // // //       const response = await fetch(`${BASE_URL}/registerDocHs/update-doctor`, {
// // // // // //         method: 'POST',
// // // // // //         headers: {
// // // // // //           'Auth': `Bearer ${token}`,
// // // // // //           'Content-Type': 'application/json'
// // // // // //         },
// // // // // //         body: JSON.stringify(doctorInfo)
// // // // // //       });

// // // // // //       if (!response.ok) {
// // // // // //         throw new Error(`HTTP error! Status: ${response.status}`);
// // // // // //       }

// // // // // //       const data = await response.json();
// // // // // //       console.log('Profile updated successfully:', data);
// // // // // //       setEditMode(false); // Exit edit mode after updating
// // // // // //     } catch (error) {
// // // // // //       console.error('Error updating doctor details:', error);
// // // // // //     }
// // // // // //   };

// // // // // //   const logout = () => {
// // // // // //     localStorage.removeItem("jwtToken");
// // // // // //     navigate("/");
// // // // // //   };

// // // // // //   return (
// // // // // //     <div className="navbar-section">
// // // // // //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // // // // //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // // // // //       </div>

// // // // // //       {/* Desktop */}
// // // // // //       <ul className="navbar-items">
// // // // // //         <li>
// // // // // //           <Link to="/dashdoc">
// // // // // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // // // // //           </Link>
// // // // // //         </li>
// // // // // //         <li>
// // // // // //           <img
// // // // // //             src={notificationIcon}
// // // // // //             alt="Notification Icon"
// // // // // //             className="history-icon"
// // // // // //             onClick={toggleNotification}
// // // // // //           />
// // // // // //           {showNotification && (
// // // // // //             <div className="notification-box">
// // // // // //               <ul>
// // // // // //                 <li>Appointment Scheduled</li>
// // // // // //               </ul>
// // // // // //             </div>
// // // // // //           )}
// // // // // //         </li>
// // // // // //         <li>
// // // // // //           <img
// // // // // //             src={settingicon}
// // // // // //             alt="Setting Icon"
// // // // // //             className="history-icon"
// // // // // //             onClick={toggleSettingsPopup}
// // // // // //           />
// // // // // //           {showSettingsPopup && (
// // // // // //             <div className="popup show">
// // // // // //               <ul>
// // // // // //                 <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
// // // // // //                 <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
// // // // // //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// // // // // //                 <li><Link to="/" onClick={logout}>Logout</Link></li>
// // // // // //               </ul>
// // // // // //             </div>
// // // // // //           )}
// // // // // //         </li>
// // // // // //       </ul>

// // // // // //       {/* Profile Details Popup */}
// // // // // //       {showProfilePopup && (
// // // // // //         <div className="profile-popup show">
// // // // // //           <div className="doctor-details">
// // // // // //            <img
// // // // // //   src={doctorInfo.image ? `${BASE_URL}${doctorInfo.image}` : 'default-profile.png'}
// // // // // //   alt="Doctor Profile"
// // // // // //   style={{
// // // // // //     width: '150px',   // Set the desired width
// // // // // //     height: '150px',  // Set the desired height
// // // // // //     borderRadius: '50%', // Optional: makes the image circular
// // // // // //     objectFit: 'cover' // Ensures the image covers the defined area
// // // // // //   }}
// // // // // // />

// // // // // //             {editMode ? (
// // // // // //               <>
// // // // // //                 <p><strong>Email:</strong> <input type="email" name="email" value={doctorInfo.email} onChange={handleInputChange} /></p>
// // // // // //                 <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={doctorInfo.hospitalName} onChange={handleInputChange} /></p>
// // // // // //                 <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={doctorInfo.hospitalAddress} onChange={handleInputChange} /></p>
// // // // // //                 <p><strong>Degree:</strong> <input type="text" name="degree" value={doctorInfo.degree} onChange={handleInputChange} /></p>
// // // // // //                 <p><strong>Specialist:</strong> <input type="text" name="specialist" value={doctorInfo.specialist} onChange={handleInputChange} /></p>
// // // // // //                 <p><strong>Experience:</strong> <input type="text" name="experience" value={doctorInfo.experience} onChange={handleInputChange} /></p>
// // // // // //                 <p><strong>Address:</strong> <input type="text" name="address" value={doctorInfo.address} onChange={handleInputChange} /></p>
// // // // // //                 <p><strong>Age:</strong> <input type="number" name="age" value={doctorInfo.age} onChange={handleInputChange} /></p>
// // // // // //                 <p><strong>Gender:</strong> <input type="text" name="gender" value={doctorInfo.gender} onChange={handleInputChange} /></p>
// // // // // //                 <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={doctorInfo.bloodGroup} onChange={handleInputChange} /></p>
// // // // // //                 <button onClick={handleUpdateProfile}>Update Profile</button>
// // // // // //                 <button onClick={() => setEditMode(false)}>Cancel</button>
// // // // // //               </>
// // // // // //             ) : (
// // // // // //               <>
// // // // // //                 <p><strong>Email:</strong> {doctorInfo.email}</p>
// // // // // //                 <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName}</p>
// // // // // //                 <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress}</p>
// // // // // //                 <p><strong>Degree:</strong> {doctorInfo.degree}</p>
// // // // // //                 <p><strong>Specialist:</strong> {doctorInfo.specialist}</p>
// // // // // //                 <p><strong>Experience:</strong> {doctorInfo.experience}</p>
// // // // // //                 <p><strong>Address:</strong> {doctorInfo.address}</p>
// // // // // //                 <p><strong>Age:</strong> {doctorInfo.age}</p>
// // // // // //                 <p><strong>Gender:</strong> {doctorInfo.gender}</p>
// // // // // //                 <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup}</p>
// // // // // //                 <button onClick={() => setEditMode(true)}>Edit Profile</button>
// // // // // //               </>
// // // // // //             )}
// // // // // //           </div>
// // // // // //         </div>
// // // // // //       )}
// // // // // //     </div>
// // // // // //   );
// // // // // // }

// // // // // // export default Navbar;
// // // // // import React, { useState, useEffect } from "react";
// // // // // import { Link, useNavigate } from "react-router-dom";
// // // // // import Homeicon from "../Assets/Home icon.png";
// // // // // import settingicon from "../Assets/setting icon.png";
// // // // // import notificationIcon from "../Assets/notification icon.png";
// // // // // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // // // // import { BASE_URL } from './config/apiConfig';

// // // // // function Navbar() {
// // // // //   const [showNotification, setShowNotification] = useState(false);
// // // // //   const [showSettingsPopup, setShowSettingsPopup] = useState(false);
// // // // //   const [showProfilePopup, setShowProfilePopup] = useState(false);
// // // // //   const [editMode, setEditMode] = useState(false); // State to handle edit mode
// // // // //   const [doctorInfo, setDoctorInfo] = useState({});
// // // // //   const navigate = useNavigate();

// // // // //   useEffect(() => {
// // // // //     const fetchDoctorDetails = async () => {
// // // // //       try {
// // // // //         const token = localStorage.getItem('jwtToken');
// // // // //         if (!token) {
// // // // //           console.error('No JWT token found in localStorage');
// // // // //           return;
// // // // //         }
// // // // //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// // // // //           headers: {
// // // // //             'Auth': `Bearer ${token}`,
// // // // //             'Content-Type': 'application/json'
// // // // //           }
// // // // //         });

// // // // //         if (!response.ok) {
// // // // //           throw new Error(`HTTP error! Status: ${response.status}`);
// // // // //         }

// // // // //         const data = await response.json();
// // // // //         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
// // // // //         setDoctorInfo({
// // // // //           name: capitalizedDoctorName,
// // // // //           email: data.email,
// // // // //           hospitalName: data.hospitalName,
// // // // //           hospitalAddress: data.hospitalAddress,
// // // // //           degree: data.docHsProfileDegree,
// // // // //           specialist: data.docHsProfileSpecilist,
// // // // //           image: data.docHsProfileImage,
// // // // //           experience: data.docHsProfileExperience,
// // // // //           address: data.docHsProfileAddress,
// // // // //           age: data.docHsProfileHsAge,
// // // // //           gender: data.docHsProfileGender,
// // // // //           bloodGroup: data.docHsProfileBloodGroup,
// // // // //         });
// // // // //       } catch (error) {
// // // // //         console.error('Error fetching doctor details:', error);
// // // // //       }
// // // // //     };

// // // // //     fetchDoctorDetails();
// // // // //   }, []);

// // // // //   const toggleNotification = () => {
// // // // //     setShowNotification(!showNotification);
// // // // //   };

// // // // //   const toggleSettingsPopup = () => {
// // // // //     setShowSettingsPopup(!showSettingsPopup);
// // // // //     setShowProfilePopup(false);
// // // // //   };

// // // // //   const toggleProfilePopup = () => {
// // // // //     setShowProfilePopup(!showProfilePopup);
// // // // //     setShowSettingsPopup(false);
// // // // //   };

// // // // //   const handleInputChange = (e) => {
// // // // //     const { name, value } = e.target;
// // // // //     setDoctorInfo(prevInfo => ({
// // // // //       ...prevInfo,
// // // // //       [name]: value
// // // // //     }));
// // // // //   };

// // // // //   const handleUpdateProfile = async () => {
// // // // //     try {
// // // // //       const token = localStorage.getItem('jwtToken');
// // // // //       if (!token) {
// // // // //         console.error('No JWT token found in localStorage');
// // // // //         return;
// // // // //       }
// // // // //       const response = await fetch(`${BASE_URL}/registerDocHs/update-doctor`, {
// // // // //         method: 'POST',
// // // // //         headers: {
// // // // //           'Auth': `Bearer ${token}`,
// // // // //           'Content-Type': 'application/json'
// // // // //         },
// // // // //         body: JSON.stringify(doctorInfo)
// // // // //       });

// // // // //       if (!response.ok) {
// // // // //         throw new Error(`HTTP error! Status: ${response.status}`);
// // // // //       }

// // // // //       const data = await response.json();
// // // // //       console.log('Profile updated successfully:', data);
// // // // //       setEditMode(false); // Exit edit mode after updating
// // // // //     } catch (error) {
// // // // //       console.error('Error updating doctor details:', error);
// // // // //     }
// // // // //   };

// // // // //   const logout = () => {
// // // // //     localStorage.removeItem("jwtToken");
// // // // //     navigate("/");
// // // // //   };

// // // // //   return (
// // // // //     <div className="navbar-section">
// // // // //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // // // //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // // // //       </div>

// // // // //       {/* Desktop */}
// // // // //       <ul className="navbar-items">
// // // // //         <li>
// // // // //           <Link to="/dashdoc">
// // // // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // // // //           </Link>
// // // // //         </li>
// // // // //         <li>
// // // // //           <img
// // // // //             src={notificationIcon}
// // // // //             alt="Notification Icon"
// // // // //             className="history-icon"
// // // // //             onClick={toggleNotification}
// // // // //           />
// // // // //           {showNotification && (
// // // // //             <div className="notification-box">
// // // // //               <ul>
// // // // //                 <li>Appointment Scheduled</li>
// // // // //               </ul>
// // // // //             </div>
// // // // //           )}
// // // // //         </li>
// // // // //         <li>
// // // // //           <img
// // // // //             src={settingicon}
// // // // //             alt="Setting Icon"
// // // // //             className="history-icon"
// // // // //             onClick={toggleSettingsPopup}
// // // // //           />
// // // // //           {showSettingsPopup && (
// // // // //             <div className="popup show">
// // // // //               <ul>
// // // // //                 <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
// // // // //                 <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
// // // // //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// // // // //                 <li><Link to="/" onClick={logout}>Logout</Link></li>
// // // // //               </ul>
// // // // //             </div>
// // // // //           )}
// // // // //         </li>
// // // // //       </ul>

// // // // //       {/* Profile Details Popup */}
// // // // //       {showProfilePopup && (
// // // // //         <div className="profile-popup show">
// // // // //           <div className="doctor-details">
// // // // //             <img
// // // // //               src={doctorInfo.image ? `${BASE_URL}${doctorInfo.image}` : 'default-profile.png'}
// // // // //               alt="Doctor Profile"
// // // // //               style={{
// // // // //                 width: '150px',   // Set the desired width
// // // // //                 height: '150px',  // Set the desired height
// // // // //                 borderRadius: '50%', // Optional: makes the image circular
// // // // //                 objectFit: 'cover' // Ensures the image covers the defined area
// // // // //               }}
// // // // //             />
// // // // //             <h2>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</h2> {/* Display the doctor's name */}
// // // // //             {editMode ? (
// // // // //               <>
// // // // //                 <p><strong>Email:</strong> <input type="email" name="email" value={doctorInfo.email} onChange={handleInputChange} /></p>
// // // // //                 <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={doctorInfo.hospitalName} onChange={handleInputChange} /></p>
// // // // //                 <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={doctorInfo.hospitalAddress} onChange={handleInputChange} /></p>
// // // // //                 <p><strong>Degree:</strong> <input type="text" name="degree" value={doctorInfo.degree} onChange={handleInputChange} /></p>
// // // // //                 <p><strong>Specialist:</strong> <input type="text" name="specialist" value={doctorInfo.specialist} onChange={handleInputChange} /></p>
// // // // //                 <p><strong>Experience:</strong> <input type="text" name="experience" value={doctorInfo.experience} onChange={handleInputChange} /></p>
// // // // //                 <p><strong>Address:</strong> <input type="text" name="address" value={doctorInfo.address} onChange={handleInputChange} /></p>
// // // // //                 <p><strong>Age:</strong> <input type="number" name="age" value={doctorInfo.age} onChange={handleInputChange} /></p>
// // // // //                 <p><strong>Gender:</strong> <input type="text" name="gender" value={doctorInfo.gender} onChange={handleInputChange} /></p>
// // // // //                 <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={doctorInfo.bloodGroup} onChange={handleInputChange} /></p>
// // // // //                 <button onClick={handleUpdateProfile}>Update Profile</button>
// // // // //                 <button onClick={() => setEditMode(false)}>Cancel</button>
// // // // //               </>
// // // // //             ) : (
// // // // //               <>
// // // // //                 <p><strong>Email:</strong> {doctorInfo.email}</p>
// // // // //                 <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName}</p>
// // // // //                 <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress}</p>
// // // // //                 <p><strong>Degree:</strong> {doctorInfo.degree}</p>
// // // // //                 <p><strong>Specialist:</strong> {doctorInfo.specialist}</p>
// // // // //                 <p><strong>Experience:</strong> {doctorInfo.experience}</p>
// // // // //                 <p><strong>Address:</strong> {doctorInfo.address}</p>
// // // // //                 <p><strong>Age:</strong> {doctorInfo.age}</p>
// // // // //                 <p><strong>Gender:</strong> {doctorInfo.gender}</p>
// // // // //                 <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup}</p>
// // // // //                 <button onClick={() => setEditMode(true)}>Edit Profile</button>
// // // // //               </>
// // // // //             )}
// // // // //           </div>
// // // // //         </div>
// // // // //       )}
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // // export default Navbar;
// // // // import React, { useState, useEffect } from "react";
// // // // import { Link, useNavigate } from "react-router-dom";
// // // // import Homeicon from "../Assets/Home icon.png";
// // // // import settingicon from "../Assets/setting icon.png";
// // // // import notificationIcon from "../Assets/notification icon.png";
// // // // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // // // import { BASE_URL } from './config/apiConfig';

// // // // function Navbar() {
// // // //   const [showNotification, setShowNotification] = useState(false);
// // // //   const [showSettingsPopup, setShowSettingsPopup] = useState(false);
// // // //   const [showProfilePopup, setShowProfilePopup] = useState(false);
// // // //   const [editMode, setEditMode] = useState(false); // State to handle edit mode
// // // //   const [doctorInfo, setDoctorInfo] = useState({});
// // // //   const navigate = useNavigate();

// // // //   useEffect(() => {
// // // //     const fetchDoctorDetails = async () => {
// // // //       try {
// // // //         const token = localStorage.getItem('jwtToken');
// // // //         if (!token) {
// // // //           console.error('No JWT token found in localStorage');
// // // //           return;
// // // //         }
// // // //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// // // //           headers: {
// // // //             'Auth': `Bearer ${token}`,
// // // //             'Content-Type': 'application/json'
// // // //           }
// // // //         });

// // // //         if (!response.ok) {
// // // //           throw new Error(`HTTP error! Status: ${response.status}`);
// // // //         }

// // // //         const data = await response.json();
// // // //         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
// // // //         setDoctorInfo({
// // // //           name: capitalizedDoctorName,
// // // //           email: data.email,
// // // //           hospitalName: data.hospitalName,
// // // //           hospitalAddress: data.hospitalAddress,
// // // //           degree: data.docHsProfileDegree,
// // // //           specialist: data.docHsProfileSpecilist,
// // // //           image: data.docHsProfileImage,
// // // //           experience: data.docHsProfileExperience,
// // // //           address: data.docHsProfileAddress,
// // // //           age: data.docHsProfileHsAge,
// // // //           gender: data.docHsProfileGender,
// // // //           bloodGroup: data.docHsProfileBloodGroup,
// // // //         });
// // // //       } catch (error) {
// // // //         console.error('Error fetching doctor details:', error);
// // // //       }
// // // //     };

// // // //     fetchDoctorDetails();
// // // //   }, []);

// // // //   const toggleNotification = () => {
// // // //     setShowNotification(!showNotification);
// // // //   };

// // // //   const toggleSettingsPopup = () => {
// // // //     setShowSettingsPopup(!showSettingsPopup);
// // // //     setShowProfilePopup(false);
// // // //   };

// // // //   const toggleProfilePopup = () => {
// // // //     setShowProfilePopup(!showProfilePopup);
// // // //     setShowSettingsPopup(false);
// // // //   };

// // // //   const handleInputChange = (e) => {
// // // //     const { name, value } = e.target;
// // // //     setDoctorInfo(prevInfo => ({
// // // //       ...prevInfo,
// // // //       [name]: value
// // // //     }));
// // // //   };

// // // //   const handleUpdateProfile = async () => {
// // // //     try {
// // // //       const token = localStorage.getItem('jwtToken');
// // // //       if (!token) {
// // // //         console.error('No JWT token found in localStorage');
// // // //         return;
// // // //       }
// // // //       const response = await fetch(`${BASE_URL}/registerDocHs/update-doctor`, {
// // // //         method: 'POST',
// // // //         headers: {
// // // //           'Auth': `Bearer ${token}`,
// // // //           'Content-Type': 'application/json'
// // // //         },
// // // //         body: JSON.stringify(doctorInfo)
// // // //       });

// // // //       if (!response.ok) {
// // // //         throw new Error(`HTTP error! Status: ${response.status}`);
// // // //       }

// // // //       const data = await response.json();
// // // //       console.log('Profile updated successfully:', data);
// // // //       setEditMode(false); // Exit edit mode after updating
// // // //     } catch (error) {
// // // //       console.error('Error updating doctor details:', error);
// // // //     }
// // // //   };

// // // //   const logout = () => {
// // // //     localStorage.removeItem("jwtToken");
// // // //     navigate("/");
// // // //   };

// // // //   return (
// // // //     <div className="navbar-section">
// // // //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // // //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // // //       </div>

// // // //       {/* Desktop */}
// // // //       <ul className="navbar-items">
// // // //         <li>
// // // //           <Link to="/dashdoc">
// // // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // // //           </Link>
// // // //         </li>
// // // //         <li>
// // // //           <img
// // // //             src={notificationIcon}
// // // //             alt="Notification Icon"
// // // //             className="history-icon"
// // // //             onClick={toggleNotification}
// // // //           />
// // // //           {showNotification && (
// // // //             <div className="notification-box">
// // // //               <ul>
// // // //                 <li>Appointment Scheduled</li>
// // // //               </ul>
// // // //             </div>
// // // //           )}
// // // //         </li>
// // // //         <li>
// // // //           <img
// // // //             src={settingicon}
// // // //             alt="Setting Icon"
// // // //             className="history-icon"
// // // //             onClick={toggleSettingsPopup}
// // // //           />
// // // //           {showSettingsPopup && (
// // // //             <div className="popup show">
// // // //               <ul>
// // // //                 <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
// // // //                 <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
// // // //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// // // //                 <li><Link to="/" onClick={logout}>Logout</Link></li>
// // // //               </ul>
// // // //             </div>
// // // //           )}
// // // //         </li>
// // // //       </ul>

// // // //       {/* Profile Details Popup */}
// // // //       {showProfilePopup && (
// // // //         <div className="profile-popup show">
// // // //           <div className="doctor-details">
// // // //             <img
// // // //               src={doctorInfo.image ? `${BASE_URL}${doctorInfo.image}` : 'default-profile.png'}
// // // //               alt="Doctor Profile"
// // // //               className="profile-image"
// // // //             />
// // // //             <h2>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</h2> {/* Display the doctor's name */}
// // // //             {editMode ? (
// // // //               <>
// // // //                 <p><strong>Email:</strong> <input type="email" name="email" value={doctorInfo.email || ''} onChange={handleInputChange} /></p>
// // // //                 <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={doctorInfo.hospitalName || ''} onChange={handleInputChange} /></p>
// // // //                 <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={doctorInfo.hospitalAddress || ''} onChange={handleInputChange} /></p>
// // // //                 <p><strong>Degree:</strong> <input type="text" name="degree" value={doctorInfo.degree || ''} onChange={handleInputChange} /></p>
// // // //                 <p><strong>Specialist:</strong> <input type="text" name="specialist" value={doctorInfo.specialist || ''} onChange={handleInputChange} /></p>
// // // //                 <p><strong>Experience:</strong> <input type="text" name="experience" value={doctorInfo.experience || ''} onChange={handleInputChange} /></p>
// // // //                 <p><strong>Address:</strong> <input type="text" name="address" value={doctorInfo.address || ''} onChange={handleInputChange} /></p>
// // // //                 <p><strong>Age:</strong> <input type="number" name="age" value={doctorInfo.age || ''} onChange={handleInputChange} /></p>
// // // //                 <p><strong>Gender:</strong> <input type="text" name="gender" value={doctorInfo.gender || ''} onChange={handleInputChange} /></p>
// // // //                 <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={doctorInfo.bloodGroup || ''} onChange={handleInputChange} /></p>
// // // //                 <button onClick={handleUpdateProfile}>Update Profile</button>
// // // //                 <button onClick={() => setEditMode(false)}>Cancel</button>
// // // //               </>
// // // //             ) : (
// // // //               <>
// // // //                 <p><strong>Email:</strong> {doctorInfo.email || 'N/A'}</p>
// // // //                 <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName || 'N/A'}</p>
// // // //                 <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress || 'N/A'}</p>
// // // //                 <p><strong>Degree:</strong> {doctorInfo.degree || 'N/A'}</p>
// // // //                 <p><strong>Specialist:</strong> {doctorInfo.specialist || 'N/A'}</p>
// // // //                 <p><strong>Experience:</strong> {doctorInfo.experience || 'N/A'}</p>
// // // //                 <p><strong>Address:</strong> {doctorInfo.address || 'N/A'}</p>
// // // //                 <p><strong>Age:</strong> {doctorInfo.age || 'N/A'}</p>
// // // //                 <p><strong>Gender:</strong> {doctorInfo.gender || 'N/A'}</p>
// // // //                 <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup || 'N/A'}</p>
// // // //                 <button onClick={() => setEditMode(true)}>Edit Profile</button>
// // // //               </>
// // // //             )}
// // // //           </div>
// // // //         </div>
// // // //       )}
// // // //     </div>
// // // //   );
// // // // }

// // // // export default Navbar;
// // // import React, { useState, useEffect } from "react";
// // // import { Link, useNavigate } from "react-router-dom";
// // // import Homeicon from "../Assets/Home icon.png";
// // // import settingicon from "../Assets/setting icon.png";
// // // import notificationIcon from "../Assets/notification icon.png";
// // // import logo from "../Assets/O2I_LOGO.png";
// // // import { BASE_URL } from './config/apiConfig';

// // // function Navbar() {
// // //   const [showNotification, setShowNotification] = useState(false);
// // //   const [showSettingsPopup, setShowSettingsPopup] = useState(false);
// // //   const [showProfilePopup, setShowProfilePopup] = useState(false);
// // //   const [editMode, setEditMode] = useState(false);
// // //   const [doctorInfo, setDoctorInfo] = useState({});
// // //   const navigate = useNavigate();

// // //   useEffect(() => {
// // //     const fetchDoctorDetails = async () => {
// // //       try {
// // //         const token = localStorage.getItem('jwtToken');
// // //         if (!token) {
// // //           console.error('No JWT token found in localStorage');
// // //           return;
// // //         }
// // //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// // //           headers: {
// // //             'Auth': `Bearer ${token}`,
// // //             'Content-Type': 'application/json'
// // //           }
// // //         });

// // //         if (!response.ok) {
// // //           throw new Error(`HTTP error! Status: ${response.status}`);
// // //         }

// // //         const data = await response.json();
// // //         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
// // //         setDoctorInfo({
// // //           name: capitalizedDoctorName,
// // //           email: data.email,
// // //           hospitalName: data.hospitalName,
// // //           hospitalAddress: data.hospitalAddress,
// // //           degree: data.docHsProfileDegree,
// // //           specialist: data.docHsProfileSpecilist,
// // //           image: data.docHsProfileImage,
// // //           experience: data.docHsProfileExperience,
// // //           address: data.docHsProfileAddress,
// // //           age: data.docHsProfileHsAge,
// // //           gender: data.docHsProfileGender,
// // //           bloodGroup: data.docHsProfileBloodGroup,
// // //         });
// // //       } catch (error) {
// // //         console.error('Error fetching doctor details:', error);
// // //       }
// // //     };

// // //     fetchDoctorDetails();
// // //   }, []);

// // //   const toggleNotification = () => {
// // //     setShowNotification(!showNotification);
// // //   };

// // //   const toggleSettingsPopup = () => {
// // //     setShowSettingsPopup(!showSettingsPopup);
// // //     setShowProfilePopup(false);
// // //   };

// // //   const toggleProfilePopup = () => {
// // //     setShowProfilePopup(!showProfilePopup);
// // //     setShowSettingsPopup(false);
// // //   };

// // //   const handleInputChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setDoctorInfo(prevInfo => ({
// // //       ...prevInfo,
// // //       [name]: value
// // //     }));
// // //   };

// // //   const handleUpdateProfile = async () => {
// // //     try {
// // //       const token = localStorage.getItem('jwtToken');
// // //       if (!token) {
// // //         console.error('No JWT token found in localStorage');
// // //         return;
// // //       }
// // //       const response = await fetch(`${BASE_URL}/registerDocHs/update-doctor`, {
// // //         method: 'POST',
// // //         headers: {
// // //           'Auth': `Bearer ${token}`,
// // //           'Content-Type': 'application/json'
// // //         },
// // //         body: JSON.stringify(doctorInfo)
// // //       });

// // //       if (!response.ok) {
// // //         throw new Error(`HTTP error! Status: ${response.status}`);
// // //       }

// // //       const data = await response.json();
// // //       console.log('Profile updated successfully:', data);
// // //       setEditMode(false);
// // //     } catch (error) {
// // //       console.error('Error updating doctor details:', error);
// // //     }
// // //   };

// // //   const logout = () => {
// // //     localStorage.removeItem("jwtToken");
// // //     navigate("/");
// // //   };

// // //   return (
// // //     <div className="navbar-section">
// // //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // //       </div>

// // //       <ul className="navbar-items">
// // //         <li>
// // //           <Link to="/dashdoc">
// // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // //           </Link>
// // //         </li>
// // //         <li>
// // //           <img
// // //             src={notificationIcon}
// // //             alt="Notification Icon"
// // //             className="history-icon"
// // //             onClick={toggleNotification}
// // //           />
// // //           {showNotification && (
// // //             <div className="notification-box">
// // //               <ul>
// // //                 <li>Appointment Scheduled</li>
// // //               </ul>
// // //             </div>
// // //           )}
// // //         </li>
// // //         <li>
// // //           <img
// // //             src={settingicon}
// // //             alt="Setting Icon"
// // //             className="history-icon"
// // //             onClick={toggleSettingsPopup}
// // //           />
// // //           {showSettingsPopup && (
// // //             <div className="popup show">
// // //               <ul>
// // //                 <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
// // //                 <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
// // //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// // //                 <li><Link to="/" onClick={logout}>Logout</Link></li>
// // //               </ul>
// // //             </div>
// // //           )}
// // //         </li>
// // //       </ul>

// // //       {/* Profile Details Popup */}
// // //       {showProfilePopup && (
// // //         <div className="profile-popup show">
// // //           <div className="doctor-details">
// // //             <div className="profile-image-container">
// // //               {doctorInfo.image ? (
// // //                 <img
// // //                   src={`${BASE_URL}${doctorInfo.image}`}
// // //                   alt="Doctor Profile"
// // //                   className="profile-image"
// // //                 />
// // //               ) : (
// // //                 <div className="profile-image-placeholder">No Image</div>
// // //               )}
// // //             </div>
// // //             <h2>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</h2>
// // //             {editMode ? (
// // //               <>
// // //                 <p><strong>Email:</strong> <input type="email" name="email" value={doctorInfo.email || ''} onChange={handleInputChange} /></p>
// // //                 <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={doctorInfo.hospitalName || ''} onChange={handleInputChange} /></p>
// // //                 <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={doctorInfo.hospitalAddress || ''} onChange={handleInputChange} /></p>
// // //                 <p><strong>Degree:</strong> <input type="text" name="degree" value={doctorInfo.degree || ''} onChange={handleInputChange} /></p>
// // //                 <p><strong>Specialist:</strong> <input type="text" name="specialist" value={doctorInfo.specialist || ''} onChange={handleInputChange} /></p>
// // //                 <p><strong>Experience:</strong> <input type="text" name="experience" value={doctorInfo.experience || ''} onChange={handleInputChange} /></p>
// // //                 <p><strong>Address:</strong> <input type="text" name="address" value={doctorInfo.address || ''} onChange={handleInputChange} /></p>
// // //                 <p><strong>Age:</strong> <input type="number" name="age" value={doctorInfo.age || ''} onChange={handleInputChange} /></p>
// // //                 <p><strong>Gender:</strong> <input type="text" name="gender" value={doctorInfo.gender || ''} onChange={handleInputChange} /></p>
// // //                 <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={doctorInfo.bloodGroup || ''} onChange={handleInputChange} /></p>
// // //                 <button onClick={handleUpdateProfile}>Update Profile</button>
// // //                 <button onClick={() => setEditMode(false)}>Cancel</button>
// // //               </>
// // //             ) : (
// // //               <>
// // //                 <p><strong>Email:</strong> {doctorInfo.email || 'N/A'}</p>
// // //                 <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName || 'N/A'}</p>
// // //                 <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress || 'N/A'}</p>
// // //                 <p><strong>Degree:</strong> {doctorInfo.degree || 'N/A'}</p>
// // //                 <p><strong>Specialist:</strong> {doctorInfo.specialist || 'N/A'}</p>
// // //                 <p><strong>Experience:</strong> {doctorInfo.experience || 'N/A'}</p>
// // //                 <p><strong>Address:</strong> {doctorInfo.address || 'N/A'}</p>
// // //                 <p><strong>Age:</strong> {doctorInfo.age || 'N/A'}</p>
// // //                 <p><strong>Gender:</strong> {doctorInfo.gender || 'N/A'}</p>
// // //                 <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup || 'N/A'}</p>
// // //                 <button onClick={() => setEditMode(true)}>Edit Profile</button>
// // //               </>
// // //             )}
// // //           </div>
// // //         </div>
// // //       )}
// // //     </div>
// // //   );
// // // }

// // // export default Navbar;
// // import React, { useState, useEffect } from "react";
// // import { Link, useNavigate } from "react-router-dom";
// // import Homeicon from "../Assets/Home icon.png";
// // import settingicon from "../Assets/setting icon.png";
// // import notificationIcon from "../Assets/notification icon.png";
// // import logo from "../Assets/O2I_LOGO.png";
// // import { BASE_URL } from './config/apiConfig';

// // function Navbar() {
// //   const [showNotification, setShowNotification] = useState(false);
// //   const [showSettingsPopup, setShowSettingsPopup] = useState(false);
// //   const [showProfilePopup, setShowProfilePopup] = useState(false);
// //   const [editMode, setEditMode] = useState(false);
// //   const [doctorInfo, setDoctorInfo] = useState({});
// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     const fetchDoctorDetails = async () => {
// //       try {
// //         const token = localStorage.getItem('jwtToken');
// //         if (!token) {
// //           console.error('No JWT token found in localStorage');
// //           return;
// //         }
// //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// //           headers: {
// //             'Auth': `Bearer ${token}`,
// //             'Content-Type': 'application/json'
// //           }
// //         });

// //         if (!response.ok) {
// //           throw new Error(`HTTP error! Status: ${response.status}`);
// //         }

// //         const data = await response.json();
// //         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
// //         setDoctorInfo({
// //           name: capitalizedDoctorName,
// //           email: data.email,
// //           hospitalName: data.hospitalName,
// //           hospitalAddress: data.hospitalAddress,
// //           degree: data.docHsProfileDegree,
// //           specialist: data.docHsProfileSpecilist,
// //           image: data.docHsProfileImage,
// //           experience: data.docHsProfileExperience,
// //           address: data.docHsProfileAddress,
// //           age: data.docHsProfileHsAge,
// //           gender: data.docHsProfileGender,
// //           bloodGroup: data.docHsProfileBloodGroup,
// //         });
// //       } catch (error) {
// //         console.error('Error fetching doctor details:', error);
// //       }
// //     };

// //     fetchDoctorDetails();
// //   }, []);

// //   const toggleNotification = () => {
// //     setShowNotification(!showNotification);
// //   };

// //   const toggleSettingsPopup = () => {
// //     setShowSettingsPopup(!showSettingsPopup);
// //     setShowProfilePopup(false);
// //   };

// //   const toggleProfilePopup = () => {
// //     setShowProfilePopup(!showProfilePopup);
// //     setShowSettingsPopup(false);
// //   };

// //   const handleInputChange = (e) => {
// //     const { name, value } = e.target;
// //     setDoctorInfo(prevInfo => ({
// //       ...prevInfo,
// //       [name]: value
// //     }));
// //   };

// //   const handleFileChange = (e) => {
// //     const file = e.target.files[0];
// //     setDoctorInfo(prevInfo => ({
// //       ...prevInfo,
// //       image: file
// //     }));
// //   };

// //  const handleUpdateProfile = async () => {
// //   try {
// //     const token = localStorage.getItem('jwtToken');
// //     if (!token) {
// //       console.error('No JWT token found in localStorage');
// //       return;
// //     }

// //     const formData = new FormData();
// //     formData.append('docHsProfileDegree', doctorInfo.degree || '');
// //     formData.append('docHsProfileSpecilist', doctorInfo.specialist || '');
// //     formData.append('docHsProfileExperience', doctorInfo.experience || '');
// //     formData.append('docHsProfileAddress', doctorInfo.address || '');
// //     formData.append('docHsProfileHsAge', doctorInfo.age || '');
// //     formData.append('docHsProfileGender', doctorInfo.gender || '');
// //     formData.append('docHsProfileBloodGroup', doctorInfo.bloodGroup || '');

// //     if (doctorInfo.image && doctorInfo.image instanceof File) {
// //       formData.append('docHsProfileImage', doctorInfo.image);
// //     }

// //     const response = await fetch(`${BASE_URL}/registerDocHs/profile-create`, {
// //       method: 'PUT',
// //       headers: {
// //         'Auth': `Bearer ${token}`
// //       },
// //       body: formData
// //     });

// //     const text = await response.text();  // Fetch response as text
// //     if (!response.ok) {
// //       throw new Error(`HTTP error! Status: ${response.status}`);
// //     }

// //     console.log('Profile updated successfully:', text);
// //     setEditMode(false);
// //   } catch (error) {
// //     console.error('Error updating doctor details:', error);
// //   }
// // };


// //   const logout = () => {
// //     localStorage.removeItem("jwtToken");
// //     navigate("/");
// //   };

// //   return (
// //     <div className="navbar-section">
// //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// //       </div>

// //       <ul className="navbar-items">
// //         <li>
// //           <Link to="/dashdoc">
// //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// //           </Link>
// //         </li>
// //         <li>
// //           <img
// //             src={notificationIcon}
// //             alt="Notification Icon"
// //             className="history-icon"
// //             onClick={toggleNotification}
// //           />
// //           {showNotification && (
// //             <div className="notification-box">
// //               <ul>
// //                 <li>Appointment Scheduled</li>
// //               </ul>
// //             </div>
// //           )}
// //         </li>
// //         <li>
// //           <img
// //             src={settingicon}
// //             alt="Setting Icon"
// //             className="history-icon"
// //             onClick={toggleSettingsPopup}
// //           />
// //           {showSettingsPopup && (
// //             <div className="popup show">
// //               <ul>
// //                 <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
// //                 <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
// //                 <li><Link to="/drchangepassword">Change Password</Link></li>
// //                 <li><Link to="/" onClick={logout}>Logout</Link></li>
// //               </ul>
// //             </div>
// //           )}
// //         </li>
// //       </ul>

// //       {/* Profile Details Popup */}
// //       {showProfilePopup && (
// //         <div className="profile-popup show">
// //           <div className="doctor-details">
// //             <div className="profile-image-container">
// //               {doctorInfo.image ? (
// //                 <img
// //                   src={`${BASE_URL}${doctorInfo.image}`}
// //                   alt="Doctor Profile"
// //                   className="profile-image"
// //                 />
// //               ) : (
// //                 <div className="profile-image-placeholder">No Image</div>
// //               )}
// //             </div>
// //             <h2>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</h2>
// //             {editMode ? (
// //               <>
// //                 <p><strong>Email:</strong> <input type="email" name="email" value={doctorInfo.email || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={doctorInfo.hospitalName || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={doctorInfo.hospitalAddress || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Degree:</strong> <input type="text" name="degree" value={doctorInfo.degree || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Specialist:</strong> <input type="text" name="specialist" value={doctorInfo.specialist || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Experience:</strong> <input type="text" name="experience" value={doctorInfo.experience || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Address:</strong> <input type="text" name="address" value={doctorInfo.address || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Age:</strong> <input type="number" name="age" value={doctorInfo.age || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Gender:</strong> <input type="text" name="gender" value={doctorInfo.gender || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={doctorInfo.bloodGroup || ''} onChange={handleInputChange} /></p>
// //                 <p><strong>Profile Image:</strong> <input type="file" accept="image/*" onChange={handleFileChange} /></p>
// //                 <button onClick={handleUpdateProfile}>Update Profile</button>
// //                 <button onClick={() => setEditMode(false)}>Cancel</button>
// //               </>
// //             ) : (
// //               <>
// //                 <p><strong>Email:</strong> {doctorInfo.email || 'N/A'}</p>
// //                 <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName || 'N/A'}</p>
// //                 <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress || 'N/A'}</p>
// //                 <p><strong>Degree:</strong> {doctorInfo.degree || 'N/A'}</p>
// //                 <p><strong>Specialist:</strong> {doctorInfo.specialist || 'N/A'}</p>
// //                 <p><strong>Experience:</strong> {doctorInfo.experience || 'N/A'}</p>
// //                 <p><strong>Address:</strong> {doctorInfo.address || 'N/A'}</p>
// //                 <p><strong>Age:</strong> {doctorInfo.age || 'N/A'}</p>
// //                 <p><strong>Gender:</strong> {doctorInfo.gender || 'N/A'}</p>
// //                 <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup || 'N/A'}</p>
// //                 <button onClick={() => setEditMode(true)}>Edit Profile</button>
// //               </>
// //             )}
// //           </div>
// //         </div>
// //       )}
// //     </div>
// //   );
// // }

// // export default Navbar;
// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Homeicon from "../Assets/Home icon.png";
// import settingicon from "../Assets/setting icon.png";
// import notificationIcon from "../Assets/notification icon.png";
// import logo from "../Assets/O2I_LOGO.png";
// import { BASE_URL } from './config/apiConfig';

// function Navbar() {
//   const [showNotification, setShowNotification] = useState(false);
//   const [showSettingsPopup, setShowSettingsPopup] = useState(false);
//   const [showProfilePopup, setShowProfilePopup] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [doctorInfo, setDoctorInfo] = useState({});
//   const [successMessage, setSuccessMessage] = useState(""); // State for success message
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchDoctorDetails = async () => {
//       try {
//         const token = localStorage.getItem('jwtToken');
//         if (!token) {
//           console.error('No JWT token found in localStorage');
//           return;
//         }
//         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
//           headers: {
//             'Auth': `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
//         setDoctorInfo({
//           name: capitalizedDoctorName,
//           email: data.email,
//           hospitalName: data.hospitalName,
//           hospitalAddress: data.hospitalAddress,
//           degree: data.docHsProfileDegree,
//           specialist: data.docHsProfileSpecilist,
//           image: data.docHsProfileImage,
//           experience: data.docHsProfileExperience,
//           address: data.docHsProfileAddress,
//           age: data.docHsProfileHsAge,
//           gender: data.docHsProfileGender,
//           bloodGroup: data.docHsProfileBloodGroup,
//         });
//       } catch (error) {
//         console.error('Error fetching doctor details:', error);
//       }
//     };

//     fetchDoctorDetails();
//   }, []);

//   const toggleNotification = () => {
//     setShowNotification(!showNotification);
//   };

//   const toggleSettingsPopup = () => {
//     setShowSettingsPopup(!showSettingsPopup);
//     setShowProfilePopup(false);
//   };

//   const toggleProfilePopup = () => {
//     setShowProfilePopup(!showProfilePopup);
//     setShowSettingsPopup(false);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setDoctorInfo(prevInfo => ({
//       ...prevInfo,
//       [name]: value
//     }));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setDoctorInfo(prevInfo => ({
//       ...prevInfo,
//       image: file
//     }));
//   };

//   const handleUpdateProfile = async () => {
//     try {
//       const token = localStorage.getItem('jwtToken');
//       if (!token) {
//         console.error('No JWT token found in localStorage');
//         return;
//       }

//       const formData = new FormData();
//       formData.append('docHsProfileDegree', doctorInfo.degree || '');
//       formData.append('docHsProfileSpecilist', doctorInfo.specialist || '');
//       formData.append('docHsProfileExperience', doctorInfo.experience || '');
//       formData.append('docHsProfileAddress', doctorInfo.address || '');
//       formData.append('docHsProfileHsAge', doctorInfo.age || '');
//       formData.append('docHsProfileGender', doctorInfo.gender || '');
//       formData.append('docHsProfileBloodGroup', doctorInfo.bloodGroup || '');

//       if (doctorInfo.image && doctorInfo.image instanceof File) {
//         formData.append('docHsProfileImage', doctorInfo.image);
//       }

//       const response = await fetch(`${BASE_URL}/registerDocHs/profile-create`, {
//         method: 'PUT',
//         headers: {
//           'Auth': `Bearer ${token}`
//         },
//         body: formData
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       setSuccessMessage("Profile updated successfully!"); // Set success message
//       setEditMode(false);
//     } catch (error) {
//       console.error('Error updating doctor details:', error);
//     }
//   };

//   const logout = () => {
//     localStorage.removeItem("jwtToken");
//     navigate("/");
//   };

//   return (
//     <div className="navbar-section">
//       <div className="navbar-title" style={{ marginLeft: '10px' }}>
//         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
//       </div>

//       <ul className="navbar-items">
//         <li>
//           <Link to="/dashdoc">
//             <img src={Homeicon} alt="Home Icon" className="history-icon" />
//           </Link>
//         </li>
//         <li>
//           <img
//             src={notificationIcon}
//             alt="Notification Icon"
//             className="history-icon"
//             onClick={toggleNotification}
//           />
//           {showNotification && (
//             <div className="notification-box">
//               <ul>
//                 <li>Appointment Scheduled</li>
//               </ul>
//             </div>
//           )}
//         </li>
//         <li>
//           <img
//             src={settingicon}
//             alt="Setting Icon"
//             className="history-icon"
//             onClick={toggleSettingsPopup}
//           />
//           {showSettingsPopup && (
//             <div className="popup show">
//               <ul>
//                 <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
//                 <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
//                 <li><Link to="/drchangepassword">Change Password</Link></li>
//                 <li><Link to="/" onClick={logout}>Logout</Link></li>
//               </ul>
//             </div>
//           )}
//         </li>
//       </ul>

//       {/* Profile Details Popup */}
//       {showProfilePopup && (
//         <div className="profile-popup show">
//           <div className="doctor-details">
//             <div className="profile-image-container">
//               {doctorInfo.image ? (
//                 <img
//                   src={`${BASE_URL}${doctorInfo.image}`}
//                   alt="Doctor Profile"
//                   className="profile-image"
//                 />
//               ) : (
//                 <div className="profile-image-placeholder">No Image</div>
//               )}
//             </div>
//             <h2>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</h2>
//             {editMode ? (
//               <>
//                 <p><strong>Email:</strong> <input type="email" name="email" value={doctorInfo.email || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={doctorInfo.hospitalName || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={doctorInfo.hospitalAddress || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Degree:</strong> <input type="text" name="degree" value={doctorInfo.degree || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Specialist:</strong> <input type="text" name="specialist" value={doctorInfo.specialist || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Experience:</strong> <input type="text" name="experience" value={doctorInfo.experience || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Address:</strong> <input type="text" name="address" value={doctorInfo.address || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Age:</strong> <input type="number" name="age" value={doctorInfo.age || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Gender:</strong> <input type="text" name="gender" value={doctorInfo.gender || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={doctorInfo.bloodGroup || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Profile Image:</strong> <input type="file" accept="image/*" onChange={handleFileChange} /></p>
//                 <button onClick={handleUpdateProfile}>Update Profile</button>
//                 <button onClick={() => setEditMode(false)}>Cancel</button>
//               </>
//             ) : (
//               <>
//                 <p><strong>Email:</strong> {doctorInfo.email}</p>
//                 <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName}</p>
//                 <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress}</p>
//                 <p><strong>Degree:</strong> {doctorInfo.degree}</p>
//                 <p><strong>Specialist:</strong> {doctorInfo.specialist}</p>
//                 <p><strong>Experience:</strong> {doctorInfo.experience}</p>
//                 <p><strong>Address:</strong> {doctorInfo.address}</p>
//                 <p><strong>Age:</strong> {doctorInfo.age}</p>
//                 <p><strong>Gender:</strong> {doctorInfo.gender}</p>
//                 <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup}</p>
//                 <button onClick={() => setEditMode(true)}>Edit Profile</button>
//               </>
//             )}
//           </div>
//         </div>
//       )}

//       {/* Display success message */}
//       {successMessage && (
//         <div className="success-message">
//           {successMessage}
//         </div>
//       )}
//     </div>
//   );
// }

// export default Navbar;
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Homeicon from "../Assets/Home icon.png";
import settingicon from "../Assets/setting icon.png";
import notificationIcon from "../Assets/notification icon.png";
import logo from "../Assets/O2I_LOGO.png";
import { BASE_URL } from './config/apiConfig';

function Navbar() {
  const [showNotification, setShowNotification] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [doctorInfo, setDoctorInfo] = useState({});
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDoctorDetails = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          console.error('No JWT token found in localStorage');
          return;
        }
        const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
          headers: {
            'Auth': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const capitalizedDoctorName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
        setDoctorInfo({
          name: capitalizedDoctorName,
          email: data.email,
          hospitalName: data.hospitalName,
          hospitalAddress: data.hospitalAddress,
          degree: data.docHsProfileDegree,
          specialist: data.docHsProfileSpecilist,
          image: data.docHsProfileImage,
          experience: data.docHsProfileExperience,
          address: data.docHsProfileAddress,
          age: data.docHsProfileHsAge,
          gender: data.docHsProfileGender,
          bloodGroup: data.docHsProfileBloodGroup,
        });
      } catch (error) {
        console.error('Error fetching doctor details:', error);
      }
    };

    fetchDoctorDetails();
  }, []);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const toggleSettingsPopup = () => {
    setShowSettingsPopup(!showSettingsPopup);
    setShowProfilePopup(false);
  };

  const toggleProfilePopup = () => {
    setShowProfilePopup(!showProfilePopup);
    setShowSettingsPopup(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDoctorInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setDoctorInfo(prevInfo => ({
      ...prevInfo,
      image: file
    }));
  };

  const handleUpdateProfile = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        console.error('No JWT token found in localStorage');
        return;
      }

      const formData = new FormData();
      formData.append('docHsProfileDegree', doctorInfo.degree || '');
      formData.append('docHsProfileSpecilist', doctorInfo.specialist || '');
      formData.append('docHsProfileExperience', doctorInfo.experience || '');
      formData.append('docHsProfileAddress', doctorInfo.address || '');
      formData.append('docHsProfileHsAge', doctorInfo.age || '');
      formData.append('docHsProfileGender', doctorInfo.gender || '');
      formData.append('docHsProfileBloodGroup', doctorInfo.bloodGroup || '');

      if (doctorInfo.image && doctorInfo.image instanceof File) {
        formData.append('docHsProfileImage', doctorInfo.image);
      }

      const response = await fetch(`${BASE_URL}/registerDocHs/profile-create`, {
        method: 'PUT',
        headers: {
          'Auth': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setSuccessMessage("Profile updated successfully!"); // Set success message
      setEditMode(false);

      // Hide the success message after 3 seconds
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      console.error('Error updating doctor details:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem("jwtToken");
    navigate("/");
  };

  return (
    <div className="navbar-section">
      <div className="navbar-title" style={{ marginLeft: '10px' }}>
        <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
      </div>

      <ul className="navbar-items">
        <li>
          <Link to="/dashdoc">
            <img src={Homeicon} alt="Home Icon" className="history-icon" />
          </Link>
        </li>
        <li>
          <img
            src={notificationIcon}
            alt="Notification Icon"
            className="history-icon"
            onClick={toggleNotification}
          />
          {showNotification && (
            <div className="notification-box">
              <ul>
                <li>Appointment Scheduled</li>
              </ul>
            </div>
          )}
        </li>
        <li>
          <img
            src={settingicon}
            alt="Setting Icon"
            className="history-icon"
            onClick={toggleSettingsPopup}
          />
          {showSettingsPopup && (
            <div className="popup show">
              <ul>
                <li>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</li>
                <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
                <li><Link to="/drchangepassword">Change Password</Link></li>
                <li><Link to="/" onClick={logout}>Logout</Link></li>
              </ul>
            </div>
          )}
        </li>
      </ul>

      {/* Profile Details Popup */}
      {showProfilePopup && (
        <div className="profile-popup show">
          <div className="doctor-details">
            <div className="profile-image-container">
              {doctorInfo.image ? (
                <img
                  src={`${BASE_URL}${doctorInfo.image}`}
                  alt="Doctor Profile"
                  className="profile-image"
                />
              ) : (
                <div className="profile-image-placeholder">No Image</div>
              )}
            </div>
            <h2>{doctorInfo.name ? `Dr. ${doctorInfo.name}` : 'Dr.'}</h2>
            {editMode ? (
              <>
                <p><strong>Email:</strong> <input type="email" name="email" value={doctorInfo.email || ''} onChange={handleInputChange} /></p>
                <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={doctorInfo.hospitalName || ''} onChange={handleInputChange} /></p>
                <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={doctorInfo.hospitalAddress || ''} onChange={handleInputChange} /></p>
                <p><strong>Degree:</strong> <input type="text" name="degree" value={doctorInfo.degree || ''} onChange={handleInputChange} /></p>
                <p><strong>Specialist:</strong> <input type="text" name="specialist" value={doctorInfo.specialist || ''} onChange={handleInputChange} /></p>
                <p><strong>Experience:</strong> <input type="text" name="experience" value={doctorInfo.experience || ''} onChange={handleInputChange} /></p>
                <p><strong>Address:</strong> <input type="text" name="address" value={doctorInfo.address || ''} onChange={handleInputChange} /></p>
                <p><strong>Age:</strong> <input type="number" name="age" value={doctorInfo.age || ''} onChange={handleInputChange} /></p>
                <p><strong>Gender:</strong> 
                  <select name="gender" value={doctorInfo.gender || ''} onChange={handleInputChange}>
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </p>
                <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={doctorInfo.bloodGroup || ''} onChange={handleInputChange} /></p>
                <input type="file" name="image" onChange={handleFileChange} />
                <button onClick={handleUpdateProfile}>Save</button>
                <button onClick={() => setEditMode(false)}>Cancel</button>
              </>
            ) : (
              <>
                <p><strong>Email:</strong> {doctorInfo.email}</p>
                <p><strong>Hospital Name:</strong> {doctorInfo.hospitalName}</p>
                <p><strong>Hospital Address:</strong> {doctorInfo.hospitalAddress}</p>
                <p><strong>Degree:</strong> {doctorInfo.degree}</p>
                <p><strong>Specialist:</strong> {doctorInfo.specialist}</p>
                <p><strong>Experience:</strong> {doctorInfo.experience}</p>
                <p><strong>Address:</strong> {doctorInfo.address}</p>
                <p><strong>Age:</strong> {doctorInfo.age}</p>
                <p><strong>Gender:</strong> {doctorInfo.gender}</p>
                <p><strong>Blood Group:</strong> {doctorInfo.bloodGroup}</p>
                <button onClick={() => setEditMode(true)}>Edit Profile</button>
              </>
            )}
          </div>
        </div>
      )}

      {/* Display success message as an overlay */}
      {successMessage && (
        <div className="success-message-overlay">
          {successMessage}
        </div>
      )}
    </div>
  );
}

export default Navbar;
