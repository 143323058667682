
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// // import Footer from '../../Footer';
// // import Header from "../../Hoheader";
// // import Videoheader from "../Videoheader/Videoheader";
// import { BASE_URL } from './config/apiConfig';

// const pageStyle = {
//   background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)',
// };

// const appointmentHistoryStyle = {
//   textAlign: 'center',
//   color: '#324E6A',
//   fontSize: '50px',
//   textShadow: '0px 0px 32.1px #BB1111',
// };

// const tableContainerStyle = {
//   maxWidth: '1200px',
//   margin: '0 auto',
//   padding: '20px',
//   backgroundColor: '#DCE7EB',
//   border: '2px solid #104E8D',
//   borderBottom: '2px solid #104E8D',
//   overflowY: 'auto',
//   marginBottom: '10px',
//   maxHeight: '600px',
// };

// const tableStyle = {
//   width: '100%',
//   borderCollapse: 'separate',
//   border: '2px solid #D9D9D9',
// };

// const thStyle = {
//   backgroundColor: '#0F60B3',
//   color: 'white',
//   padding: '12px 8px',
//   fontWeight: 'bold',
//   textAlign: 'left',
//   borderBottom: '1px solid #fff',
//   borderRight: '2px solid #fff',
// };

// const tdStyle = {
//   padding: '8px',
//   borderBottom: '1px solid #ddd',
//   textAlign: 'left',
//   borderRight: '2px solid #ddd',
//   cursor: 'pointer', // Make the action cells clickable
// };

// const lastColumnStyle = {
//   borderRight: '2px solid #fff',
// };

// const columnStyles = {
//   patient: { maxWidth: '100px' },
//   doctor: { maxWidth: '80px' },
//   complaint: { maxWidth: '150px' },
//   appointmentTime: { maxWidth: '100px' },
//   status: { maxWidth: '100px' },
//   action: { maxWidth: '100px' },
// };

// const popupStyle = {
//   position: 'fixed',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   backgroundColor: '#fff',
//   padding: '20px',
//   boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
//   zIndex: 1000,
//   width: '400px',
//   borderRadius: '10px',
// };

// const popupOverlayStyle = {
//   position: 'fixed',
//   top: 0,
//   left: 0,
//   width: '100%',
//   height: '100%',
//   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   zIndex: 999,
// };

// const closeButtonStyle = {
//   position: 'absolute',
//   top: '10px',
//   right: '10px',
//   cursor: 'pointer',
//   background: 'none',
//   border: 'none',
//   fontSize: '20px',
// };

// function AppointmentHistory() {
//   const [range, setRange] = useState("1-2");
//   const [showPopup, setShowPopup] = useState(false); // State for showing/hiding popup
//   const [selectedData, setSelectedData] = useState(null); // State to store selected data for popup
//   const [appointmentHistory, setAppointmentHistory] = useState([]); // State to store fetched appointment history

//   useEffect(() => {
//     // Fetch data from API
//     const fetchData = async () => {
//       const jwtToken = localStorage.getItem('jwtToken');
    
//       if (!jwtToken) {
//         console.error('No JWT token available for authentication');
//         return;
//       }
      
//       console.log('JWT Token:', jwtToken);
//       try {
//         const response = await axios.get(`${BASE_URL}/registerDocHs/all-history`, {
//           headers: {
//             'Content-Type': 'application/json',
//             'Auth': `Bearer ${jwtToken}`
//           }
//         });
//         setAppointmentHistory(response.data); // Assuming response.data is an array of appointment history objects
//       } catch (error) {
//         console.error('Error fetching appointment history:', error);
//       }
//     };

//     fetchData();
//   }, []); // Empty dependency array means this effect runs once after the initial render

//   const handleRangeChange = (event) => {
//     setRange(event.target.value);
//   };

//   const handlePrescriptionClick = (data) => {
//     setSelectedData(data);
//     setShowPopup(true);
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//     setSelectedData(null);
//   };

//   const rowHeight = 45;
//   const numberOfDataRows = 10;
//   const headerHeight = 100;
//   const totalRows = appointmentHistory.length;
//   const displayedRows = Math.max(totalRows, numberOfDataRows);
//   const containerHeight = headerHeight + rowHeight * displayedRows;

//   return (
//     <div style={pageStyle}>
  
//       <div style={appointmentHistoryStyle}>
//         Appointment History
//       </div>
//       <div style={tableContainerStyle}>
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <div style={{ display: 'flex', alignItems: 'center' }}>
//             <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
//             <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
//               <option value="1-2">1-2</option>
//               <option value="3-4">3-4</option>
//             </select>
//             <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
//           </div>
//           <span style={{ marginLeft: 'auto', marginRight: '23px', color: '#324E6A' }}>Search: </span>
//           <input type="text" style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px' }} />
//         </div>
//         <table style={tableStyle}>
//           <thead>
//             <tr>
//               <th style={{ ...thStyle, ...columnStyles.patient }}>Patient</th>
//               <th style={{ ...thStyle, ...columnStyles.healthofficer }}>Healthofficer</th>
//               <th style={{ ...thStyle, ...columnStyles.complaint }}>complaint</th>
//               <th style={{ ...thStyle, ...columnStyles.appointmentTime }}>Appointment Time</th>
//               <th style={{ ...thStyle, ...columnStyles.status }}>Status</th>
//               <th style={{ ...thStyle, ...columnStyles.action, ...lastColumnStyle }}>Prescription</th>
//             </tr>
//           </thead>
//           <tbody style={{ overflowX: 'auto' }}>
//             {appointmentHistory.map((data, index) => (
//               <tr key={index} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
//                 <td style={{ ...tdStyle, ...columnStyles.patient }}>{data.patientName}</td>
//                 <td style={{ ...tdStyle, ...columnStyles.healthofficer }}>{data.healthOfficer}</td>
//                 <td style={{ ...tdStyle, ...columnStyles.complaint }}>{data.complaint}</td>
//                 <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}>{data.localDate.join('/')}</td>
//                 <td style={{ ...tdStyle, ...columnStyles.status }}>{data.status ? 'Confirmed' : 'Pending'}</td>
//                 <td style={{ ...tdStyle, ...columnStyles.action, ...lastColumnStyle }} onClick={() => handlePrescriptionClick(data)}>View</td>
//               </tr>
//             ))}
//             {[...Array(displayedRows - totalRows)].map((_, index) => (
//               <tr key={index + totalRows} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
//                 <td style={{ ...tdStyle, ...columnStyles.patient }}></td>
//                 <td style={{ ...tdStyle, ...columnStyles.healthofficer }}></td>
//                 <td style={{ ...tdStyle, ...columnStyles.complaint }}></td>
//                 <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}></td>
//                 <td style={{ ...tdStyle, ...columnStyles.status }}></td>
//                 <td style={{ ...tdStyle, ...columnStyles.action, ...lastColumnStyle }}></td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         {/* {showPopup && (
//           <div style={popupOverlayStyle}>
//             <div style={popupStyle}>
//               <button onClick={closePopup} style={closeButtonStyle}>×</button>
//               <div>Prescription for {selectedData.patientName}</div>
//               <div>
//                 <div>Chief Complaints: {selectedData.prescriptions[0].chiefComplaints}</div>
//                 <div>Symptoms: {selectedData.prescriptions[0].symptoms}</div>
//                 <div>Advice: {selectedData.prescriptions[0].advice}</div>
//                 <div>Provisional Diagnosis: {selectedData.prescriptions[0].provisionalDiagnosis}</div>
//                 <div>Allergy: {selectedData.prescriptions[0].allergy}</div>
//                 <div>Comorbidity: {selectedData.prescriptions[0].comorbidity}</div>
//                 <div>Follow-up Date: {selectedData.prescriptions[0].followUpDate}</div>
//                 <div>Other Illnesses: {selectedData.prescriptions[0].otherIllnesses.join(', ')}</div>
//                 <div>
//                   <div>Medications:</div>
//                   <ul>
//                     {selectedData.prescriptions[0].medications.map((med, index) => (
//                       <li key={index}>
//                         {med.medication} - {med.frequency}, {med.dosage} for {med.days} days
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//                 <div>
//                   <div>Laboratory Tests:</div>
//                   <ul>
//                     {selectedData.prescriptions[0].laboratory.map((lab, index) => (
//                       <li key={index}>
//                         {lab.laboratory}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )} */}

// {showPopup && (
//   <div style={popupOverlayStyle}>
//     <div style={popupStyle}>
//       <button onClick={closePopup} style={closeButtonStyle}>×</button>
//       <div style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '18px' }}>Prescription for {selectedData.patientName}</div>
//       <div style={{ marginBottom: '15px' }}>
//         <div><strong>Chief Complaints:</strong> {selectedData.prescriptions[0].chiefComplaints}</div>
//         <div><strong>Symptoms:</strong> {selectedData.prescriptions[0].symptoms}</div>
//         <div><strong>Advice:</strong> {selectedData.prescriptions[0].advice}</div>
//         <div><strong>Provisional Diagnosis:</strong> {selectedData.prescriptions[0].provisionalDiagnosis}</div>
//         <div><strong>Allergy:</strong> {selectedData.prescriptions[0].allergy}</div>
//         <div><strong>Comorbidity:</strong> {selectedData.prescriptions[0].comorbidity}</div>
//         <div><strong>Follow-up Date:</strong> {selectedData.prescriptions[0].followUpDate}</div>
//         <div><strong>Other Illnesses:</strong> {selectedData.prescriptions[0].otherIllnesses.join(', ')}</div>
//       </div>
//       <div style={{ marginBottom: '15px' }}>
//         <div><strong>Medications:</strong></div>
//         <ul style={{ paddingLeft: '20px' }}>
//           {selectedData.prescriptions[0].medications.map((med, index) => (
//             <li key={index}>
//               {med.medication} - {med.frequency}, {med.dosage} for {med.days} days
//             </li>
//           ))}
//         </ul>
//       </div>
//       <div>
//         <div><strong>Laboratory Tests:</strong></div>
//         <ul style={{ paddingLeft: '20px' }}>
//           {selectedData.prescriptions[0].laboratory.map((lab, index) => (
//             <li key={index}>
//               {lab.laboratory}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   </div>
// )}



//       </div>
     
//     </div>
//   );
// }

// export default AppointmentHistory;


//======================jdjfbrhjd                tset==============================



import React, { useState, useEffect } from "react";
import axios from "axios";

import { BASE_URL } from './config/apiConfig';
import 'jspdf-autotable';
import jsPDF from "jspdf";
import logo from "../Assets/logo.png"
const pageStyle = {
  background: 'linear-gradient(to bottom, #7CB2D0, #D0E0E5)',
};

const appointmentHistoryStyle = {
  textAlign: 'center',
  color: '#324E6A',
  fontSize: '50px',
  textShadow: '0px 0px 32.1px #BB1111',
};

const tableContainerStyle = {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#DCE7EB',
  border: '2px solid #104E8D',
  borderBottom: '2px solid #104E8D',
  overflowY: 'auto',
  marginBottom: '10px',
  maxHeight: '600px',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'separate',
  border: '2px solid #D9D9D9',
};

const thStyle = {
  backgroundColor: '#0F60B3',
  color: 'white',
  padding: '12px 8px',
  fontWeight: 'bold',
  textAlign: 'left',
  borderBottom: '1px solid #fff',
  borderRight: '2px solid #fff',
};

const tdStyle = {
  padding: '8px',
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  borderRight: '2px solid #ddd',
  cursor: 'pointer', // Make the action cells clickable
};

const lastColumnStyle = {
  borderRight: '2px solid #fff',
};

const columnStyles = {
  patient: { maxWidth: '100px' },
  doctor: { maxWidth: '80px' },
  complaint: { maxWidth: '150px' },
  appointmentTime: { maxWidth: '100px' },
  status: { maxWidth: '100px' },
  action: { maxWidth: '100px' },
};

const popupStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  padding: '20px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  width: '700px',
  borderRadius: '10px',
};

const popupOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  fontSize: '20px',
};

const generatePDF = (data) => {
  const doc = new jsPDF();
  doc.setFontSize(12);
  let y = 10;

  // Add the logo, Personal Info, and Hospital Info on the same line
  const img = new Image();
  img.src = logo;
  img.onload = function () {
    const personalInfoX = 10;
    const logoX = 90;
    const hospitalInfoX = 150;

    // Add Personal Information on the left
    doc.setFontSize(12);
    doc.text(`ID: ${data.patientId} - ${data.patientName} (${data.gender} / ${data.age} Y)`, personalInfoX, y + 5);
    doc.text(`Mob. No.: ${data.mobileNumber}`, personalInfoX, y + 10);
    doc.text(`Address: ${data.patientAddress}`, personalInfoX, y + 15);
    doc.text(`Weight: ${data.weight} Kg, Height: ${data.height} Cm`, personalInfoX, y + 20);
    doc.text(`Blood Group: ${data.bloodGroup}, BP: ${data.diastolicBP} mmHg`, personalInfoX, y + 25);

    doc.addImage(img, 'PNG', logoX, y, 30, 30);

    doc.setFontSize(14);
    doc.text(`Dr. ${data.doctorName}`, hospitalInfoX, y + 5);
    doc.setFontSize(10);
    doc.text(`${data.hospitalName}`, hospitalInfoX, y + 10);
    doc.text(`${data.hospitalAddress}`, hospitalInfoX, y + 15);
    doc.text(`Ph: ${data.hospitalMobileNumber}`, hospitalInfoX, y + 20);
    doc.text(`Timing: ${data.hospitalTiming}`, hospitalInfoX, y + 25);
    doc.text(`Closed: ${data.hospitalClosedDays}`, hospitalInfoX, y + 30);

    y += 40;
    doc.line(10, y, 200, y);

    // Clinical Information - Split into 50% of the width
    y += 10;
    doc.setFontSize(12);

    const leftColumnX = 10;
    const rightColumnX = 105; // 50% of the page width

    const splitChiefComplaints = doc.splitTextToSize(`- ${data.prescriptions[0].chiefComplaints}`, 90);
    const splitClinicalFindings = doc.splitTextToSize(`- ${data.prescriptions[0].clinicalFinding}`, 90);

    doc.text('Chief Complaints:', leftColumnX, y);
    doc.text(splitChiefComplaints, leftColumnX, y + 5);

    doc.text('Clinical Findings:', rightColumnX, y);
    doc.text(splitClinicalFindings, rightColumnX, y + 5);

    const maxLines = Math.max(splitChiefComplaints.length, splitClinicalFindings.length);
    y += (maxLines * 5) + 10; // Adjust y position based on the content

    // doc.line(10, y, 200, y); // Draw a line to separate sections

    // Provisional Diagnosis and Final Diagnosis
    y += 5; // Reduced space here
    doc.text('Provisional Diagnosis:', leftColumnX, y);
    doc.text(`- ${data.prescriptions[0].provisionalDiagnosis}`, leftColumnX + 45, y);

    doc.text('Final Diagnosis:', rightColumnX, y);
    doc.text(`- ${data.prescriptions[0].finalDiagnosis}`, rightColumnX + 45, y);

    y += 10;
    doc.line(10, y, 200, y);

    // Medications List
    y += 10;
    doc.text('Medications:', 10, y);
    y += 5;

    doc.autoTable({
      startY: y,
      head: [['#', 'Medicine Name', 'Dosage', 'Duration']],
      body: data.prescriptions[0].medications.map((med, index) => [
        index + 1,
        med.medication,
        med.dosage,
        `${med.days} Days`,
      ]),
      theme: 'plain',
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { textColor: [0, 0, 0] },
    });

    y = doc.autoTable.previous.finalY + 10; // Adjust y position after table
    doc.line(10, y, 200, y);

    // Laboratory Tests
    y += 10;
    doc.text('Laboratory Tests:', 10, y);
    y += 5;

    doc.autoTable({
      startY: y,
      head: [['#', 'Test Name']],
      body: data.prescriptions[0].laboratory.map((lab, index) => [
        index + 1,
        lab.laboratory,
      ]),
      theme: 'plain',
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { textColor: [0, 0, 0] },
    });

    y = doc.autoTable.previous.finalY + 10;
    doc.line(10, y, 200, y);

    // Advice and Follow-up
    y += 10;
    doc.text('Advice:', 10, y);
    y += 5;
    doc.text(`- ${data.prescriptions[0].advice}`, 15, y);
    y += 10;
    doc.text(`Follow-up Date: ${data.prescriptions[0].followUpDate}`, 10, y);

    doc.save('prescription.pdf');
  };
};

function AppointmentHistory() {
  const [range, setRange] = useState("1-2");
  const [showPopup, setShowPopup] = useState(false); // State for showing/hiding popup
  const [selectedData, setSelectedData] = useState(null); // State to store selected data for popup
  const [appointmentHistory, setAppointmentHistory] = useState([]); // State to store fetched appointment history

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      const jwtToken = localStorage.getItem('jwtToken');
    
      if (!jwtToken) {
        console.error('No JWT token available for authentication');
        return;
      }
      
      console.log('JWT Token:', jwtToken);
      try {
        const response = await axios.get(`${BASE_URL}/registerDocHs/all-history`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${jwtToken}`
          }
        });
        setAppointmentHistory(response.data); // Assuming response.data is an array of appointment history objects
      } catch (error) {
        console.error('Error fetching appointment history:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render

  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };

  const handlePrescriptionClick = (data) => {
    setSelectedData(data);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedData(null);
  };

  const rowHeight = 45;
  const numberOfDataRows = 10;
  const headerHeight = 100;
  const totalRows = appointmentHistory.length;
  const displayedRows = Math.max(totalRows, numberOfDataRows);
  const containerHeight = headerHeight + rowHeight * displayedRows;

  return (
    <div style={pageStyle}>
  
      <div style={appointmentHistoryStyle}>
        Appointment History
      </div>
      <div style={tableContainerStyle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: '23px', marginRight: '10px', color: '#324E6A' }}>Show </span>
            <select value={range} onChange={handleRangeChange} style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none' }}>
              <option value="1-2">1-2</option>
              <option value="3-4">3-4</option>
            </select>
            <span style={{ marginLeft: '15px', marginRight: '15px', color: '#324E6A' }}>entries </span>
          </div>
          <span style={{ marginLeft: 'auto', marginRight: '23px', color: '#324E6A' }}>Search: </span>
          <input type="text" style={{ backgroundColor: '#D9D9D9', border: 'none', outline: 'none', borderRadius: '5px', padding: '5px' }} />
        </div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={{ ...thStyle, ...columnStyles.patient }}>Patient</th>
              <th style={{ ...thStyle, ...columnStyles.healthofficer }}>Healthofficer</th>
              <th style={{ ...thStyle, ...columnStyles.complaint }}>complaint</th>
              <th style={{ ...thStyle, ...columnStyles.appointmentTime }}>Appointment Time</th>
              <th style={{ ...thStyle, ...columnStyles.status }}>Status</th>
              <th style={{ ...thStyle, ...columnStyles.action, ...lastColumnStyle }}>Prescription</th>
            </tr>
          </thead>
          <tbody style={{ overflowX: 'auto' }}>
            {appointmentHistory.map((data, index) => (
              <tr key={index} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.patient }}>{data.patientName}</td>
                <td style={{ ...tdStyle, ...columnStyles.healthofficer }}>{data.healthOfficer}</td>
                <td style={{ ...tdStyle, ...columnStyles.complaint }}>{data.complaint}</td>
                <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}>{data.localDate.join('/')}</td>
                <td style={{ ...tdStyle, ...columnStyles.status }}>{data.status ? 'Confirmed' : 'Pending'}</td>
                <td style={{ ...tdStyle, ...columnStyles.action, ...lastColumnStyle }} onClick={() => handlePrescriptionClick(data)}>View</td>
              </tr>
            ))}
            {[...Array(displayedRows - totalRows)].map((_, index) => (
              <tr key={index + totalRows} style={{ height: rowHeight, backgroundColor: index % 2 === 0 ? '#D9D9D9' : '#E6E6E6' }}>
                <td style={{ ...tdStyle, ...columnStyles.patient }}></td>
                <td style={{ ...tdStyle, ...columnStyles.healthofficer }}></td>
                <td style={{ ...tdStyle, ...columnStyles.complaint }}></td>
                <td style={{ ...tdStyle, ...columnStyles.appointmentTime }}></td>
                <td style={{ ...tdStyle, ...columnStyles.status }}></td>
                <td style={{ ...tdStyle, ...columnStyles.action, ...lastColumnStyle }}></td>
              </tr>
            ))}
          </tbody>
        </table>
 {showPopup && (
  <div style={popupOverlayStyle}>
    <div style={{ ...popupStyle, maxWidth: '800px', maxHeight: '80vh', overflow: 'auto' }}>
      <button onClick={closePopup} style={closeButtonStyle}>×</button>

      {/* Flex container to align Doctor Info on the left and Patient Info on the right */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
  
        {/* Patient Information */}
        <div style={{ flex: '1', textAlign: 'left', marginRight: '20px' }}>
          <div><strong>ID:</strong> {selectedData.patientId} - {selectedData.patientName} ({selectedData.gender} / {selectedData.age} Y)</div>
          <div><strong>Mob. No.:</strong> {selectedData.mobileNumber}</div>
          <div><strong>Address:</strong> {selectedData.patientAddress}</div>
          <div><strong>Weight:</strong> {selectedData.weight} Kg, <strong>Height:</strong> {selectedData.height} Cm</div>
          <div><strong>Blood Group:</strong> {selectedData.bloodGroup}, <strong>BP:</strong> {selectedData.diastolicBP} mmHg</div>
        </div>

        {/* Logo */}
        <div style={{ flex: '0', textAlign: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '80px', margin: '0 20px' }} />
        </div>

        {/* Header with Doctor and Hospital Info */}
        <div style={{ flex: '1', textAlign: 'right' }}>
          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>Dr. {selectedData.doctorName}</div>
          <div style={{ fontSize: '14px' }}>{selectedData.hospitalName}</div>
          <div style={{ fontSize: '12px' }}>{selectedData.hospitalAddress}</div>
          <div style={{ fontSize: '12px', marginTop: '5px' }}>Ph: {selectedData.hospitalMobileNumber}</div>
          <div style={{ fontSize: '12px' }}>Timing: {selectedData.hospitalTiming}</div>
          <div style={{ fontSize: '12px' }}>Closed: {selectedData.hospitalClosedDays}</div>
        </div>

      </div>

      {/* Clinical Information */}
      {selectedData.prescriptions && selectedData.prescriptions.length > 0 ? (
        <>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', gap: '20px' }}>
  <div style={{ textAlign: 'left', display: 'grid', gridTemplateRows: 'auto auto', rowGap: '10px' }}>
    <div>
      <strong>Chief Complaints:</strong>
      <ul>
        <li>{selectedData.prescriptions[0].chiefComplaints}</li>
      </ul>
    </div>
    <div>
      <strong>Diagnosis:</strong>
      <ul>
        <li>{selectedData.prescriptions[0].provisionalDiagnosis}</li>
      </ul>
    </div>
  </div>
  <div style={{ textAlign: 'left', display: 'grid', gridTemplateRows: 'auto auto', rowGap: '10px' }}>
    <div>
      <strong>Clinical Findings:</strong>
      <ul>
        <li>{selectedData.prescriptions[0].clinicalFinding}</li>
      </ul>
    </div>
    <div>
      <strong>Final Diagnosis:</strong>
      <ul>
        <li>{selectedData.prescriptions[0].finalDiagnosis}</li>
      </ul>
    </div>
  </div>
</div>


          {/* Medication List */}
          <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
            <div style={{ marginBottom: '15px' }}>
              <strong>Medications:</strong>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid black', padding: '5px' }}>#</th>
                    <th style={{ border: '1px solid black', padding: '5px' }}>Medicine Name</th>
                    <th style={{ border: '1px solid black', padding: '5px' }}>Dosage</th>
                    <th style={{ border: '1px solid black', padding: '5px' }}>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedData.prescriptions[0].medications.map((med, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{index + 1}</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>{med.medication}</td>
                      <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{med.dosage}</td>
                      <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{med.days} Days</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Laboratory List */}
          <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
            <div style={{ marginBottom: '15px' }}>
              <strong>Laboratory Tests:</strong>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid black', padding: '5px' }}>#</th>
                    <th style={{ border: '1px solid black', padding: '5px' }}>Test Name</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedData.prescriptions[0].laboratory.map((lab, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: '5px', textAlign: 'center' }}>{index + 1}</td>
                      <td style={{ border: '1px solid black', padding: '5px' }}>{lab.laboratory}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Advice and Follow-up */}
          <div style={{ borderTop: '1px solid black', marginTop: '10px', paddingTop: '10px', textAlign: 'left' }}>
            <strong>Advice:</strong>
            <ul>
              <li>{selectedData.prescriptions[0].advice}</li>
            </ul>
            <strong>Follow-up:</strong> {selectedData.prescriptions[0].followUpDate}
          </div>

          {/* Download Button */}
          <button onClick={() => generatePDF(selectedData)} style={{ marginTop: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
            Download PDF
          </button>
        </>
      ) : (
        <div style={{ color: 'red' }}>No prescription available.</div>
      )}
    </div>
  </div>
)}




      </div>
     
    </div>
  );
}

export default AppointmentHistory;
