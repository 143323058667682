import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import Header from './Header'; 
import { BASE_URL } from '../config/apiConfig';
const SidebarStyled = styled.div`
  position: fixed; /* Fixes the sidebar in place */
  top: 0;
  left: 0;
  height: 100vh; /* Full height of the viewport */
  width: 250px; /* Adjust width as needed */
  background-color: #f4f4f4;
  overflow-y: auto; /* Scroll if content overflows */
  z-index: 1000; /* Ensure it stays on top of other content */
 
`;

const MainContent = styled.div`
  margin-left: 250px; /* Space for sidebar */
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #f4f4f4;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const PatientsList = () => {
  const [patients, setPatients] = useState([]);
 const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      console.error('No JWT token available for authentication');
      return;
    }

  useEffect(() => {
    // Fetch patients data from API
    const fetchPatients = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/patients`, {
          headers: {
          'Auth': `Bearer ${jwtToken}`
          }
        });
        setPatients(response.data);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };

    fetchPatients();
  }, []);

  return (
    <div className="patients-list">
      <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <MainContent>
        <h1>Patients List</h1>
        <Table>
          <thead>
            <tr>
              <Th>Patient ID</Th>
              <Th>Name</Th>
              <Th>Doctor</Th>
              <Th>Age</Th>
              <Th>Gender</Th>
              <Th>Status</Th>
              <Th>Health Officer</Th>
              <Th>Aadhaar Card Number</Th>
              <Th>Appointment Time</Th>
            </tr>
          </thead>
          <tbody>
            {patients.map((patient, index) => (
              <Tr key={index}>
                <Td>{patient.patientId}</Td>
                <Td>{patient.patientName}</Td>
                <Td>{patient.doctor}</Td>
                <Td>{patient.age}</Td>
                <Td>{patient.gender}</Td>
                <Td>{patient.status ? 'Active' : 'Inactive'}</Td>
                <Td>{patient.healthOfficer}</Td>
                <Td>{patient.aadhaarCardNumber}</Td>
                <Td>{new Date(patient.appointmentTime).toLocaleString()}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </MainContent>
    </div>
  );
};

export default PatientsList;
