
import React from "react";
import Footer from './Footer';
import Header from "./Header";
import Changepassword from "./changepassword";

function Appointment() {
  return (
    <div>
      <Header />
      <Changepassword />;
      <Footer />
    </div>
  );
}

export default Appointment;
