
// // // // import React, { useState, useEffect } from 'react';
// // // // import axios from 'axios';

// // // // import { BASE_URL } from '../config/apiConfig';
// // // // import Sidebar from './Sidebar';
// // // // import styled from 'styled-components';
// // // // import Header from './Header'; 

// // // // const SidebarStyled = styled.div`
// // // //   position: fixed; /* Fixes the sidebar in place */
// // // //   top: 0;
// // // //   left: 0;
// // // //   height: 100vh; /* Full height of the viewport */
// // // //   width: 250px; /* Adjust width as needed */
// // // //   background-color: #f4f4f4;
// // // //   overflow-y: auto; /* Scroll if content overflows */
// // // //   z-index: 1000; /* Ensure it stays on top of other content */
 
// // // // `;
// // // // const Container = styled.div`
// // // //   background: linear-gradient(#7CB2D0, #D0E0E5);
// // // //   min-height: 100vh;
// // // //   display: flex;
// // // //   flex-direction: column;
// // // //   justify-content: center;
// // // //   align-items: center;
// // // // `;

// // // // const Heading = styled.h2`
// // // //   margin-top: 20px;
// // // //   color: #333;
// // // //   font-size: 29px;
// // // //   text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.5);
// // // //   margin-bottom: 20px;
// // // // `;

// // // // const RegisterFormContainer = styled.form`
// // // //   width: 90%;
// // // //   max-width: 400px;
// // // //   padding: 40px;
// // // //   border-radius: 8px;
// // // //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// // // //   background: none;
// // // //   border: 2px solid #007bff;
// // // // `;

// // // // const Input = styled.input`
// // // //   width: 100%;
// // // //   padding: 10px;
// // // //   border-radius: 5px;
// // // //   background: rgba(255, 255, 255, 0.3);
// // // //   font-size: 16px;
// // // //   margin-bottom: 10px;
// // // //   color: #000;
// // // //   border: 1px solid #007bff;
// // // //   ::placeholder {
// // // //     color: #007bff;
// // // //   }
// // // // `;

// // // // const Select = styled.select`
// // // //   width: 100%;
// // // //   padding: 10px;
// // // //   border-radius: 5px;
// // // //   background: rgba(255, 255, 255, 0.3);
// // // //   font-size: 16px;
// // // //   margin-bottom: 10px;
// // // //   color: #000;
// // // //   border: 1px solid #007bff;
// // // // `;

// // // // const Button = styled.button`
// // // //   width: 100%;
// // // //   padding: 12px;
// // // //   margin-top: 20px;
// // // //   border-radius: 5px;
// // // //   border: none;
// // // //   background: #007bff;
// // // //   color: #fff;
// // // //   cursor: pointer;
// // // //   font-size: 16px;
// // // // `;

// // // // const LinkText = styled.div`
// // // //   margin-top: 20px;
// // // //   font-size: 14px;
// // // // `;

// // // // const Link = styled.a`
// // // //   color: #007bff;
// // // //   text-decoration: none;
// // // // `;

// // // // const MessageContainer = styled.div`
// // // //   position: fixed;
// // // //   top: 50%;
// // // //   left: 50%;
// // // //   transform: translate(-50%, -50%);
// // // //   padding: 20px;
// // // //   background-color: ${(props) => (props.success ? '#4CAF50' : '#F44336')};
// // // //   color: #fff;
// // // //   border-radius: 5px;
// // // //   text-align: center;
// // // //   display: ${(props) => (props.message ? 'block' : 'none')};
// // // //   z-index: 1000;
// // // // `;

// // // // const RegisterDocHs = () => {
// // // //   const [formData, setFormData] = useState({
// // // //     name: '',
// // // //     email: '',
// // // //     mobileNo: '',
// // // //     specialist: '',
// // // //     password: '',
// // // //     type: 'Doctor', // Default value for type
// // // //   });

// // // //   const [successMessage, setSuccessMessage] = useState('');
// // // //   const [errorMessage, setErrorMessage] = useState('');

// // // //   const handleChange = (e) => {
// // // //     const { name, value } = e.target;
// // // //     setFormData({
// // // //       ...formData,
// // // //       [name]: value,
// // // //     });
// // // //   };

// // // //   const handleSubmit = async (e) => {
// // // //     e.preventDefault();

// // // //     const jwtToken = localStorage.getItem('jwtToken');

// // // //     if (!jwtToken) {
// // // //       console.error('No JWT token available for authentication');
// // // //       return;
// // // //     }

// // // //     try {
// // // //       const response = await axios.post(`${BASE_URL}/registerDocHs/register`, formData, {
// // // //         headers: {
// // // //           'Content-Type': 'application/json',
// // // //           'Auth': `Bearer ${jwtToken}`
// // // //         }
// // // //       });

// // // //       // Clear form data after successful registration
// // // //       setFormData({
// // // //         name: '',
// // // //         email: '',
// // // //         mobileNo: '',
// // // //         specialist: '',
// // // //         password: '',
// // // //         type: 'Doctor',
// // // //       });

// // // //       // Display success message
// // // //       setSuccessMessage('Registration successful!');

// // // //     } catch (error) {
// // // //       console.error('There was an error registering the user!', error);
// // // //       // Display error message
// // // //       setErrorMessage('Registration failed. Please try again.');
// // // //     }
// // // //   };

// // // //   useEffect(() => {
// // // //     const timer = setTimeout(() => {
// // // //       setSuccessMessage('');
// // // //       setErrorMessage('');
// // // //     }, 3000); // Adjust the timeout as needed

// // // //     return () => clearTimeout(timer);
// // // //   }, [successMessage, errorMessage]);

// // // //   return (
// // // // <>
// // // //     <Header />
// // // //       <SidebarStyled>
// // // //         <Sidebar />
// // // //       </SidebarStyled>
// // // //     <Container>
// // // //       <Heading>Register Health Officer/Doctor</Heading>
// // // //       <RegisterFormContainer onSubmit={handleSubmit}>
// // // //         <Select id="type" name="type" value={formData.type} onChange={handleChange}>
// // // //           <option value="Health Officer">Health Officer</option>
// // // //           <option value="Doctor">Doctor</option>
          
// // // //         </Select>
// // // //         <Input
// // // //           type="text"
// // // //           name="name"
// // // //           value={formData.name}
// // // //           onChange={handleChange}
// // // //           placeholder="Name"
// // // //           required
// // // //         />
// // // //         <Input
// // // //           type="email"
// // // //           name="email"
// // // //           value={formData.email}
// // // //           onChange={handleChange}
// // // //           placeholder="Email"
// // // //           required
// // // //         />
// // // //         <Input
// // // //           type="text"
// // // //           name="mobileNo"
// // // //           value={formData.mobileNo}
// // // //           onChange={handleChange}
// // // //           placeholder="Mobile No"
// // // //           required
// // // //         />
// // // //         <Input
// // // //           type="text"
// // // //           name="specialist"
// // // //           value={formData.specialist}
// // // //           onChange={handleChange}
// // // //           placeholder="Specialist"
// // // //           required
// // // //         />
// // // //         <Input
// // // //           type="password"
// // // //           name="password"
// // // //           value={formData.password}
// // // //           onChange={handleChange}
// // // //           placeholder="Password"
// // // //           required
// // // //         />

// // // //         <Button type="submit">Register</Button>
// // // //         <LinkText>
// // // //           Already have an account? <Link href="/login">Login</Link>
// // // //         </LinkText>
// // // //       </RegisterFormContainer>

// // // //       <MessageContainer success={successMessage} message={successMessage}>
// // // //         {successMessage}
// // // //       </MessageContainer>
// // // //       <MessageContainer success={false} message={errorMessage}>
// // // //         {errorMessage}
// // // //       </MessageContainer>
// // // //     </Container>
// // // //     </>
// // // //   );
// // // // };


// // // // export default RegisterDocHs;




// // // import React, { useState, useEffect } from 'react';
// // // import axios from 'axios';
// // // import { BASE_URL } from '../config/apiConfig';
// // // import Sidebar from './Sidebar';
// // // import styled from 'styled-components';
// // // import Header from './Header';

// // // const SidebarStyled = styled.div`
// // //   position: fixed; /* Fixes the sidebar in place */
// // //   top: 0;
// // //   left: 0;
// // //   height: 100vh; /* Full height of the viewport */
// // //   width: 250px; /* Adjust width as needed */
// // //   background-color: #f4f4f4;
// // //   overflow-y: auto; /* Scroll if content overflows */
// // //   z-index: 1000; /* Ensure it stays on top of other content */
// // // `;

// // // const Container = styled.div`
// // //   background: linear-gradient(#7CB2D0, #D0E0E5);
// // //   min-height: 100vh;
// // //   display: flex;
// // //   flex-direction: column;
// // //   justify-content: center;
// // //   align-items: center;
// // // `;

// // // const Heading = styled.h2`
// // //   margin-top: 20px;
// // //   color: #333;
// // //   font-size: 29px;
// // //   text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.5);
// // //   margin-bottom: 20px;
// // // `;

// // // const RegisterFormContainer = styled.form`
// // //   width: 90%;
// // //   max-width: 400px;
// // //   padding: 40px;
// // //   border-radius: 8px;
// // //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// // //   background: none;
// // //   border: 2px solid #007bff;
// // // `;

// // // const Input = styled.input`
// // //   width: 100%;
// // //   padding: 10px;
// // //   border-radius: 5px;
// // //   background: rgba(255, 255, 255, 0.3);
// // //   font-size: 16px;
// // //   margin-bottom: 10px;
// // //   color: #000;
// // //   border: 1px solid #007bff;
// // //   ::placeholder {
// // //     color: #007bff;
// // //   }
// // // `;

// // // const Select = styled.select`
// // //   width: 100%;
// // //   padding: 10px;
// // //   border-radius: 5px;
// // //   background: rgba(255, 255, 255, 0.3);
// // //   font-size: 16px;
// // //   margin-bottom: 10px;
// // //   color: #000;
// // //   border: 1px solid #007bff;
// // // `;

// // // const Button = styled.button`
// // //   width: 100%;
// // //   padding: 12px;
// // //   margin-top: 20px;
// // //   border-radius: 5px;
// // //   border: none;
// // //   background: #007bff;
// // //   color: #fff;
// // //   cursor: pointer;
// // //   font-size: 16px;
// // // `;

// // // const LinkText = styled.div`
// // //   margin-top: 20px;
// // //   font-size: 14px;
// // // `;

// // // const Link = styled.a`
// // //   color: #007bff;
// // //   text-decoration: none;
// // // `;

// // // const MessageContainer = styled.div`
// // //   position: fixed;
// // //   top: 50%;
// // //   left: 50%;
// // //   transform: translate(-50%, -50%);
// // //   padding: 20px;
// // //   background-color: ${(props) => (props.success ? '#4CAF50' : '#F44336')};
// // //   color: #fff;
// // //   border-radius: 5px;
// // //   text-align: center;
// // //   display: ${(props) => (props.message ? 'block' : 'none')};
// // //   z-index: 1000;
// // // `;

// // // const RegisterDocHs = () => {
// // //   const [formData, setFormData] = useState({
// // //     name: '',
// // //     email: '',
// // //     mobileNo: '',
// // //     specialist: '',
// // //     password: '',
// // //     type: 'Doctor', // Default value for type
// // //   });

// // //   const [successMessage, setSuccessMessage] = useState('');
// // //   const [errorMessage, setErrorMessage] = useState('');

// // //   const handleChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setFormData({
// // //       ...formData,
// // //       [name]: value,
// // //     });
// // //   };

// // //   const handleSubmit = async (e) => {
// // //     e.preventDefault();

// // //     const jwtToken = localStorage.getItem('jwtToken');

// // //     if (!jwtToken) {
// // //       console.error('No JWT token available for authentication');
// // //       return;
// // //     }

// // //     try {
// // //       const response = await axios.post(`${BASE_URL}/registerDocHs/register`, formData, {
// // //         headers: {
// // //           'Content-Type': 'application/json',
// // //           'Auth': `Bearer ${jwtToken}`
// // //         }
// // //       });

// // //       // Clear form data after successful registration
// // //       setFormData({
// // //         name: '',
// // //         email: '',
// // //         mobileNo: '',
// // //         specialist: '',
// // //         password: '',
// // //         type: 'Doctor',
// // //       });

// // //       // Display success message
// // //       setSuccessMessage('Registration successful!');
// // //       setErrorMessage(''); // Clear any previous error messages

// // //     } catch (error) {
// // //       console.error('There was an error registering the user!', error);
      
// // //       // Handle error responses
// // //       if (error.response && error.response.data && error.response.data.message) {
// // //         // Display detailed error message from the backend
// // //         setErrorMessage(error.response.data.message);
// // //       } else {
// // //         // Fallback error message
// // //         setErrorMessage('Registration failed. Please try again.');
// // //       }

// // //       setSuccessMessage(''); // Clear any previous success messages
// // //     }
// // //   };

// // //   useEffect(() => {
// // //     const timer = setTimeout(() => {
// // //       setSuccessMessage('');
// // //       setErrorMessage('');
// // //     }, 5000); // Adjust the timeout as needed

// // //     return () => clearTimeout(timer);
// // //   }, [successMessage, errorMessage]);

// // //   return (
// // //     <>
// // //       <Header />
// // //       <SidebarStyled>
// // //         <Sidebar />
// // //       </SidebarStyled>
// // //       <Container>
// // //         <Heading>Register Health Officer/Doctor</Heading>
// // //         <RegisterFormContainer onSubmit={handleSubmit}>
// // //           <Select id="type" name="type" value={formData.type} onChange={handleChange}>
// // //             <option value="Health Officer">Health Officer</option>
// // //             <option value="Doctor">Doctor</option>
// // //           </Select>
// // //           <Input
// // //             type="text"
// // //             name="name"
// // //             value={formData.name}
// // //             onChange={handleChange}
// // //             placeholder="Name"
// // //             required
// // //           />
// // //           <Input
// // //             type="email"
// // //             name="email"
// // //             value={formData.email}
// // //             onChange={handleChange}
// // //             placeholder="Email"
// // //             required
// // //           />
// // //           <Input
// // //             type="text"
// // //             name="mobileNo"
// // //             value={formData.mobileNo}
// // //             onChange={handleChange}
// // //             placeholder="Mobile No"
// // //             required
// // //           />
// // //           <Input
// // //             type="text"
// // //             name="specialist"
// // //             value={formData.specialist}
// // //             onChange={handleChange}
// // //             placeholder="Specialist"
// // //             required
// // //           />
// // //           <Input
// // //             type="password"
// // //             name="password"
// // //             value={formData.password}
// // //             onChange={handleChange}
// // //             placeholder="Password"
// // //             required
// // //           />
// // //           <Button type="submit">Register</Button>
// // //           <LinkText>
// // //             Already have an account? <Link href="/login">Login</Link>
// // //           </LinkText>
// // //         </RegisterFormContainer>

// // //         <MessageContainer success={!!successMessage} message={successMessage}>
// // //           {successMessage}
// // //         </MessageContainer>
// // //         <MessageContainer success={false} message={errorMessage}>
// // //           {errorMessage}
// // //         </MessageContainer>
// // //       </Container>
// // //     </>
// // //   );
// // // };

// // // export default RegisterDocHs;
// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import { BASE_URL } from '../config/apiConfig';
// // import Sidebar from './Sidebar';
// // import styled from 'styled-components';
// // import Header from './Header';

// // const SidebarStyled = styled.div`
// //   position: fixed;
// //   top: 0;
// //   left: 0;
// //   height: 100vh;
// //   width: 250px;
// //   background-color: #f4f4f4;
// //   overflow-y: auto;
// //   z-index: 1000;
// // `;

// // const Container = styled.div`
// //   background: linear-gradient(#7cb2d0, #d0e0e5);
// //   min-height: 100vh;
// //   display: flex;
// //   flex-direction: column;
// //   justify-content: center;
// //   align-items: center;
// // `;

// // const Heading = styled.h2`
// //   margin-top: 20px;
// //   color: #333;
// //   font-size: 29px;
// //   text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.5);
// //   margin-bottom: 20px;
// // `;

// // const RegisterFormContainer = styled.form`
// //   width: 90%;
// //   max-width: 400px;
// //   padding: 40px;
// //   border-radius: 8px;
// //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// //   background: none;
// //   border: 2px solid #007bff;
// // `;

// // const Input = styled.input`
// //   width: 100%;
// //   padding: 10px;
// //   border-radius: 5px;
// //   background: rgba(255, 255, 255, 0.3);
// //   font-size: 16px;
// //   margin-bottom: 10px;
// //   color: #000;
// //   border: 1px solid #007bff;
// //   ::placeholder {
// //     color: #007bff;
// //   }
// // `;

// // const Select = styled.select`
// //   width: 100%;
// //   padding: 10px;
// //   border-radius: 5px;
// //   background: rgba(255, 255, 255, 0.3);
// //   font-size: 16px;
// //   margin-bottom: 10px;
// //   color: #000;
// //   border: 1px solid #007bff;
// // `;

// // const Button = styled.button`
// //   width: 100%;
// //   padding: 12px;
// //   margin-top: 20px;
// //   border-radius: 5px;
// //   border: none;
// //   background: #007bff;
// //   color: #fff;
// //   cursor: pointer;
// //   font-size: 16px;
// // `;

// // const LinkText = styled.div`
// //   margin-top: 20px;
// //   font-size: 14px;
// // `;

// // const Link = styled.a`
// //   color: #007bff;
// //   text-decoration: none;
// // `;

// // const MessageContainer = styled.div`
// //   position: fixed;
// //   top: 50%;
// //   left: 50%;
// //   transform: translate(-50%, -50%);
// //   padding: 20px;
// //   background-color: ${(props) => (props.success ? '#4caf50' : '#f44336')};
// //   color: #fff;
// //   border-radius: 5px;
// //   text-align: center;
// //   display: ${(props) => (props.message ? 'block' : 'none')};
// //   z-index: 1000;
// // `;

// // const RegisterDocHs = () => {
// //   const [formData, setFormData] = useState({
// //     name: '',
// //     email: '',
// //     mobileNo: '',
// //     specialist: '',
// //     password: '',
// //     type: 'Doctor', // Default value for type
// //   });

// //   const [successMessage, setSuccessMessage] = useState('');
// //   const [errorMessage, setErrorMessage] = useState('');

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({
// //       ...formData,
// //       [name]: value,
// //     });
// //   };

// //  const handleSubmit = async (e) => {
// //   e.preventDefault();

// //   const jwtToken = localStorage.getItem('jwtToken');

// //   if (!jwtToken) {
// //     console.error('No JWT token available for authentication');
// //     setErrorMessage('Authentication token missing. Please login again.');
// //     return;
// //   }

// //   try {
// //     const response = await axios.post(`${BASE_URL}/registerDocHs/register`, formData, {
// //       headers: {
// //         'Content-Type': 'application/json',
// //         'Auth': `Bearer ${jwtToken}`
// //       }
// //     });

// //     // Clear form data after successful registration
// //     setFormData({
// //       name: '',
// //       email: '',
// //       mobileNo: '',
// //       specialist: '',
// //       password: '',
// //       type: 'Doctor',
// //     });

// //     // Display success message
// //     setSuccessMessage('Registration successful!');
// //     setErrorMessage(''); // Clear any previous error messages

// //   } catch (error) {
// //     console.error('There was an error registering the user!', error);

// //     // Check the structure of the error response
// //     if (error.response && error.response.data && error.response.data.message) {
// //       // Display detailed error message from the backend
// //       setErrorMessage(error.response.data.message);
// //     } else if (error.response && error.response.data) {
// //       // Display generic error if no message is available
// //       setErrorMessage('An error occurred: ' + error.response.data);
// //     } else {
// //       // Fallback error message for network or other unknown errors
// //       setErrorMessage('Registration failed. Please try again.');
// //     }

// //     setSuccessMessage(''); // Clear any previous success messages
// //   }
// // };


// //   useEffect(() => {
// //     const timer = setTimeout(() => {
// //       setSuccessMessage('');
// //       setErrorMessage('');
// //     }, 5000); // Adjust the timeout as needed

// //     return () => clearTimeout(timer);
// //   }, [successMessage, errorMessage]);

// //   return (
// //     <>
// //       <Header />
// //       <SidebarStyled>
// //         <Sidebar />
// //       </SidebarStyled>
// //       <Container>
// //         <Heading>Register Health Officer/Doctor</Heading>
// //         <RegisterFormContainer onSubmit={handleSubmit}>
// //           <Select id="type" name="type" value={formData.type} onChange={handleChange}>
// //             <option value="Health Officer">Health Officer</option>
// //             <option value="Doctor">Doctor</option>
// //           </Select>
// //           <Input
// //             type="text"
// //             name="name"
// //             value={formData.name}
// //             onChange={handleChange}
// //             placeholder="Name"
// //             required
// //           />
// //           <Input
// //             type="email"
// //             name="email"
// //             value={formData.email}
// //             onChange={handleChange}
// //             placeholder="Email"
// //             required
// //           />
// //           <Input
// //             type="text"
// //             name="mobileNo"
// //             value={formData.mobileNo}
// //             onChange={handleChange}
// //             placeholder="Mobile No"
// //             required
// //           />
// //           <Input
// //             type="text"
// //             name="specialist"
// //             value={formData.specialist}
// //             onChange={handleChange}
// //             placeholder="Specialist"
// //             required
// //           />
// //           <Input
// //             type="password"
// //             name="password"
// //             value={formData.password}
// //             onChange={handleChange}
// //             placeholder="Password"
// //             required
// //           />
// //           <Button type="submit">Register</Button>
// //           <LinkText>
// //             Already have an account? <Link href="/login">Login</Link>
// //           </LinkText>
// //         </RegisterFormContainer>

// //         <MessageContainer success={!!successMessage} message={successMessage}>
// //           {successMessage}
// //         </MessageContainer>
// //         <MessageContainer success={false} message={errorMessage}>
// //           {errorMessage}
// //         </MessageContainer>
// //       </Container>
// //     </>
// //   );
// // };

// // export default RegisterDocHs;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BASE_URL } from '../config/apiConfig';
// import Sidebar from './Sidebar';
// import styled from 'styled-components';
// import Header from './Header';

// const SidebarStyled = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   height: 100vh;
//   width: 250px;
//   background-color: #f4f4f4;
//   overflow-y: auto;
//   z-index: 1000;
// `;

// const Container = styled.div`
//   background: linear-gradient(#7cb2d0, #d0e0e5);
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// const Heading = styled.h2`
//   margin-top: 20px;
//   color: #333;
//   font-size: 29px;
//   text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.5);
//   margin-bottom: 20px;
// `;

// const RegisterFormContainer = styled.form`
//   width: 90%;
//   max-width: 400px;
//   padding: 40px;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   background: none;
//   border: 2px solid #007bff;
// `;

// const Input = styled.input`
//   width: 100%;
//   padding: 10px;
//   border-radius: 5px;
//   background: rgba(255, 255, 255, 0.3);
//   font-size: 16px;
//   margin-bottom: 10px;
//   color: #000;
//   border: 1px solid #007bff;
//   ::placeholder {
//     color: #007bff;
//   }
// `;

// const Select = styled.select`
//   width: 100%;
//   padding: 10px;
//   border-radius: 5px;
//   background: rgba(255, 255, 255, 0.3);
//   font-size: 16px;
//   margin-bottom: 10px;
//   color: #000;
//   border: 1px solid #007bff;
// `;

// const Button = styled.button`
//   width: 100%;
//   padding: 12px;
//   margin-top: 20px;
//   border-radius: 5px;
//   border: none;
//   background: #007bff;
//   color: #fff;
//   cursor: pointer;
//   font-size: 16px;
// `;

// const LinkText = styled.div`
//   margin-top: 20px;
//   font-size: 14px;
// `;

// const Link = styled.a`
//   color: #007bff;
//   text-decoration: none;
// `;

// const MessageContainer = styled.div`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   padding: 20px;
//   background-color: ${(props) => (props.success ? '#4caf50' : '#f44336')};
//   color: #fff;
//   border-radius: 5px;
//   text-align: center;
//   display: ${(props) => (props.message ? 'block' : 'none')};
//   z-index: 1000;
// `;

// const RegisterDocHs = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     mobileNo: '',
//     specialist: '',
//     password: '',
//     type: 'Doctor', // Default value for type
//   });

//   const [successMessage, setSuccessMessage] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');
//   const [formErrors, setFormErrors] = useState({});

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   const validatePassword = (password) => {
//     // Regular expression for a password that must contain:
//     // At least one uppercase letter, one lowercase letter, one number, and one special character (including dot)
//     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
//     return passwordRegex.test(password);
//   };

//   const validateMobileNo = (mobileNo) => {
//     // Assuming mobile number should be 10 digits long
//     const mobileNoRegex = /^[0-9]{10}$/;
//     return mobileNoRegex.test(mobileNo);
//   };

//   const validateForm = () => {
//     const errors = {};

//     if (!formData.name) {
//       errors.name = 'Name is required.';
//     }

//     if (!validateEmail(formData.email)) {
//       errors.email = 'Please enter a valid email address.';
//     }

//     if (!validateMobileNo(formData.mobileNo)) {
//       errors.mobileNo = 'Please enter a valid 10-digit mobile number.';
//     }

//     if (!validatePassword(formData.password)) {
//       errors.password = 'Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one number, and one special character (including dot).';
//     }

//     setFormErrors(errors);

//     return Object.keys(errors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!validateForm()) {
//       return;
//     }

//     const jwtToken = localStorage.getItem('jwtToken');

//     if (!jwtToken) {
//       console.error('No JWT token available for authentication');
//       setErrorMessage('Authentication token missing. Please login again.');
//       return;
//     }

//     try {
//       const response = await axios.post(`${BASE_URL}/registerDocHs/register`, formData, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Auth': `Bearer ${jwtToken}`
//         }
//       });

//       // Clear form data after successful registration
//       setFormData({
//         name: '',
//         email: '',
//         mobileNo: '',
//         specialist: '',
//         password: '',
//         type: 'Doctor',
//       });

//       // Display success message
//       setSuccessMessage('Registration successful!');
//       setErrorMessage(''); // Clear any previous error messages

//     } catch (error) {
//       console.error('There was an error registering the user!', error);

//       // Check the structure of the error response
//       if (error.response && error.response.data && error.response.data.message) {
//         // Display detailed error message from the backend
//         setErrorMessage(error.response.data.message);
//       } else if (error.response && error.response.data) {
//         // Display generic error if no message is available
//         setErrorMessage('An error occurred: ' + error.response.data);
//       } else {
//         // Fallback error message for network or other unknown errors
//         setErrorMessage('Registration failed. Please try again.');
//       }

//       setSuccessMessage(''); // Clear any previous success messages
//     }
//   };

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setSuccessMessage('');
//       setErrorMessage('');
//     }, 5000); // Adjust the timeout as needed

//     return () => clearTimeout(timer);
//   }, [successMessage, errorMessage]);

//   return (
//     <>
//       <Header />
//       <SidebarStyled>
//         <Sidebar />
//       </SidebarStyled>
//       <Container>
//         <Heading>Register Health Officer/Doctor</Heading>
//         <RegisterFormContainer onSubmit={handleSubmit}>
//           <Select id="type" name="type" value={formData.type} onChange={handleChange}>
//             <option value="Health Officer">Health Officer</option>
//             <option value="Doctor">Doctor</option>
//           </Select>
//           <Input
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             placeholder="Name"
//             required
//           />
//           {formErrors.name && <p>{formErrors.name}</p>}
//           <Input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             placeholder="Email"
//             required
//           />
//           {formErrors.email && <p>{formErrors.email}</p>}
//           <Input
//             type="text"
//             name="mobileNo"
//             value={formData.mobileNo}
//             onChange={handleChange}
//             placeholder="Mobile No"
//             required
//           />
//           {formErrors.mobileNo && <p>{formErrors.mobileNo}</p>}
//           <Input
//             type="text"
//             name="specialist"
//             value={formData.specialist}
//             onChange={handleChange}
//             placeholder="Specialist"
//             required
//           />
//           <Input
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleChange}
//             placeholder="Password"
//             required
//           />
//           {formErrors.password && <p>{formErrors.password}</p>}
//           <Button type="submit">Register</Button>
//           <LinkText>
//             Already have an account? <Link href="/login">Login</Link>
//           </LinkText>
//         </RegisterFormContainer>

//         <MessageContainer success={!!successMessage} message={successMessage}>
//           {successMessage}
//         </MessageContainer>
//         <MessageContainer success={false} message={errorMessage}>
//           {errorMessage}
//         </MessageContainer>
//       </Container>
//     </>
//   );
// };

// export default RegisterDocHs;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config/apiConfig';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import Header from './Header';

const SidebarStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: #f4f4f4;
  overflow-y: auto;
  z-index: 1000;
`;

const Container = styled.div`
  background: linear-gradient(#7cb2d0, #d0e0e5);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h2`
  margin-top: 20px;
  color: #333;
  font-size: 29px;
  text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.5);
  margin-bottom: 20px;
`;

const RegisterFormContainer = styled.form`
  width: 90%;
  max-width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: none;
  border: 2px solid #007bff;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  font-size: 16px;
  margin-bottom: 10px;
  color: #000;
  border: 1px solid #007bff;
  ::placeholder {
    color: #007bff;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  font-size: 16px;
  margin-bottom: 10px;
  color: #000;
  border: 1px solid #007bff;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  border-radius: 5px;
  border: none;
  background: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
`;

const LinkText = styled.div`
  margin-top: 20px;
  font-size: 14px;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
`;

const MessageContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: ${(props) => (props.success ? '#4caf50' : '#f44336')};
  color: #fff;
  border-radius: 5px;
  text-align: center;
  display: ${(props) => (props.message ? 'block' : 'none')};
  z-index: 1000;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 5px 0;
`;

const RegisterDocHs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNo: '',
    specialist: '',
    password: '',
    type: 'Doctor', // Default value for type
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const validatePassword = (password) => {
  //   // Regular expression for a password that must contain:
  //   // At least one uppercase letter, one lowercase letter, one number, and one special character (including dot)
  //   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
  //   return passwordRegex.test(password);
  // };
const validatePassword = (password) => {
  // Regular expression for a password that must be at least 8 characters long
  const passwordRegex = /^.{8,}$/;
  return passwordRegex.test(password);
};


  const validateMobileNo = (mobileNo) => {
    // Assuming mobile number should be 10 digits long
    const mobileNoRegex = /^[0-9]{10}$/;
    return mobileNoRegex.test(mobileNo);
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = 'Name is required.';
    }

    if (!validateEmail(formData.email)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (!validateMobileNo(formData.mobileNo)) {
      errors.mobileNo = 'Please enter a valid 10-digit mobile number.';
    }

   if (!validatePassword(formData.password)) {
  errors.password = 'Password must be at least 8 characters long.';
}


    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
      console.error('No JWT token available for authentication');
      setErrorMessage('Authentication token missing. Please login again.');
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/registerDocHs/register`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': `Bearer ${jwtToken}`
        }
      });
      

      // Clear form data after successful registration
      setFormData({
        name: '',
        email: '',
        mobileNo: '',
        specialist: '',
        password: '',
        type: 'Doctor',
      });

      // Display success message
      setSuccessMessage('Registration successful!');
      setErrorMessage(''); // Clear any previous error messages

    } catch (error) {
      console.error('There was an error registering the user!', error);

      // Check the structure of the error response
      if (error.response && error.response.data && error.response.data.message) {
        // Display detailed error message from the backend
        setErrorMessage(error.response.data.message);
      } else if (error.response && error.response.data) {
        // Display generic error if no message is available
        setErrorMessage('An error occurred: ' + error.response.data);
      } else {
        // Fallback error message for network or other unknown errors
        setErrorMessage('Registration failed. Please try again.');
      }

      setSuccessMessage(''); // Clear any previous success messages
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessMessage('');
      setErrorMessage('');
    }, 5000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
  }, [successMessage, errorMessage]);

  return (
    <>
      <Header />
      <SidebarStyled>
        <Sidebar />
      </SidebarStyled>
      <Container>
        <Heading>Register Health Officer/Doctor</Heading>
        <RegisterFormContainer onSubmit={handleSubmit}>
          <Select id="type" name="type" value={formData.type} onChange={handleChange}>
            <option value="Health Officer">Health Officer</option>
            <option value="Doctor">Doctor</option>
          </Select>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            required
          />
          {formErrors.name && <ErrorMessage>{formErrors.name}</ErrorMessage>}
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          {formErrors.email && <ErrorMessage>{formErrors.email}</ErrorMessage>}
          <Input
            type="text"
            name="mobileNo"
            value={formData.mobileNo}
            onChange={handleChange}
            placeholder="Mobile No"
            required
          />
          {formErrors.mobileNo && <ErrorMessage>{formErrors.mobileNo}</ErrorMessage>}
          <Input
            type="text"
            name="specialist"
            value={formData.specialist}
            onChange={handleChange}
            placeholder="Specialist"
            required
          />
          <Input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          {formErrors.password && <ErrorMessage>{formErrors.password}</ErrorMessage>}
          <Button type="submit">Register</Button>
          <LinkText>
            Already have an account? <Link href="/login">Login</Link>
          </LinkText>
        </RegisterFormContainer>

        <MessageContainer success={!!successMessage} message={successMessage}>
          {successMessage}
        </MessageContainer>
        <MessageContainer success={false} message={errorMessage}>
          {errorMessage}
        </MessageContainer>
      </Container>
    </>
  );
};

export default RegisterDocHs;
