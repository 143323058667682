

// import React, { useState } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom'; 
// import { BASE_URL } from '../../config/apiConfig';
// import Footer from '../../Footer';
// import Header from "../../Hoheader";
// const Container = styled.div`
//   background: linear-gradient(#7CB2D0, #D0E0E5);
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// const Heading = styled.h1`
//   margin-top: 20px;
//   color: #333;
//   font-size: 29px;
//   text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.5);
//   margin-bottom: 20px;
// `;

// const RegisterFormContainer = styled.form`
//   width: 90%;
//   max-width: 500px;
//   padding: 40px;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   background: none; /* Transparent background */
//   border: 2px solid #007bff; /* Border color changed to blue */
// `;

// const FormGroup = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   margin-bottom: 10px;
//   gap: 5px;
// `;

// const Input = styled.input`
//   flex: 1;
//   padding: 10px;
//   border-radius: 5px;
//   background: rgba(255, 255, 255, 0.3); /* Transparent white */
//   font-size: 16px;
//   color: #000; /* Text color */
// `;

// const Select = styled.select`
//   flex: 1;
//   padding: 10px;
//   border-radius: 5px;
//   background: rgba(255, 255, 255, 0.3); /* Transparent white */
//   font-size: 16px;
//   color: #000; /* Text color */
// `;

// const TextArea = styled.textarea`
//   flex: 1;
//   padding: 10px;
//   border-radius: 5px;
//   background: rgba(255, 255, 255, 0.3); /* Transparent white */
//   font-size: 16px;
//   color: #000; /* Text color */
// `;

// const Button = styled.button`
//   width: 100%;
//   padding: 12px;
//   margin-top: 20px;
//   border-radius: 5px;
//   border: none;
//   background: #007bff;
//   color: #fff;
//   cursor: pointer;
//   font-size: 16px;
// `;

// const LinkText = styled.p`
//   text-align: center;
//   margin-top: 20px;
// `;

// const LoginLink = styled(Link)`
//   color: #007bff;
//   text-decoration: none;
//   margin-left: 5px;
// `;

// const SuccessPopup = styled.div`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background: #fff;
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
// `;

// const PatientRegistrationForm = () => {
//   const [formData, setFormData] = useState({
//     patientName: '',
//     addharNo: '',
//     height: '',
//     weight: '',
//     age: '',
//     gender: '',
//     email: '',
//     mobileNo: '',
//     address: ''
//   });

//   const [showPopup, setShowPopup] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const key = localStorage.getItem("jwtToken");
//     if (!key) {
//       throw new Error("No JWT token found in localStorage");
//     }
//     try {
//       const response = await axios.post(`${BASE_URL}/api/patients/register`, formData, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Auth': `Bearer ${key}`,
//         },
//       });
//       console.log('Registration successful:', response.data);
//       setShowPopup(true);
//       // Reset form fields after successful submission
//       setFormData({
//         patientName: '',
//         addharNo: '',
//         height: '',
//         weight: '',
//         age: '',
//         gender: '',
//         email: '',
//         mobileNo: '',
//         address: ''
//       });
//     } catch (error) {
//       console.error('Error registering patient:', error);
//     }
//   };

//   return (
//     <div>
//       <Header />
  
//     <Container>
//       <Heading>Patient Registration Form</Heading>
//       <RegisterFormContainer onSubmit={handleSubmit}>
//         <FormGroup>
//           <Input type="text" name="patientName" placeholder="Patient Name" value={formData.patientName} onChange={handleChange} required />
//           <Input type="text" name="addharNo" placeholder="Aadhaar Number" value={formData.addharNo} onChange={handleChange} required />
//         </FormGroup>
//         <FormGroup>
//           <Input type="number" name="height" placeholder="Height (in cm)" value={formData.height} onChange={handleChange} required />
//           <Input type="number" name="weight" placeholder="Weight (in kg)" value={formData.weight} onChange={handleChange} required />
//         </FormGroup>
//         <FormGroup>
//           <Input type="number" name="age" placeholder="Age" value={formData.age} onChange={handleChange} required />
//           <Select name="gender" value={formData.gender} onChange={handleChange} required>
//             <option value="">Select Gender</option>
//             <option value="male">Male</option>
//             <option value="female">Female</option>
//             <option value="other">Other</option>
//           </Select>
//         </FormGroup>
//         <FormGroup>
//           <Input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
//           <Input type="text" name="mobileNo" placeholder="Mobile Number" value={formData.mobileNo} onChange={handleChange} required />
//         </FormGroup>
//         <FormGroup>
//           <TextArea name="address" placeholder="Address" value={formData.address} onChange={handleChange} required />
//         </FormGroup>
//         <Button type="submit">Register</Button>
//         <LinkText>
//           Already have an account? <LoginLink to="/login">Login</LoginLink>
//         </LinkText>
//       </RegisterFormContainer>

//       {showPopup && (
//         <SuccessPopup>
//           <p>Registration successful!</p>
//           <Button onClick={() => setShowPopup(false)}>Close</Button>
//         </SuccessPopup>
//       )}
//     </Container>
//     <Footer />
//     </div>
//   );
// };

// export default PatientRegistrationForm;
import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; 
import { BASE_URL } from '../../config/apiConfig';
import Footer from '../../Footer';
import Header from "../../Hoheader";

// Styled Components
const Container = styled.div`
  background: linear-gradient(#7CB2D0, #D0E0E5);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h1`
  margin-top: 20px;
  color: #333;
  font-size: 29px;
  text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.5);
  margin-bottom: 20px;
`;

const RegisterFormContainer = styled.form`
  width: 90%;
  max-width: 500px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: none; /* Transparent background */
  border: 2px solid #007bff; /* Border color changed to blue */
`;

const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 5px;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3); /* Transparent white */
  font-size: 16px;
  color: #000; /* Text color */
`;

const Select = styled.select`
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3); /* Transparent white */
  font-size: 16px;
  color: #000; /* Text color */
`;

const TextArea = styled.textarea`
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3); /* Transparent white */
  font-size: 16px;
  color: #000; /* Text color */
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  border-radius: 5px;
  border: none;
  background: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
`;

const LinkText = styled.p`
  text-align: center;
  margin-top: 20px;
`;

const LoginLink = styled(Link)`
  color: #007bff;
  text-decoration: none;
  margin-left: 5px;
`;

const SuccessPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 5px 0;
`;

const PatientRegistrationForm = () => {
  const [formData, setFormData] = useState({
    patientName: '',
    addharNo: '',
    height: '',
    weight: '',
    age: '',
    gender: '',
    email: '',
    mobileNo: '',
    address: ''
  });

  const [showPopup, setShowPopup] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobileNo = (mobileNo) => {
    // Assuming mobile number should be 10 digits long
    const mobileNoRegex = /^[0-9]{10}$/;
    return mobileNoRegex.test(mobileNo);
  };

  const validateAadhaarNo = (addharNo) => {
    // Aadhaar number should be exactly 12 digits
    const addharNoRegex = /^[0-9]{12}$/;
    return addharNoRegex.test(addharNo);
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.patientName) {
      errors.patientName = 'Patient Name is required.';
    }

    if (!validateAadhaarNo(formData.addharNo)) {
      errors.addharNo = 'Aadhaar Number must be exactly 12 digits.';
    }

    if (!validateEmail(formData.email)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (!validateMobileNo(formData.mobileNo)) {
      errors.mobileNo = 'Mobile Number must be exactly 10 digits.';
    }

    if (!formData.height || formData.height <= 0) {
      errors.height = 'Height is required and must be a positive number.';
    }

    if (!formData.weight || formData.weight <= 0) {
      errors.weight = 'Weight is required and must be a positive number.';
    }

    if (!formData.age || formData.age <= 0) {
      errors.age = 'Age is required and must be a positive number.';
    }

    if (!formData.gender) {
      errors.gender = 'Gender is required.';
    }

    if (!formData.address) {
      errors.address = 'Address is required.';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const key = localStorage.getItem("jwtToken");
    if (!key) {
      console.error("No JWT token found in localStorage");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/api/patients/register`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': `Bearer ${key}`,
        },
      });
      console.log('Registration successful:', response.data);
      setShowPopup(true);
      // Reset form fields after successful submission
      setFormData({
        patientName: '',
        addharNo: '',
        height: '',
        weight: '',
        age: '',
        gender: '',
        email: '',
        mobileNo: '',
        address: ''
      });
    } catch (error) {
      console.error('Error registering patient:', error);
    }
  };

  return (
    <div>
      <Header />
      <Container>
        <Heading>Patient Registration Form</Heading>
        <RegisterFormContainer onSubmit={handleSubmit}>
          <FormGroup>
            <Input type="text" name="patientName" placeholder="Patient Name" value={formData.patientName} onChange={handleChange} required />
            {formErrors.patientName && <ErrorMessage>{formErrors.patientName}</ErrorMessage>}
            <Input type="text" name="addharNo" placeholder="Aadhaar Number" value={formData.addharNo} onChange={handleChange} required />
            {formErrors.addharNo && <ErrorMessage>{formErrors.addharNo}</ErrorMessage>}
          </FormGroup>
          <FormGroup>
            <Input type="number" name="height" placeholder="Height (in cm)" value={formData.height} onChange={handleChange} required />
            {formErrors.height && <ErrorMessage>{formErrors.height}</ErrorMessage>}
            <Input type="number" name="weight" placeholder="Weight (in kg)" value={formData.weight} onChange={handleChange} required />
            {formErrors.weight && <ErrorMessage>{formErrors.weight}</ErrorMessage>}
          </FormGroup>
          <FormGroup>
            <Input type="number" name="age" placeholder="Age" value={formData.age} onChange={handleChange} required />
            {formErrors.age && <ErrorMessage>{formErrors.age}</ErrorMessage>}
            <Select name="gender" value={formData.gender} onChange={handleChange} required>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </Select>
            {formErrors.gender && <ErrorMessage>{formErrors.gender}</ErrorMessage>}
          </FormGroup>
          <FormGroup>
            <Input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
            {formErrors.email && <ErrorMessage>{formErrors.email}</ErrorMessage>}
            <Input type="text" name="mobileNo" placeholder="Mobile Number" value={formData.mobileNo} onChange={handleChange} required />
            {formErrors.mobileNo && <ErrorMessage>{formErrors.mobileNo}</ErrorMessage>}
          </FormGroup>
          <FormGroup>
            <TextArea name="address" placeholder="Address" value={formData.address} onChange={handleChange} required />
            {formErrors.address && <ErrorMessage>{formErrors.address}</ErrorMessage>}
          </FormGroup>
          <Button type="submit">Register</Button>
          <LinkText>
            Already have an account? <LoginLink to="/login">Login</LoginLink>
          </LinkText>
        </RegisterFormContainer>

        {showPopup && (
          <SuccessPopup>
            <p>Registration successful!</p>
            <Button onClick={() => setShowPopup(false)}>Close</Button>
          </SuccessPopup>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default PatientRegistrationForm;
