

// // // // // import "../Styles/Header.css";
// // // // // import Homeicon from "../Assets/Home icon.png";
// // // // // import settingicon from "../Assets/setting icon.png";
// // // // // import React, { useState } from "react";
// // // // // import { Link, useNavigate } from "react-router-dom";
// // // // // import notificationIcon from "../Assets/notification icon.png"; // Corrected variable name
// // // // // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // // // // function Navbar() {
// // // // //   const [showNotification, setShowNotification] = useState(false);
// // // // //   const [showPopup, setShowPopup] = useState(false);
// // // // //   const navigate = useNavigate();

// // // // //   const toggleNotification = () => {
// // // // //     setShowNotification(!showNotification); // Toggle notification box visibility
// // // // //   };

// // // // //   const togglePopup = () => {
// // // // //     setShowPopup(!showPopup); // Toggle popup visibility
// // // // //   };

// // // // //   const logout = () => {
// // // // //     localStorage.removeItem("token"); // Remove token from localStorage
// // // // //     navigate("/"); // Redirect to login page
// // // // //   };

// // // // //   return (
// // // // //     <div className="navbar-section">
// // // // //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// // // // //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// // // // //       </div>


// // // // //       {/* Desktop */}
// // // // //       <ul className="navbar-items">
// // // // //         <li>
// // // // //           <Link to="/dashboard"> {/* Added Link wrapper for the Home icon */}
// // // // //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// // // // //           </Link>
// // // // //         </li>
// // // // //         <li>
// // // // //           <img src={notificationIcon} alt="Notification Icon" className="history-icon" onClick={toggleNotification} />
// // // // //           {showNotification && (
// // // // //             <div className="notification-box">
// // // // //               <ul>
// // // // //                 <li>Appointment Scheduled</li>
// // // // //               </ul>
// // // // //             </div>
// // // // //           )}
// // // // //         </li>
// // // // //         <li>
// // // // //           <img
// // // // //             src={settingicon}
// // // // //             alt="Setting Icon"
// // // // //             className="history-icon"
// // // // //             onClick={togglePopup}
// // // // //           />
// // // // //           {showPopup && (
// // // // //             <div className="popup show">
// // // // //               <ul>
// // // // //                 <li>Health Officer</li>
// // // // //                 <li><Link to="/Registerpatient">Registerpatient</Link></li>
// // // // //                 {/* <li><Link to="/userdashboard">PatientData</Link></li> */}
// // // // //                 {/* <li><Link to="/patientlist">Patient List</Link></li> */}
// // // // //                 {/* <li><Link to="/updatehealth">Update Health Records</Link></li> */}
// // // // //                 {/* <li><Link to="/clinichistory">Clinic History</Link></li> */}
// // // // //                 <li><Link to="/Hochangepassword">Change Password</Link></li>
// // // // //                 <li><Link to="/" onClick={logout}>Logout</Link></li> {/* Use Link for logout */}
// // // // //               </ul>
// // // // //             </div>
// // // // //           )}
// // // // //         </li>
// // // // //       </ul>
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // // export default Navbar;
// // import "../Styles/Header.css";
// // import React, { useState, useEffect } from "react";
// // import { Link, useNavigate } from "react-router-dom";
// // import Homeicon from "../Assets/Home icon.png";
// // import settingicon from "../Assets/setting icon.png";
// // import notificationIcon from "../Assets/notification icon.png";
// // import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// // import { BASE_URL } from './config/apiConfig'; // Ensure you have this config file

// // function Navbar() {
// //   const [showNotification, setShowNotification] = useState(false);
// //   const [showPopup, setShowPopup] = useState(false);
// //   const [healthOfficerName, setHealthOfficerName] = useState(""); // State to store health officer's name
// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     const fetchHealthOfficerDetails = async () => {
// //       try {
// //         const token = localStorage.getItem('jwtToken'); // Adjust the key if needed

// //         if (!token) {
// //           console.error('No JWT token found in localStorage');
// //           return;
// //         }

// //         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
// //           headers: {
// //             'Auth': `Bearer ${token}`,
// //             'Content-Type': 'application/json'
// //           }
// //         });

// //         if (!response.ok) {
// //           throw new Error(`HTTP error! Status: ${response.status}`);
// //         }

// //         const data = await response.json();
// //         const healthOfficer = data.name; // Adjust based on your API response structure
// //         if (healthOfficer) {
// //           const capitalizedHealthOfficerName = healthOfficer.charAt(0).toUpperCase() + healthOfficer.slice(1);
// //           setHealthOfficerName(capitalizedHealthOfficerName);
// //         }
// //       } catch (error) {
// //         console.error('Error fetching health officer details:', error);
// //       }
// //     };

// //     fetchHealthOfficerDetails();
// //   }, []);

// //   const toggleNotification = () => {
// //     setShowNotification(!showNotification); // Toggle notification box visibility
// //   };

// //   const togglePopup = () => {
// //     setShowPopup(!showPopup); // Toggle popup visibility
// //   };

// //   const logout = () => {
// //     localStorage.removeItem("jwtToken"); // Remove token from localStorage
// //     navigate("/"); // Redirect to login page
// //   };

// //   return (
// //     <div className="navbar-section">
// //       <div className="navbar-title" style={{ marginLeft: '10px' }}>
// //         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
// //       </div>

// //       {/* Desktop */}
// //       <ul className="navbar-items">
// //         <li>
// //           <Link to="/dashboard"> {/* Added Link wrapper for the Home icon */}
// //             <img src={Homeicon} alt="Home Icon" className="history-icon" />
// //           </Link>
// //         </li>
// //         <li>
// //           <img src={notificationIcon} alt="Notification Icon" className="history-icon" onClick={toggleNotification} />
// //           {showNotification && (
// //             <div className="notification-box">
// //               <ul>
// //                 <li>Appointment Scheduled</li>
// //               </ul>
// //             </div>
// //           )}
// //         </li>
// //         <li>
// //           <img
// //             src={settingicon}
// //             alt="Setting Icon"
// //             className="history-icon"
// //             onClick={togglePopup}
// //           />
// //           {showPopup && (
// //             <div className="popup show">
// //               <ul>
// //                 <li>{healthOfficerName ? `Officer: ${healthOfficerName}` : 'Health Officer'}</li>
// //                 <li><Link to="/profile">My Profile</Link></li>
// //                 <li><Link to="/Registerpatient">Register Patient</Link></li>
// //                 <li><Link to="/Hochangepassword">Change Password</Link></li>
// //                 <li><Link to="/" onClick={logout}>Logout</Link></li>
// //               </ul>
// //             </div>
// //           )}
// //         </li>
// //       </ul>
// //     </div>
// //   );
// // }

// // export default Navbar;

// import "../Styles/Header.css";
// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Homeicon from "../Assets/Home icon.png";
// import settingicon from "../Assets/setting icon.png";
// import notificationIcon from "../Assets/notification icon.png";
// import logo from "../Assets/O2I_LOGO.png"; // Import your image here
// import { BASE_URL } from './config/apiConfig'; // Ensure you have this config file

// function Navbar() {
//   const [showNotification, setShowNotification] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);
//   const [showProfilePopup, setShowProfilePopup] = useState(false);
//   const [healthOfficerInfo, setHealthOfficerInfo] = useState({});
//   const [editMode, setEditMode] = useState(false);
//   const [successMessage, setSuccessMessage] = useState(""); // State for success message
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchHealthOfficerDetails = async () => {
//       try {
//         const token = localStorage.getItem('jwtToken');
//         if (!token) {
//           console.error('No JWT token found in localStorage');
//           return;
//         }

//         const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
//           headers: {
//             'Auth': `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         const capitalizedName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
//         setHealthOfficerInfo({
//           name: capitalizedName,
//           email: data.email,
//           hospitalName: data.hospitalName,
//           hospitalAddress: data.hospitalAddress,
//           degree: data.docHsProfileDegree,
//           specialist: data.docHsProfileSpecilist,
//           experience: data.docHsProfileExperience,
//           address: data.docHsProfileAddress,
//           age: data.docHsProfileHsAge,
//           gender: data.docHsProfileGender,
//           bloodGroup: data.docHsProfileBloodGroup,
//           image: data.docHsProfileImage,
//         });
//       } catch (error) {
//         console.error('Error fetching health officer details:', error);
//       }
//     };

//     fetchHealthOfficerDetails();
//   }, []);

//   const toggleNotification = () => {
//     setShowNotification(!showNotification);
//   };

//   const togglePopup = () => {
//     setShowPopup(!showPopup);
//   };

//   const toggleProfilePopup = () => {
//     setShowProfilePopup(!showProfilePopup);
//     setShowPopup(false);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setHealthOfficerInfo(prevInfo => ({
//       ...prevInfo,
//       [name]: value
//     }));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setHealthOfficerInfo(prevInfo => ({
//       ...prevInfo,
//       image: file
//     }));
//   };

//   const handleUpdateProfile = async () => {
//     try {
//       const token = localStorage.getItem('jwtToken');
//       if (!token) {
//         console.error('No JWT token found in localStorage');
//         return;
//       }

//       const formData = new FormData();
//       formData.append('docHsProfileDegree', healthOfficerInfo.degree || '');
//       formData.append('docHsProfileSpecilist', healthOfficerInfo.specialist || '');
//       formData.append('docHsProfileExperience', healthOfficerInfo.experience || '');
//       formData.append('docHsProfileAddress', healthOfficerInfo.address || '');
//       formData.append('docHsProfileHsAge', healthOfficerInfo.age || '');
//       formData.append('docHsProfileGender', healthOfficerInfo.gender || '');
//       formData.append('docHsProfileBloodGroup', healthOfficerInfo.bloodGroup || '');

//       if (healthOfficerInfo.image && healthOfficerInfo.image instanceof File) {
//         formData.append('docHsProfileImage', healthOfficerInfo.image);
//       }

//       const response = await fetch(`${BASE_URL}/registerDocHs/profile-create`, {
//         method: 'PUT',
//         headers: {
//           'Auth': `Bearer ${token}`
//         },
//         body: formData
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       setSuccessMessage("Profile updated successfully!");
//       setEditMode(false);

//       setTimeout(() => setSuccessMessage(""), 3000);
//     } catch (error) {
//       console.error('Error updating health officer details:', error);
//     }
//   };

//   const logout = () => {
//     localStorage.removeItem("jwtToken");
//     navigate("/");
//   };

//   return (
//     <div className="navbar-section">
//       <div className="navbar-title" style={{ marginLeft: '10px' }}>
//         <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
//       </div>

//       {/* Desktop */}
//       <ul className="navbar-items">
//         <li>
//           <Link to="/dashboard">
//             <img src={Homeicon} alt="Home Icon" className="history-icon" />
//           </Link>
//         </li>
//         <li>
//           <img src={notificationIcon} alt="Notification Icon" className="history-icon" onClick={toggleNotification} />
//           {showNotification && (
//             <div className="notification-box">
//               <ul>
//                 <li>Appointment Scheduled</li>
//               </ul>
//             </div>
//           )}
//         </li>
//         <li>
//           <img
//             src={settingicon}
//             alt="Setting Icon"
//             className="history-icon"
//             onClick={togglePopup}
//           />
//           {showPopup && (
//             <div className="popup show">
//               <ul>
//                 <li>{healthOfficerInfo.name ? `Officer: ${healthOfficerInfo.name}` : 'Health Officer'}</li>
//                 <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
//                 <li><Link to="/Registerpatient">Register Patient</Link></li>
//                 <li><Link to="/Hochangepassword">Change Password</Link></li>
//                 <li><Link to="/" onClick={logout}>Logout</Link></li>
//               </ul>
//             </div>
//           )}
//         </li>
//       </ul>

//       {/* Profile Details Popup */}
//       {showProfilePopup && (
//         <div className="profile-popup show">
//           <div className="doctor-details">
//             <div className="profile-image-container">
//               {healthOfficerInfo.image ? (
//                 <img
//                   src={`${BASE_URL}${healthOfficerInfo.image}`}
//                   alt="Health Officer Profile"
//                   className="profile-image"
//                 />
//               ) : (
//                 <div className="profile-image-placeholder">No Image</div>
//               )}
//             </div>
//             <h2>{healthOfficerInfo.name ? `Officer: ${healthOfficerInfo.name}` : 'Health Officer'}</h2>
//             {editMode ? (
//               <>
//                 <p><strong>Email:</strong> <input type="email" name="email" value={healthOfficerInfo.email || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={healthOfficerInfo.hospitalName || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={healthOfficerInfo.hospitalAddress || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Degree:</strong> <input type="text" name="degree" value={healthOfficerInfo.degree || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Specialist:</strong> <input type="text" name="specialist" value={healthOfficerInfo.specialist || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Experience:</strong> <input type="text" name="experience" value={healthOfficerInfo.experience || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Address:</strong> <input type="text" name="address" value={healthOfficerInfo.address || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Age:</strong> <input type="number" name="age" value={healthOfficerInfo.age || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Gender:</strong> 
//                   <select name="gender" value={healthOfficerInfo.gender || ''} onChange={handleInputChange}>
//                     <option value="">Select Gender</option>
//                     <option value="Male">Male</option>
//                     <option value="Female">Female</option>
//                     <option value="Other">Other</option>
//                   </select>
//                 </p>
//                 <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={healthOfficerInfo.bloodGroup || ''} onChange={handleInputChange} /></p>
//                 <p><strong>Profile Image:</strong> <input type="file" name="image" onChange={handleFileChange} /></p>
//                 <button onClick={handleUpdateProfile}>Save</button>
//                 {successMessage && <p className="success-message">{successMessage}</p>}
//               </>
//             ) : (
//               <>
//                 <p><strong>Email:</strong> {healthOfficerInfo.email}</p>
//                 <p><strong>Hospital Name:</strong> {healthOfficerInfo.hospitalName}</p>
//                 <p><strong>Hospital Address:</strong> {healthOfficerInfo.hospitalAddress}</p>
//                 <p><strong>Degree:</strong> {healthOfficerInfo.degree}</p>
//                 <p><strong>Specialist:</strong> {healthOfficerInfo.specialist}</p>
//                 <p><strong>Experience:</strong> {healthOfficerInfo.experience}</p>
//                 <p><strong>Address:</strong> {healthOfficerInfo.address}</p>
//                 <p><strong>Age:</strong> {healthOfficerInfo.age}</p>
//                 <p><strong>Gender:</strong> {healthOfficerInfo.gender}</p>
//                 <p><strong>Blood Group:</strong> {healthOfficerInfo.bloodGroup}</p>
//               </>
//             )}
//             <button onClick={() => setEditMode(!editMode)}>
//               {editMode ? 'Cancel' : 'Edit'}
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Navbar;
import "../Styles/Header.css";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Homeicon from "../Assets/Home icon.png";
import settingicon from "../Assets/setting icon.png";
import notificationIcon from "../Assets/notification icon.png";
import logo from "../Assets/O2I_LOGO.png"; // Import your image here
import { BASE_URL } from './config/apiConfig'; // Ensure you have this config file

function Navbar() {
  const [showNotification, setShowNotification] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [healthOfficerInfo, setHealthOfficerInfo] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const profilePopupRef = useRef(null);

  useEffect(() => {
    const fetchHealthOfficerDetails = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          console.error('No JWT token found in localStorage');
          return;
        }

        const response = await fetch(`${BASE_URL}/registerDocHs/get-doctor-and-healthOfficer`, {
          headers: {
            'Auth': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const capitalizedName = data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : '';
        
        setHealthOfficerInfo({
          name: capitalizedName,
          email: data.email,
          hospitalName: data.hospitalName,
          hospitalAddress: data.hospitalAddress,
          degree: data.docHsProfileDegree,
          specialist: data.docHsProfileSpecilist,
          experience: data.docHsProfileExperience,
          address: data.docHsProfileAddress,
          age: data.docHsProfileHsAge,
          gender: data.docHsProfileGender,
          bloodGroup: data.docHsProfileBloodGroup,
          image: data.docHsProfileImage,
        });
      } catch (error) {
        console.error('Error fetching health officer details:', error);
      }
    };

    fetchHealthOfficerDetails();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
        setShowProfilePopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const togglePopup = () => {
    setShowPopup(prev => !prev);
    if (showPopup) {
      setShowProfilePopup(false);
    }
  };

  const toggleProfilePopup = () => {
    setShowProfilePopup(prev => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHealthOfficerInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setHealthOfficerInfo(prevInfo => ({
      ...prevInfo,
      image: file
    }));
  };

  const handleUpdateProfile = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        console.error('No JWT token found in localStorage');
        return;
      }

      const formData = new FormData();
      formData.append('docHsProfileDegree', healthOfficerInfo.degree || '');
      formData.append('docHsProfileSpecilist', healthOfficerInfo.specialist || '');
      formData.append('docHsProfileExperience', healthOfficerInfo.experience || '');
      formData.append('docHsProfileAddress', healthOfficerInfo.address || '');
      formData.append('docHsProfileHsAge', healthOfficerInfo.age || '');
      formData.append('docHsProfileGender', healthOfficerInfo.gender || '');
      formData.append('docHsProfileBloodGroup', healthOfficerInfo.bloodGroup || '');

      if (healthOfficerInfo.image && healthOfficerInfo.image instanceof File) {
        formData.append('docHsProfileImage', healthOfficerInfo.image);
      }

      const response = await fetch(`${BASE_URL}/registerDocHs/profile-create`, {
        method: 'PUT',
        headers: {
          'Auth': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setSuccessMessage("Profile updated successfully!");
      setEditMode(false);

      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      console.error('Error updating health officer details:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem("jwtToken");
    navigate("/");
  };

  return (
    <div className="navbar-section">
      <div className="navbar-title" style={{ marginLeft: '10px' }}>
        <img src={logo} alt="Oxygen 2 Innovation" className="logo-image" />
      </div>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/dashboard">
            <img src={Homeicon} alt="Home Icon" className="history-icon" />
          </Link>
        </li>
        <li>
          <img src={notificationIcon} alt="Notification Icon" className="history-icon" onClick={toggleNotification} />
          {showNotification && (
            <div className="notification-box">
              <ul>
                <li>Appointment Scheduled</li>
              </ul>
            </div>
          )}
        </li>
        <li>
          <img
            src={settingicon}
            alt="Setting Icon"
            className="history-icon"
            onClick={togglePopup}
          />
          {showPopup && (
            <div className="popup show" ref={popupRef}>
              <ul>
                <li>{healthOfficerInfo.name ? `Officer: ${healthOfficerInfo.name}` : 'Health Officer'}</li>
                <li><Link to="#" onClick={toggleProfilePopup}>My Profile</Link></li>
                <li><Link to="/Registerpatient">Register Patient</Link></li>
                <li><Link to="/Hochangepassword">Change Password</Link></li>
                <li><Link to="/" onClick={logout}>Logout</Link></li>
              </ul>
            </div>
          )}
        </li>
      </ul>

      {/* Profile Details Popup */}
      {showProfilePopup && (
        <div className="profile-popup show" ref={profilePopupRef}>
          <div className="doctor-details">
            <div className="profile-image-container">
              {healthOfficerInfo.image ? (
                <img
                  src={`${BASE_URL}${healthOfficerInfo.image}`}
                  alt="Health Officer Profile"
                  className="profile-image"
                />
              ) : (
                <div className="profile-image-placeholder">No Image</div>
              )}
            </div>
            <h2>{healthOfficerInfo.name ? `Officer: ${healthOfficerInfo.name}` : 'Health Officer'}</h2>
            {editMode ? (
              <>
                <p><strong>Email:</strong> <input type="email" name="email" value={healthOfficerInfo.email || ''} onChange={handleInputChange} /></p>
                <p><strong>Hospital Name:</strong> <input type="text" name="hospitalName" value={healthOfficerInfo.hospitalName || ''} onChange={handleInputChange} /></p>
                <p><strong>Hospital Address:</strong> <input type="text" name="hospitalAddress" value={healthOfficerInfo.hospitalAddress || ''} onChange={handleInputChange} /></p>
                <p><strong>Degree:</strong> <input type="text" name="degree" value={healthOfficerInfo.degree || ''} onChange={handleInputChange} /></p>
                <p><strong>Specialist:</strong> <input type="text" name="specialist" value={healthOfficerInfo.specialist || ''} onChange={handleInputChange} /></p>
                <p><strong>Experience:</strong> <input type="text" name="experience" value={healthOfficerInfo.experience || ''} onChange={handleInputChange} /></p>
                <p><strong>Address:</strong> <input type="text" name="address" value={healthOfficerInfo.address || ''} onChange={handleInputChange} /></p>
                <p><strong>Age:</strong> <input type="text" name="age" value={healthOfficerInfo.age || ''} onChange={handleInputChange} /></p>
                <p><strong>Gender:</strong>
                  <select name="gender" value={healthOfficerInfo.gender || ''} onChange={handleInputChange}>
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </p>
                <p><strong>Blood Group:</strong> <input type="text" name="bloodGroup" value={healthOfficerInfo.bloodGroup || ''} onChange={handleInputChange} /></p>
                <p><strong>Profile Image:</strong> <input type="file" name="image" onChange={handleFileChange} /></p>
                <button onClick={handleUpdateProfile}>Save</button>
                {successMessage && <p className="success-message">{successMessage}</p>}
              </>
            ) : (
              <>
                <p><strong>Email:</strong> {healthOfficerInfo.email}</p>
                <p><strong>Hospital Name:</strong> {healthOfficerInfo.hospitalName}</p>
                <p><strong>Hospital Address:</strong> {healthOfficerInfo.hospitalAddress}</p>
                <p><strong>Degree:</strong> {healthOfficerInfo.degree}</p>
                <p><strong>Specialist:</strong> {healthOfficerInfo.specialist}</p>
                <p><strong>Experience:</strong> {healthOfficerInfo.experience}</p>
                <p><strong>Address:</strong> {healthOfficerInfo.address}</p>
                <p><strong>Age:</strong> {healthOfficerInfo.age}</p>
                <p><strong>Gender:</strong> {healthOfficerInfo.gender}</p>
                <p><strong>Blood Group:</strong> {healthOfficerInfo.bloodGroup}</p>
              </>
            )}
            <button onClick={() => setEditMode(!editMode)}>
              {editMode ? 'Cancel' : 'Edit'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
