

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import WebRTCClient from "./WebRTCClient";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { BASE_URL } from '../config/apiConfig';
// import styled from 'styled-components';
// const Container1 = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// `;

// const RowContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 1rem;
// `;

// const LabTestContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
// `;

// const Input = styled.input`
//   flex: 1;
//   height: 2rem; /* Adjust height as needed */
//   width: 300px; /* Adjust width as needed */
//   padding: 0.5rem;
// `;

// const Button = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: ${props => (props.remove ? 'red' : 'green')};
//   color: white;
//   border: none;
//   padding: 0.5rem;
//   cursor: pointer;
//   border-radius: 0.25rem;

//   &:hover {
//     opacity: 0.8;
//   }
// `;
// const FollowUpContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 1rem;
// `;
// function VideoConferenceInterface() {
//   const [activeTab, setActiveTab] = useState('Vitals');
//   const location = useLocation();
//   const [vitals, setVitals] = useState(null);
//   const [testResults, setTestResults] = useState(null);
//   const [prescriptions, setPrescriptions] = useState([{ id: 1 }]);
//   const [patientDetails, setPatientDetails] = useState({})
//   const [patientId, setPatientId] = useState(null);
//   const [prescriptionData, setPrescriptionData] = useState({
//     medications: [
//       {
//         medication: '',
//         frequency: '',
//         dosage: '',
//         days: 0,
//         laboratory: ''
//       }
//     ],
//     laboratory: [
//       {
//         laboratory: ''
//       }
//     ],
//     chiefComplaints: '',
//     symptoms: '',
//     advice: '',
//     provisionalDiagnosis: '',
//     followUpDate: ''
//   });
//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };
//   const handleMedicationChange = (e, index, field) => {
//     const updatedMedications = [...prescriptionData.medications];
//     updatedMedications[index][field] = e.target.value;
//     setPrescriptionData({ ...prescriptionData, medications: updatedMedications });
//   };
//   useEffect(() => {
//     if (location.state && location.state.patientId) {
//       const { patientId } = location.state;
//       fetchPatientData(patientId);
//     } else {
//       console.error('No patient ID provided in location state');
//     }
//   }, [location.state]);

//   const fetchPatientData = async (patientId) => {
//     const key = localStorage.getItem('jwtToken');

//     if (!key) {
//       console.error('No JWT token found in localStorage');
//       return;
//     }

//     try {
//       // Fetch patient vitals
//       const vitalsResponse = await fetch(
//         `${BASE_URL}/registerDocHs/get-patient-vitals?patientId=${patientId}`,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Auth: `Bearer ${key}`
//           }
//         }
//       );

//       if (!vitalsResponse.ok) {
//         const errorMessage = await vitalsResponse.text();
//         throw new Error(
//           `HTTP error! Status: ${vitalsResponse.status}, ${errorMessage}`
//         );
//       }

//       const vitalsData = await vitalsResponse.json();
//       console.log('Fetched patient vitals:', vitalsData);
//       setVitals(vitalsData);
//       // Fetch test results using patientHealthMetricsId from vitals
//       if (vitalsData && vitalsData.id) {
//         const testResultsResponse = await fetch(
//           `${BASE_URL}/registerDocHs/patient-documents?patientHealthMetricsId=${vitalsData.id}`,
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               'Auth': `Bearer ${key}`
//             }
//           }
//         );

//         if (!testResultsResponse.ok) {
//           throw new Error('Failed to fetch test results');
//         }

//         const testResultsData = await testResultsResponse.json();
//         console.log('Fetched test results:', testResultsData);
//         setTestResults(testResultsData);
//       } else {
//         console.error('No valid patient vitals ID found or vitalsData is null/undefined');
//         // If vitalsData is not present or has no id, you might want to clear or reset testResultsData
//         setTestResults(null); // Reset or clear testResultsData state
//       }



//       const patientDetailsResponse = await fetch(
//         `${BASE_URL}/registerDocHs/patient-details-symptom?patientId=${patientId}`,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             'Auth': `Bearer ${key}`
//           }
//         }
//       );

//       if (!patientDetailsResponse.ok) {
//         throw new Error('Failed to fetch patient details');
//       }

//       const patientDetailsData = await patientDetailsResponse.json();
//       console.log('Fetched patient details:', patientDetailsData);
//       setPatientDetails(patientDetailsData || {}); // Ensure to set an empty object if response data is null or undefined
//     } catch (error) {
//       console.error('Error fetching patient data:', error);
//     }
//   };


//   const handleInputChange = (index, type, event) => {
//     const { name, value } = event.target;
//     const list = [...prescriptionData[type]];
//     list[index][name] = value;
//     setPrescriptionData({ ...prescriptionData, [type]: list });
//   };

//   const handleLabChange = (index, event) => {
//     const { name, value } = event.target;
//     const list = [...prescriptionData.laboratoryTests];
//     list[index][name] = value;
//     setPrescriptionData({ ...prescriptionData, laboratoryTests: list });
//   };

//   const handleAddFields = (type) => {
//     if (type === 'medications') {
//       setPrescriptionData({
//         ...prescriptionData,
//         medications: [
//           ...prescriptionData.medications,
//           {
//             medication: '',
//             frequency: '',
//             dosage: '',
//             days: 0,
//             laboratory: ''
//           }
//         ]
//       });
//     } else if (type === 'laboratory') {
//       setPrescriptionData({
//         ...prescriptionData,
//         laboratory: [...prescriptionData.laboratory, { laboratory: '' }]
//       });
//     }
//   };

//   const handleRemoveFields = (index, type) => {
//     if (type === 'medications') {
//       const list = [...prescriptionData.medications];
//       list.splice(index, 1);
//       setPrescriptionData({ ...prescriptionData, medications: list });
//     } else if (type === 'laboratory') {
//       const list = [...prescriptionData.laboratory];
//       list.splice(index, 1);
//       setPrescriptionData({ ...prescriptionData, laboratory: list });
//     }
//   };

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };
//   const handleSubmit = async () => {
//     try {
//       const key = localStorage.getItem('jwtToken');
//       const response = await axios.post(
//         `${BASE_URL}/registerDocHs/patient-prescription?patientId=${location.state.patientId}`,
//         prescriptionData,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Auth: `Bearer ${key}`
//           }
//         }
//       );
//       console.log('Prescription data submitted successfully:', response.data);

//       // Show success toast notification
//       toast.success('Prescription saved successfully!');

//       // Reset form
//       setPrescriptionData({
//         medications: [
//           {
//             medication: '',
//             frequency: '',
//             dosage: '',
//             days: 0,
//             laboratory: ''
//           }
//         ],
//         laboratory: [
//           {
//             laboratory: ''
//           }
//         ],
//         chiefComplaints: '',
//         symptoms: '',
//         advice: '',
//         provisionalDiagnosis: '',
//         followUpDate: ''
//       });
//     } catch (error) {
//       console.error('Error submitting prescription data:', error);
//       toast.error('Error saving prescription.');
//     }
//   };

//   const handleDocumentDownload = (documentUrl) => {
//     // Function to trigger document download
//     const anchor = document.createElement('a');
//     anchor.href = `${BASE_URL}${documentUrl}`; // Construct full URL to the document
//     anchor.target = '_blank'; // Open in new tab
//     anchor.setAttribute('download', ''); // Set download attribute
//     anchor.click(); // Click the anchor element to trigger download
//   };

//   return (
//     <div style={styles.container}>
//       {/* Main content */}

//       <div className="row" style={{ height: '100%' }}>

//         <div className="col-md-6">
//           <WebRTCClient patientId={patientId} />
//         </div>
//         <div className="col-md-6" style={{ ...styles.vitalDetails, background: 'transparent', }}>
//           <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
//             <button
//               style={{
//                 ...styles.tabButton,
//                 backgroundColor: activeTab === 'Vitals' ? '#007bff' : '#BABABA',
//               }}
//               onClick={() => handleTabClick('Vitals')}
//             >
//               Vitals
//             </button>
//             <button
//               style={{
//                 ...styles.tabButton,
//                 backgroundColor: activeTab === 'TestResults' ? '#007bff' : '#BABABA',
//               }}
//               onClick={() => handleTabClick('TestResults')}
//             >
//               Test Results
//             </button>
//             <button
//               style={{
//                 ...styles.tabButton,
//                 backgroundColor: activeTab === 'Prescription' ? '#007bff' : '#BABABA',
//               }}
//               onClick={() => handleTabClick('Prescription')}
//             >
//               Prescription
//             </button>
//           </div>
//           {activeTab === 'Vitals' && (
//             <>
//               <div style={styles.vitalDataContainer}>
//                 {/* Blood Group */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Blood Group</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.bloodGroup : '-'}</p>
//                 </div>

//                 {/* SpO2 */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>SpO2 (%)</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.spO2 : '-'}</p>
//                 </div>

//                 {/* Respiration Rate */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Respiration Rate</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.respirationRate : '-'}</p>
//                 </div>

//                 {/* Heart Rate */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Heart Rate</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.heartRate : '-'}</p>
//                 </div>

//                 {/* Diastolic BP */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Diastolic BP (mmHg)</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.diastolicBP : '-'}</p>
//                 </div>

//                 {/* Systolic BP */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Systolic BP (mmHg)</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.systolicBP : '-'}</p>
//                 </div>

//                 {/* Pulse Rate */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Pulse Rate (/min)</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.pulseRate : '-'}</p>
//                 </div>

//                 {/* Temperature */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Temperature</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.temperature : '-'}</p>
//                 </div>

//                 {/* Hemoglobin */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Hemoglobin</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.hemoglobin : '-'}</p>
//                 </div>


//               </div>
//             </>
//           )}




//           {activeTab === 'TestResults' && (
//             <div style={{ overflowX: 'auto' }}>
//               <table style={styles.table}>
//                 <thead>
//                   <tr style={styles.tableHeaderRow}>
//                     <th style={styles.tableHeaderLeft}>Document Name</th>
//                     <th style={styles.tableHeaderCenter}>Document Type</th>
//                     <th style={styles.tableHeaderRight}>View Document</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {testResults && testResults.length > 0 ? (
//                     testResults.map((test, index) => (
//                       <tr key={index}>
//                         <td style={styles.tableDataLeft}>
//                           <div style={styles.valueBox}>{test.documentName}</div>
//                         </td>
//                         <td style={styles.tableDataCenter}>
//                           <div style={styles.valueBox}>{test.document}</div>
//                         </td>
//                         <td style={styles.tableDataRight}>
//                           <div style={{ ...styles.valueBox, color: 'white' }}>
//                             <p onClick={() => handleDocumentDownload(test.documentUrl)} style={styles.documentLink}>Download</p>
//                           </div>
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <tr>
//                       <td colSpan="3" style={{ textAlign: 'center' }}>
//                         {testResults ? 'No test results available' : 'No test results available'} {/* Handle loading state if testResults is null */}
//                       </td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>
//           )}


//           {activeTab === 'Prescription' && (
//             <>
//               <div className="prescription-form">

//                 {/* <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', width: '100%' }}>
//          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//       <label>Comorbidity:</label>
//       <textarea
//         name="comorbidity"
//         value={patientDetails.comorbidity || ''}
//         readOnly
//         style={{ width: "100%", height: "30px" }}
//       ></textarea>
//     </div>

//                         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                             <label>Complaints:</label>
//                             <textarea
//                                 name="complaints"
//                                 value={patientDetails.complaints || ''}
//                                 readOnly
//                                 style={{ width: "100%", height: "30px" }}
//                             ></textarea>
//                         </div>

//                         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                             <label>Allergy:</label>
//                             <textarea
//                                 name="allergy"
//                                 value={patientDetails.allergy || ''}
//                                 readOnly
//                                 style={{ width: "100%", height: "30px" }}
//                             ></textarea>
//                         </div>

//                         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                             <label>Other Illness:</label>
//                             <textarea
//                                 name="otherIllness"
//                                 value={patientDetails.otherIllness || ''}
//                                 readOnly
//                                 style={{ width: "100%", height: "30px" }}
//                             ></textarea>
//                         </div>
//                     </div> */}
//                 {patientDetails && (
//                   <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Comorbidity:</label>
//                       <textarea
//                         name="comorbidity"
//                         value={patientDetails.comorbidity || ''}
//                         readOnly
//                         style={{ width: "100%", height: "30px" }}
//                       ></textarea>
//                     </div>

//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Complaints:</label>
//                       <textarea
//                         name="complaints"
//                         value={patientDetails.complaints || ''}
//                         readOnly
//                         style={{ width: "100%", height: "30px" }}
//                       ></textarea>
//                     </div>

//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Allergy:</label>
//                       <textarea
//                         name="allergy"
//                         value={patientDetails.allergy || ''}
//                         readOnly
//                         style={{ width: "100%", height: "30px" }}
//                       ></textarea>
//                     </div>

//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Other Illness:</label>
//                       <textarea
//                         name="otherIllness"
//                         value={patientDetails.otherIllness || ''}
//                         readOnly
//                         style={{ width: "100%", height: "30px" }}
//                       ></textarea>
//                     </div>
//                   </div>
//                 )}
//                 {!patientDetails && (
//                   <div>Loading...</div>
//                 )}

//                 <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', width: '100%' }}>
//                   <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                     <label>Chief Complaints:</label>
//                     <textarea
//                       name="chiefComplaints"
//                       value={prescriptionData.chiefComplaints}
//                       onChange={(e) =>
//                         setPrescriptionData({
//                           ...prescriptionData,
//                           chiefComplaints: e.target.value
//                         })
//                       }
//                       style={{ width: "100%", height: "40px" }} // Full width and adjusted height
//                     ></textarea>
//                   </div>

//                   <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                     <label>Symptoms:</label>
//                     <textarea
//                       name="symptoms"
//                       value={prescriptionData.symptoms}
//                       onChange={(e) =>
//                         setPrescriptionData({
//                           ...prescriptionData,
//                           symptoms: e.target.value
//                         })
//                       }
//                       style={{ width: "100%", height: "40px" }} // Full width and adjusted height
//                     ></textarea>
//                   </div>
//                 </div>



//                 <div style={{ height: '38vh', overflowY: 'auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>

//                   <div style={{ display: 'flex', flexDirection: 'column' }}>

//                     <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px' }}>
//                       <button
//                         type="button"
//                         onClick={() => handleAddFields('medications')}
//                         style={{
//                           border: 'none',
//                           background: 'green',
//                           color: 'white',
//                           borderRadius: '50%',
//                           width: '30px',
//                           height: '30px',
//                           display: 'flex',
//                           justifyContent: 'center',
//                           alignItems: 'center',
//                           cursor: 'pointer',
//                           marginTop: '10px',
//                           marginBottom: '-25px'
//                         }}
//                       >
//                         <i className="fas fa-plus"></i>
//                       </button>
//                     </div>


//                     {prescriptionData.medications.map((medication, index) => (
//                       <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', position: 'relative' }}>
//                         <div style={{ marginRight: '5px' }}>
//                           <label htmlFor={`medicationName-${index}`}>Medication Name:</label>
//                           <input
//                             type="text"
//                             id={`medicationName-${index}`}
//                             placeholder="Medication Name"
//                             name="medication"
//                             value={medication.medication}
//                             onChange={(e) => handleInputChange(index, 'medications', e)}
//                             style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px' }}
//                           />
//                         </div>

//                         <div style={{ marginRight: '5px' }}>
//                           <label htmlFor={`dosage-${index}`}>Dosage:</label>
//                           <input
//                             type="text"
//                             id={`dosage-${index}`}
//                             placeholder="Dosage"
//                             name="dosage"
//                             value={medication.dosage}
//                             onChange={(e) => handleInputChange(index, 'medications', e)}
//                             style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px' }}
//                           />
//                         </div>

//                         <div style={{ marginRight: '5px' }}>
//                           <label htmlFor={`frequency-${index}`}>Frequency:</label>
//                           <input
//                             type="text"
//                             id={`frequency-${index}`}
//                             placeholder="Frequency"
//                             name="frequency"
//                             value={medication.frequency}
//                             onChange={(e) => handleInputChange(index, 'medications', e)}
//                             style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px', width: '80px' }}
//                           />
//                         </div>

//                         <div style={{ marginRight: '10px', position: 'relative' }}>
//                           <label htmlFor={`days-${index}`}>Days:</label>
//                           <input
//                             type="number"
//                             id={`days-${index}`}
//                             placeholder="Days"
//                             name="days"
//                             value={medication.days}
//                             onChange={(e) => handleInputChange(index, 'medications', e)}
//                             style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px', width: '60px' }}
//                           />


//                           {index !== 0 && (
//                             <button
//                               type="button"
//                               onClick={() => handleRemoveFields(index, 'medications')}
//                               style={{
//                                 border: 'none',
//                                 background: 'red',
//                                 color: 'white',
//                                 borderRadius: '50%',
//                                 width: '30px',
//                                 height: '30px',

//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 cursor: 'pointer',
//                                 marginRight: '5px',

//                                 position: 'absolute',
//                               }}
//                             >
//                               <i className="fas fa-minus"></i>
//                             </button>
//                           )}
//                         </div>
//                       </div>
//                     ))}
//                   </div>

//                   <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', width: '100%', marginTop: '10px' }}>
//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Advice:</label>
//                       <textarea
//                         name="advice"
//                         value={prescriptionData.advice}
//                         onChange={(e) =>
//                           setPrescriptionData({
//                             ...prescriptionData,
//                             advice: e.target.value
//                           })
//                         }
//                         style={{ width: "100%", height: "50px" }} // Full width and adjusted height
//                       ></textarea>
//                     </div>

//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Provisional Diagnosis:</label>
//                       <input
//                         type="text"
//                         name="provisionalDiagnosis"
//                         value={prescriptionData.provisionalDiagnosis}
//                         onChange={(e) =>
//                           setPrescriptionData({
//                             ...prescriptionData,
//                             provisionalDiagnosis: e.target.value
//                           })
//                         }
//                         style={{ width: "100%", height: "50px" }} // Full width and adjusted height
//                       />
//                     </div>
//                   </div>


//                   <div style={{ marginBottom: '10px' }}>
//                     <label>Laboratory Tests:</label>
//                     {prescriptionData.laboratory.map((test, index) => (
//                       <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
//                         <input
//                           type="text"
//                           placeholder="Lab Test Name"
//                           name="laboratory"
//                           value={test.laboratory}
//                           onChange={(e) => handleInputChange(index, 'laboratory', e)}
//                           style={{
//                             flex: 1,
//                             padding: '5px',
//                             borderRadius: '5px',
//                             border: '1px solid #ccc',
//                             marginRight: '10px'
//                           }}
//                         />
//                         {index > 0 && (
//                           <button
//                             type="button"
//                             onClick={() => handleRemoveFields(index, 'laboratory')}
//                             style={{
//                               border: 'none',
//                               background: 'red',
//                               color: 'white',
//                               borderRadius: '50%',
//                               width: '30px',
//                               height: '30px',
//                               display: 'flex',
//                               justifyContent: 'center',
//                               alignItems: 'center',
//                               cursor: 'pointer',
//                               marginRight: '5px'
//                             }}
//                           >
//                             <i className="fas fa-minus"></i>
//                           </button>
//                         )}
//                         {index === prescriptionData.laboratory.length - 1 && (
//                           <button
//                             type="button"
//                             onClick={() => handleAddFields('laboratory')}
//                             style={{
//                               border: 'none',
//                               background: 'green',
//                               color: 'white',
//                               borderRadius: '50%',
//                               width: '30px',
//                               height: '30px',
//                               display: 'flex',
//                               justifyContent: 'center',
//                               alignItems: 'center',
//                               cursor: 'pointer'
//                             }}
//                           >
//                             <i className="fas fa-plus"></i>
//                           </button>
//                         )}
//                       </div>
//                     ))}
//                   </div>



//                   <div style={{ marginBottom: '10px' }}>
//                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
//                       <label style={{ marginRight: '10px' }}>Follow Up Date:</label>
//                       <input
//                         type="date"
//                         name="followUpDate"
//                         value={prescriptionData.followUpDate}
//                         onChange={(e) =>
//                           setPrescriptionData({
//                             ...prescriptionData,
//                             followUpDate: e.target.value
//                           })
//                         }
//                         style={{
//                           padding: '5px',
//                           borderRadius: '5px',
//                           border: '1px solid #ccc',
//                           marginRight: '10px',
//                           flex: '1'
//                         }}
//                       />
//                       <button
//                         type="button"
//                         onClick={() => handleSubmit(prescriptionData)}
//                         style={{
//                           padding: '10px 20px',
//                           borderRadius: '5px',
//                           background: 'green',
//                           color: 'white',
//                           border: 'none',
//                           cursor: 'pointer'
//                         }}
//                       >
//                         Save Prescription
//                       </button>
//                       <ToastContainer />
//                     </div>
//                   </div>
//                 </div>



//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default VideoConferenceInterface;

// const styles = {

//   container: {
//     padding: '10px',
//     backgroundColor: 'transparent', // Set container background to transparent
//     border: '2px solid #7E7E7E',
//     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//     marginTop: '20px',
//     marginBottom: '10px', // Reduce bottom margin of the container
//     height: '500px', // Set the desired height of the container
//   },
//   doctorScreen: {
//     // borderRadius: '8px',
//     padding: '10px',
//     backgroundColor: '#7A7A7A',
//     height: '100%', // Set the height of the doctor's screen to 100%
//   },
//   patientScreen: {
//     // borderRadius: '8px',
//     padding: '10px',
//     backgroundColor: '#7A7A7A',
//     height: '100%', // Set the height of the patient's screen to 100%
//   },
//   videoFeed: {
//     width: '100%',
//     height: '70%', // Adjust the height of the video feed
//     borderRadius: '8px',
//     overflow: 'hidden',
//     marginBottom: '10px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   videoFeedText: {
//     color: '#fff',
//   },
//   controlsBottom: {
//     display: 'flex',
//     justifyContent: 'center',
//     marginTop: '60px', // Adjusted bottom margin of the screen's controls
//   },
//   controlButton: {
//     backgroundColor: 'transparent',
//     border: 'none',
//     cursor: 'pointer',
//     fontSize: '24px',
//     color: 'red', // Change icon color to red
//     marginRight: '10px',
//     transition: 'color 0.3s ease',
//   },
//   vitalDetails: {
//     padding: '20px',
//     borderRadius: '8px',
//     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//   },
//   tabButton: {
//     padding: '8px 16px',
//     border: 'none',
//     borderRadius: '8px',
//     color: '#fff',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   },
//   vitalDataContainer: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-between',
//   },
//   vitalDataItem: {
//     width: '30%', // Each item occupies one-third of the container's width
//     padding: '10px',
//     // border: '1px solid #ccc',
//     borderRadius: '8px',
//     marginBottom: '10px',
//   },
//   vitalLabel: {
//     fontWeight: 'bold',
//     marginBottom: '5px',
//     color: '#8D8D8D',
//     maxWidth: '100%', // Specify maximum width for the label
//     wordWrap: 'break-word', // Allow words to break if they exceed the width
//   },


//   vitalValue: {
//     fontSize: '16px',
//     backgroundColor: '#f9f9f9',
//     padding: '8px',
//     borderRadius: '8px',
//     width: '100%',
//     boxSizing: 'border-box',
//     minHeight: '40px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },

//   //============================================  css for result  ======================================================
//   table: {
//     width: '100%',
//     borderCollapse: 'collapse',
//     marginTop: '10px',
//     overflowX: 'auto', // Add horizontal overflow handling
//   },
//   tableHeaderRow: {
//     backgroundColor: '#0F60B3',
//     color: 'white',
//     borderRadius: '8px',
//     overflow: 'hidden',
//   },
//   tableHeaderLeft: {
//     padding: '8px',
//     textAlign: 'left',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in headers
//   },
//   tableHeaderCenter: {
//     padding: '8px',
//     textAlign: 'center',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in headers
//   },
//   tableHeaderRight: {
//     padding: '8px',
//     textAlign: 'right',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in headers
//   },
//   tableDataLeft: {
//     padding: '8px',
//     textAlign: 'left',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in cells
//   },
//   tableDataCenter: {
//     padding: '8px',
//     textAlign: 'center',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in cells
//   },
//   tableDataRight: {
//     padding: '8px',
//     textAlign: 'right',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in cells
//   },
//   valueBox: {
//     backgroundColor: '#5596D9',
//     borderRadius: '4px',
//     padding: '6px 10px',
//     display: 'inline-block',
//     color: 'white',
//     width: '150px',
//     textAlign: 'center',
//     whiteSpace: 'nowrap', // Prevent wrapping in value boxes
//   },
//   documentLink: {
//     color: 'white',
//     textDecoration: 'none',
//     wordWrap: 'break-word', // Allow long URLs to wrap
//   },

//   //====================================================================presvcription css====================





//   mediaQueryStyles: `
//         @media only screen and (max-width: 1205px) and (min-width: 775px) {
//             .vitalLabel {
//                 font-size: 5px; // Decrease font size for smaller screens
//             }
//         }
//     `,
// };





//====================10-08-2024------------------------------------------

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import WebRTCClient from "./WebRTCClient";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { BASE_URL } from '../config/apiConfig';
// import styled from 'styled-components';
// const Container1 = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// `;

// const RowContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 1rem;
// `;

// const LabTestContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
// `;

// const Input = styled.input`
//   flex: 1;
//   height: 2rem; /* Adjust height as needed */
//   width: 300px; /* Adjust width as needed */
//   padding: 0.5rem;
// `;

// const Button = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: ${props => (props.remove ? 'red' : 'green')};
//   color: white;
//   border: none;
//   padding: 0.5rem;
//   cursor: pointer;
//   border-radius: 0.25rem;

//   &:hover {
//     opacity: 0.8;
//   }
// `;
// const FollowUpContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 1rem;
// `;
// function VideoConferenceInterface() {
//   const [activeTab, setActiveTab] = useState('Vitals');
//   const location = useLocation();
//   const [vitals, setVitals] = useState(null);
//   const [testResults, setTestResults] = useState(null);
//   const [prescriptions, setPrescriptions] = useState([{ id: 1 }]);
//   const [patientDetails, setPatientDetails] = useState({})
//   const [patientId, setPatientId] = useState(null);
//   const [prescriptionData, setPrescriptionData] = useState({
//     medications: [
//       {
//         medication: '',
//         frequency: '',
//         dosage: '',
//         days: 0,
//         laboratory: ''
//       }
//     ],
//     laboratory: [
//       {
//         laboratory: ''
//       }
//     ],
//     chiefComplaints: '',
//     symptoms: '',
//     advice: '',
//     provisionalDiagnosis: '',
//     followUpDate: ''
//   });
//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };
//   const handleMedicationChange = (e, index, field) => {
//     const updatedMedications = [...prescriptionData.medications];
//     updatedMedications[index][field] = e.target.value;
//     setPrescriptionData({ ...prescriptionData, medications: updatedMedications });
//   };
//   useEffect(() => {
//     if (location.state && location.state.patientId) {
//       const { patientId } = location.state;
//       fetchPatientData(patientId);
//     } else {
//       console.error('No patient ID provided in location state');
//     }
//   }, [location.state]);

//   const fetchPatientData = async (patientId) => {
//     const key = localStorage.getItem('jwtToken');

//     if (!key) {
//       console.error('No JWT token found in localStorage');
//       return;
//     }

//     try {
//       // Fetch patient vitals
//       const vitalsResponse = await fetch(
//         `${BASE_URL}/registerDocHs/get-patient-vitals?patientId=${patientId}`,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Auth: `Bearer ${key}`
//           }
//         }
//       );

//       if (!vitalsResponse.ok) {
//         const errorMessage = await vitalsResponse.text();
//         throw new Error(
//           `HTTP error! Status: ${vitalsResponse.status}, ${errorMessage}`
//         );
//       }

//       const vitalsData = await vitalsResponse.json();
//       console.log('Fetched patient vitals:', vitalsData);
//       setVitals(vitalsData);
//       // Fetch test results using patientHealthMetricsId from vitals
//       if (vitalsData && vitalsData.id) {
//         const testResultsResponse = await fetch(
//           `${BASE_URL}/registerDocHs/patient-documents?patientHealthMetricsId=${vitalsData.id}`,
//           {
//             headers: {
//               'Content-Type': 'application/json',
//               'Auth': `Bearer ${key}`
//             }
//           }
//         );

//         if (!testResultsResponse.ok) {
//           throw new Error('Failed to fetch test results');
//         }

//         const testResultsData = await testResultsResponse.json();
//         console.log('Fetched test results:', testResultsData);
//         setTestResults(testResultsData);
//       } else {
//         console.error('No valid patient vitals ID found or vitalsData is null/undefined');
//         // If vitalsData is not present or has no id, you might want to clear or reset testResultsData
//         setTestResults(null); // Reset or clear testResultsData state
//       }



//       const patientDetailsResponse = await fetch(
//         `${BASE_URL}/registerDocHs/patient-details-symptom?patientId=${patientId}`,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             'Auth': `Bearer ${key}`
//           }
//         }
//       );

//       if (!patientDetailsResponse.ok) {
//         throw new Error('Failed to fetch patient details');
//       }

//       const patientDetailsData = await patientDetailsResponse.json();
//       console.log('Fetched patient details:', patientDetailsData);
//       setPatientDetails(patientDetailsData || {}); // Ensure to set an empty object if response data is null or undefined
//     } catch (error) {
//       console.error('Error fetching patient data:', error);
//     }
//   };


//   const handleInputChange = (index, type, event) => {
//     const { name, value } = event.target;
//     const list = [...prescriptionData[type]];
//     list[index][name] = value;
//     setPrescriptionData({ ...prescriptionData, [type]: list });
//   };

//   const handleLabChange = (index, event) => {
//     const { name, value } = event.target;
//     const list = [...prescriptionData.laboratoryTests];
//     list[index][name] = value;
//     setPrescriptionData({ ...prescriptionData, laboratoryTests: list });
//   };

//   const handleAddFields = (type) => {
//     if (type === 'medications') {
//       setPrescriptionData({
//         ...prescriptionData,
//         medications: [
//           ...prescriptionData.medications,
//           {
//             medication: '',
//             frequency: '',
//             dosage: '',
//             days: 0,
//             laboratory: ''
//           }
//         ]
//       });
//     } else if (type === 'laboratory') {
//       setPrescriptionData({
//         ...prescriptionData,
//         laboratory: [...prescriptionData.laboratory, { laboratory: '' }]
//       });
//     }
//   };

//   const handleRemoveFields = (index, type) => {
//     if (type === 'medications') {
//       const list = [...prescriptionData.medications];
//       list.splice(index, 1);
//       setPrescriptionData({ ...prescriptionData, medications: list });
//     } else if (type === 'laboratory') {
//       const list = [...prescriptionData.laboratory];
//       list.splice(index, 1);
//       setPrescriptionData({ ...prescriptionData, laboratory: list });
//     }
//   };

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };
//   const handleSubmit = async () => {
//     try {
//       const key = localStorage.getItem('jwtToken');
//       const response = await axios.post(
//         `${BASE_URL}/registerDocHs/patient-prescription?patientId=${location.state.patientId}`,
//         prescriptionData,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             Auth: `Bearer ${key}`
//           }
//         }
//       );
//       console.log('Prescription data submitted successfully:', response.data);

//       // Show success toast notification
//       toast.success('Prescription saved successfully!');

//       // Reset form
//       setPrescriptionData({
//         medications: [
//           {
//             medication: '',
//             frequency: '',
//             dosage: '',
//             days: 0,
//             laboratory: ''
//           }
//         ],
//         laboratory: [
//           {
//             laboratory: ''
//           }
//         ],
//         chiefComplaints: '',
//         symptoms: '',
//         advice: '',
//         provisionalDiagnosis: '',
//         followUpDate: ''
//       });
//     } catch (error) {
//       console.error('Error submitting prescription data:', error);
//       toast.error('Error saving prescription.');
//     }
//   };

//   const handleDocumentDownload = (documentUrl) => {
//     // Function to trigger document download
//     const anchor = document.createElement('a');
//     anchor.href = `${BASE_URL}${documentUrl}`; // Construct full URL to the document
//     anchor.target = '_blank'; // Open in new tab
//     anchor.setAttribute('download', ''); // Set download attribute
//     anchor.click(); // Click the anchor element to trigger download
//   };

//   return (
//     <div style={styles.container}>
//       {/* Main content */}

//       <div className="row" style={{ height: '100%' }}>

//         <div className="col-md-6">
//           <WebRTCClient patientId={patientId} />
//         </div>
//         <div className="col-md-6" style={{ ...styles.vitalDetails, background: 'transparent', }}>
//           <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
//             <button
//               style={{
//                 ...styles.tabButton,
//                 backgroundColor: activeTab === 'Vitals' ? '#007bff' : '#BABABA',
//               }}
//               onClick={() => handleTabClick('Vitals')}
//             >
//               Vitals
//             </button>
//             <button
//               style={{
//                 ...styles.tabButton,
//                 backgroundColor: activeTab === 'TestResults' ? '#007bff' : '#BABABA',
//               }}
//               onClick={() => handleTabClick('TestResults')}
//             >
//               Test Results
//             </button>
//             <button
//               style={{
//                 ...styles.tabButton,
//                 backgroundColor: activeTab === 'Prescription' ? '#007bff' : '#BABABA',
//               }}
//               onClick={() => handleTabClick('Prescription')}
//             >
//               Prescription
//             </button>
//           </div>
//           {activeTab === 'Vitals' && (
//             <>
//               <div style={styles.vitalDataContainer}>
//                 {/* Blood Group */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Blood Group</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.bloodGroup : '-'}</p>
//                 </div>

//                 {/* SpO2 */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>SpO2 (%)</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.spO2 : '-'}</p>
//                 </div>

//                 {/* Respiration Rate */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Respiration Rate</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.respirationRate : '-'}</p>
//                 </div>

//                 {/* Heart Rate */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Heart Rate</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.heartRate : '-'}</p>
//                 </div>

//                 {/* Diastolic BP */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Diastolic BP (mmHg)</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.diastolicBP : '-'}</p>
//                 </div>

//                 {/* Systolic BP */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Systolic BP (mmHg)</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.systolicBP : '-'}</p>
//                 </div>

//                 {/* Pulse Rate */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Pulse Rate (/min)</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.pulseRate : '-'}</p>
//                 </div>

//                 {/* Temperature */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Temperature</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.temperature : '-'}</p>
//                 </div>

//                 {/* Hemoglobin */}
//                 <div style={styles.vitalDataItem}>
//                   <p style={styles.vitalLabel}>Hemoglobin</p>
//                   <p style={styles.vitalValue}>{vitals ? vitals.hemoglobin : '-'}</p>
//                 </div>


//               </div>
//             </>
//           )}




//           {activeTab === 'TestResults' && (
//             <div style={{ overflowX: 'auto' }}>
//               <table style={styles.table}>
//                 <thead>
//                   <tr style={styles.tableHeaderRow}>
//                     <th style={styles.tableHeaderLeft}>Document Name</th>
//                     <th style={styles.tableHeaderCenter}>Document Type</th>
//                     <th style={styles.tableHeaderRight}>View Document</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {testResults && testResults.length > 0 ? (
//                     testResults.map((test, index) => (
//                       <tr key={index}>
//                         <td style={styles.tableDataLeft}>
//                           <div style={styles.valueBox}>{test.documentName}</div>
//                         </td>
//                         <td style={styles.tableDataCenter}>
//                           <div style={styles.valueBox}>{test.document}</div>
//                         </td>
//                         <td style={styles.tableDataRight}>
//                           <div style={{ ...styles.valueBox, color: 'white' }}>
//                             <p onClick={() => handleDocumentDownload(test.documentUrl)} style={styles.documentLink}>Download</p>
//                           </div>
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <tr>
//                       <td colSpan="3" style={{ textAlign: 'center' }}>
//                         {testResults ? 'No test results available' : 'No test results available'} {/* Handle loading state if testResults is null */}
//                       </td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>
//           )}


//           {activeTab === 'Prescription' && (
//             <>
//               <div className="prescription-form">

//                 {/* <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', width: '100%' }}>
//          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//       <label>Comorbidity:</label>
//       <textarea
//         name="comorbidity"
//         value={patientDetails.comorbidity || ''}
//         readOnly
//         style={{ width: "100%", height: "30px" }}
//       ></textarea>
//     </div>

//                         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                             <label>Complaints:</label>
//                             <textarea
//                                 name="complaints"
//                                 value={patientDetails.complaints || ''}
//                                 readOnly
//                                 style={{ width: "100%", height: "30px" }}
//                             ></textarea>
//                         </div>

//                         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                             <label>Allergy:</label>
//                             <textarea
//                                 name="allergy"
//                                 value={patientDetails.allergy || ''}
//                                 readOnly
//                                 style={{ width: "100%", height: "30px" }}
//                             ></textarea>
//                         </div>

//                         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                             <label>Other Illness:</label>
//                             <textarea
//                                 name="otherIllness"
//                                 value={patientDetails.otherIllness || ''}
//                                 readOnly
//                                 style={{ width: "100%", height: "30px" }}
//                             ></textarea>
//                         </div>
//                     </div> */}
//                 {patientDetails && (
//                   <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Comorbidity:</label>
//                       <textarea
//                         name="comorbidity"
//                         value={patientDetails.comorbidity || ''}
//                         readOnly
//                         style={{ width: "100%", height: "30px" }}
//                       ></textarea>
//                     </div>

//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Complaints:</label>
//                       <textarea
//                         name="complaints"
//                         value={patientDetails.complaints || ''}
//                         readOnly
//                         style={{ width: "100%", height: "30px" }}
//                       ></textarea>
//                     </div>

//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Allergy:</label>
//                       <textarea
//                         name="allergy"
//                         value={patientDetails.allergy || ''}
//                         readOnly
//                         style={{ width: "100%", height: "30px" }}
//                       ></textarea>
//                     </div>

//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Other Illness:</label>
//                       <textarea
//                         name="otherIllness"
//                         value={patientDetails.otherIllness || ''}
//                         readOnly
//                         style={{ width: "100%", height: "30px" }}
//                       ></textarea>
//                     </div>
//                   </div>
//                 )}
//                 {!patientDetails && (
//                   <div>Loading...</div>
//                 )}

//                 <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', width: '100%' }}>
//                   <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                     <label>Chief Complaints:</label>
//                     <textarea
//                       name="chiefComplaints"
//                       value={prescriptionData.chiefComplaints}
//                       onChange={(e) =>
//                         setPrescriptionData({
//                           ...prescriptionData,
//                           chiefComplaints: e.target.value
//                         })
//                       }
//                       style={{ width: "100%", height: "40px" }} // Full width and adjusted height
//                     ></textarea>
//                   </div>

//                   <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                     <label>Symptoms:</label>
//                     <textarea
//                       name="symptoms"
//                       value={prescriptionData.symptoms}
//                       onChange={(e) =>
//                         setPrescriptionData({
//                           ...prescriptionData,
//                           symptoms: e.target.value
//                         })
//                       }
//                       style={{ width: "100%", height: "40px" }} // Full width and adjusted height
//                     ></textarea>
//                   </div>
//                 </div>



//                 <div style={{ height: '38vh', overflowY: 'auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>

//                   <div style={{ display: 'flex', flexDirection: 'column' }}>

//                     <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px' }}>
//                       <button
//                         type="button"
//                         onClick={() => handleAddFields('medications')}
//                         style={{
//                           border: 'none',
//                           background: 'green',
//                           color: 'white',
//                           borderRadius: '50%',
//                           width: '30px',
//                           height: '30px',
//                           display: 'flex',
//                           justifyContent: 'center',
//                           alignItems: 'center',
//                           cursor: 'pointer',
//                           marginTop: '10px',
//                           marginBottom: '-25px'
//                         }}
//                       >
//                         <i className="fas fa-plus"></i>
//                       </button>
//                     </div>


//                     {prescriptionData.medications.map((medication, index) => (
//                       <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', position: 'relative' }}>
//                         <div style={{ marginRight: '5px' }}>
//                           <label htmlFor={`medicationName-${index}`}>Medication Name:</label>
//                           <input
//                             type="text"
//                             id={`medicationName-${index}`}
//                             placeholder="Medication Name"
//                             name="medication"
//                             value={medication.medication}
//                             onChange={(e) => handleInputChange(index, 'medications', e)}
//                             style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px' }}
//                           />
//                         </div>

//                         <div style={{ marginRight: '5px' }}>
//                           <label htmlFor={`dosage-${index}`}>Dosage:</label>
//                           <input
//                             type="text"
//                             id={`dosage-${index}`}
//                             placeholder="Dosage"
//                             name="dosage"
//                             value={medication.dosage}
//                             onChange={(e) => handleInputChange(index, 'medications', e)}
//                             style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px' }}
//                           />
//                         </div>

//                         <div style={{ marginRight: '5px' }}>
//                           <label htmlFor={`frequency-${index}`}>Frequency:</label>
//                           <input
//                             type="text"
//                             id={`frequency-${index}`}
//                             placeholder="Frequency"
//                             name="frequency"
//                             value={medication.frequency}
//                             onChange={(e) => handleInputChange(index, 'medications', e)}
//                             style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px', width: '80px' }}
//                           />
//                         </div>

//                         <div style={{ marginRight: '10px', position: 'relative' }}>
//                           <label htmlFor={`days-${index}`}>Days:</label>
//                           <input
//                             type="number"
//                             id={`days-${index}`}
//                             placeholder="Days"
//                             name="days"
//                             value={medication.days}
//                             onChange={(e) => handleInputChange(index, 'medications', e)}
//                             style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px', width: '60px' }}
//                           />


//                           {index !== 0 && (
//                             <button
//                               type="button"
//                               onClick={() => handleRemoveFields(index, 'medications')}
//                               style={{
//                                 border: 'none',
//                                 background: 'red',
//                                 color: 'white',
//                                 borderRadius: '50%',
//                                 width: '30px',
//                                 height: '30px',

//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 cursor: 'pointer',
//                                 marginRight: '5px',

//                                 position: 'absolute',
//                               }}
//                             >
//                               <i className="fas fa-minus"></i>
//                             </button>
//                           )}
//                         </div>
//                       </div>
//                     ))}
//                   </div>

//                   <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', width: '100%', marginTop: '10px' }}>
//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Advice:</label>
//                       <textarea
//                         name="advice"
//                         value={prescriptionData.advice}
//                         onChange={(e) =>
//                           setPrescriptionData({
//                             ...prescriptionData,
//                             advice: e.target.value
//                           })
//                         }
//                         style={{ width: "100%", height: "50px" }} // Full width and adjusted height
//                       ></textarea>
//                     </div>

//                     <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <label>Provisional Diagnosis:</label>
//                       <input
//                         type="text"
//                         name="provisionalDiagnosis"
//                         value={prescriptionData.provisionalDiagnosis}
//                         onChange={(e) =>
//                           setPrescriptionData({
//                             ...prescriptionData,
//                             provisionalDiagnosis: e.target.value
//                           })
//                         }
//                         style={{ width: "100%", height: "50px" }} // Full width and adjusted height
//                       />
//                     </div>
//                   </div>


//                   <div style={{ marginBottom: '10px' }}>
//                     <label>Laboratory Tests:</label>
//                     {prescriptionData.laboratory.map((test, index) => (
//                       <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
//                         <input
//                           type="text"
//                           placeholder="Lab Test Name"
//                           name="laboratory"
//                           value={test.laboratory}
//                           onChange={(e) => handleInputChange(index, 'laboratory', e)}
//                           style={{
//                             flex: 1,
//                             padding: '5px',
//                             borderRadius: '5px',
//                             border: '1px solid #ccc',
//                             marginRight: '10px'
//                           }}
//                         />
//                         {index > 0 && (
//                           <button
//                             type="button"
//                             onClick={() => handleRemoveFields(index, 'laboratory')}
//                             style={{
//                               border: 'none',
//                               background: 'red',
//                               color: 'white',
//                               borderRadius: '50%',
//                               width: '30px',
//                               height: '30px',
//                               display: 'flex',
//                               justifyContent: 'center',
//                               alignItems: 'center',
//                               cursor: 'pointer',
//                               marginRight: '5px'
//                             }}
//                           >
//                             <i className="fas fa-minus"></i>
//                           </button>
//                         )}
//                         {index === prescriptionData.laboratory.length - 1 && (
//                           <button
//                             type="button"
//                             onClick={() => handleAddFields('laboratory')}
//                             style={{
//                               border: 'none',
//                               background: 'green',
//                               color: 'white',
//                               borderRadius: '50%',
//                               width: '30px',
//                               height: '30px',
//                               display: 'flex',
//                               justifyContent: 'center',
//                               alignItems: 'center',
//                               cursor: 'pointer'
//                             }}
//                           >
//                             <i className="fas fa-plus"></i>
//                           </button>
//                         )}
//                       </div>
//                     ))}
//                   </div>



//                   <div style={{ marginBottom: '10px' }}>
//                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
//                       <label style={{ marginRight: '10px' }}>Follow Up Date:</label>
//                       <input
//                         type="date"
//                         name="followUpDate"
//                         value={prescriptionData.followUpDate}
//                         onChange={(e) =>
//                           setPrescriptionData({
//                             ...prescriptionData,
//                             followUpDate: e.target.value
//                           })
//                         }
//                         style={{
//                           padding: '5px',
//                           borderRadius: '5px',
//                           border: '1px solid #ccc',
//                           marginRight: '10px',
//                           flex: '1'
//                         }}
//                       />
//                       <button
//                         type="button"
//                         onClick={() => handleSubmit(prescriptionData)}
//                         style={{
//                           padding: '10px 20px',
//                           borderRadius: '5px',
//                           background: 'green',
//                           color: 'white',
//                           border: 'none',
//                           cursor: 'pointer'
//                         }}
//                       >
//                         Save Prescription
//                       </button>
//                       <ToastContainer />
//                     </div>
//                   </div>
//                 </div>



//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default VideoConferenceInterface;

// const styles = {

//   container: {
//     padding: '10px',
//     backgroundColor: 'transparent', // Set container background to transparent
//     border: '2px solid #7E7E7E',
//     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//     marginTop: '20px',
//     marginBottom: '10px', // Reduce bottom margin of the container
//     height: '500px', // Set the desired height of the container
//   },
//   doctorScreen: {
//     // borderRadius: '8px',
//     padding: '10px',
//     backgroundColor: '#7A7A7A',
//     height: '100%', // Set the height of the doctor's screen to 100%
//   },
//   patientScreen: {
//     // borderRadius: '8px',
//     padding: '10px',
//     backgroundColor: '#7A7A7A',
//     height: '100%', // Set the height of the patient's screen to 100%
//   },
//   videoFeed: {
//     width: '100%',
//     height: '70%', // Adjust the height of the video feed
//     borderRadius: '8px',
//     overflow: 'hidden',
//     marginBottom: '10px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   videoFeedText: {
//     color: '#fff',
//   },
//   controlsBottom: {
//     display: 'flex',
//     justifyContent: 'center',
//     marginTop: '60px', // Adjusted bottom margin of the screen's controls
//   },
//   controlButton: {
//     backgroundColor: 'transparent',
//     border: 'none',
//     cursor: 'pointer',
//     fontSize: '24px',
//     color: 'red', // Change icon color to red
//     marginRight: '10px',
//     transition: 'color 0.3s ease',
//   },
//   vitalDetails: {
//     padding: '20px',
//     borderRadius: '8px',
//     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//   },
//   tabButton: {
//     padding: '8px 16px',
//     border: 'none',
//     borderRadius: '8px',
//     color: '#fff',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   },
//   vitalDataContainer: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-between',
//   },
//   vitalDataItem: {
//     width: '30%', // Each item occupies one-third of the container's width
//     padding: '10px',
//     // border: '1px solid #ccc',
//     borderRadius: '8px',
//     marginBottom: '10px',
//   },
//   vitalLabel: {
//     fontWeight: 'bold',
//     marginBottom: '5px',
//     color: '#8D8D8D',
//     maxWidth: '100%', // Specify maximum width for the label
//     wordWrap: 'break-word', // Allow words to break if they exceed the width
//   },


//   vitalValue: {
//     fontSize: '16px',
//     backgroundColor: '#f9f9f9',
//     padding: '8px',
//     borderRadius: '8px',
//     width: '100%',
//     boxSizing: 'border-box',
//     minHeight: '40px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },

//   //============================================  css for result  ======================================================
//   table: {
//     width: '100%',
//     borderCollapse: 'collapse',
//     marginTop: '10px',
//     overflowX: 'auto', // Add horizontal overflow handling
//   },
//   tableHeaderRow: {
//     backgroundColor: '#0F60B3',
//     color: 'white',
//     borderRadius: '8px',
//     overflow: 'hidden',
//   },
//   tableHeaderLeft: {
//     padding: '8px',
//     textAlign: 'left',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in headers
//   },
//   tableHeaderCenter: {
//     padding: '8px',
//     textAlign: 'center',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in headers
//   },
//   tableHeaderRight: {
//     padding: '8px',
//     textAlign: 'right',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in headers
//   },
//   tableDataLeft: {
//     padding: '8px',
//     textAlign: 'left',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in cells
//   },
//   tableDataCenter: {
//     padding: '8px',
//     textAlign: 'center',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in cells
//   },
//   tableDataRight: {
//     padding: '8px',
//     textAlign: 'right',
//     minWidth: '150px', // Set minimum width for columns
//     whiteSpace: 'nowrap', // Prevent wrapping in cells
//   },
//   valueBox: {
//     backgroundColor: '#5596D9',
//     borderRadius: '4px',
//     padding: '6px 10px',
//     display: 'inline-block',
//     color: 'white',
//     width: '150px',
//     textAlign: 'center',
//     whiteSpace: 'nowrap', // Prevent wrapping in value boxes
//   },
//   documentLink: {
//     color: 'white',
//     textDecoration: 'none',
//     wordWrap: 'break-word', // Allow long URLs to wrap
//   },

//   //====================================================================presvcription css====================





//   mediaQueryStyles: `
//         @media only screen and (max-width: 1205px) and (min-width: 775px) {
//             .vitalLabel {
//                 font-size: 5px; // Decrease font size for smaller screens
//             }
//         }
//     `,
// };

//==============================================2----sept---

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import WebRTCClient from "./WebRTCClient";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../config/apiConfig';
import styled from 'styled-components';
const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const LabTestContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Input = styled.input`
  flex: 1;
  height: 2rem; /* Adjust height as needed */
  width: 300px; /* Adjust width as needed */
  padding: 0.5rem;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.remove ? 'red' : 'green')};
  color: white;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    opacity: 0.8;
  }
`;
const FollowUpContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
function VideoConferenceInterface() {
  const [activeTab, setActiveTab] = useState('Vitals');
  const location = useLocation();
  const [vitals, setVitals] = useState(null);
  const [testResults, setTestResults] = useState(null);
  const [prescriptions, setPrescriptions] = useState([{ id: 1 }]);
  const [patientDetails, setPatientDetails] = useState({})
  const [patientId, setPatientId] = useState(null);
  const [prescriptionData, setPrescriptionData] = useState({
    medications: [
      {
        medication: '',
        frequency: '',
        dosage: '',
        days: 0,
        laboratory: ''
      }
    ],
    laboratory: [
      {
        laboratory: ''
      }
    ],
    chiefComplaints: '',
    symptoms: '',
    advice: '',
    provisionalDiagnosis: '',
    clinicalFinding: '',
    finalDiagnosis: '',

    followUpDate: ''
  });





  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleMedicationChange = (e, index, field) => {
    const updatedMedications = [...prescriptionData.medications];
    updatedMedications[index][field] = e.target.value;
    setPrescriptionData({ ...prescriptionData, medications: updatedMedications });
  };
  useEffect(() => {
    if (location.state && location.state.patientId) {
      const { patientId } = location.state;
      fetchPatientData(patientId);
    } else {
      console.error('No patient ID provided in location state');
    }
  }, [location.state]);

  const fetchPatientData = async (patientId) => {
    const key = localStorage.getItem('jwtToken');

    if (!key) {
      console.error('No JWT token found in localStorage');
      return;
    }

    try {
      // Fetch patient vitals
      const vitalsResponse = await fetch(
        `${BASE_URL}/registerDocHs/get-patient-vitals?patientId=${patientId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Auth: `Bearer ${key}`
          }
        }
      );

      if (!vitalsResponse.ok) {
        const errorMessage = await vitalsResponse.text();
        throw new Error(
          `HTTP error! Status: ${vitalsResponse.status}, ${errorMessage}`
        );
      }

      const vitalsData = await vitalsResponse.json();
      console.log('Fetched patient vitals:', vitalsData);
      setVitals(vitalsData);
      // Fetch test results using patientHealthMetricsId from vitals
      if (vitalsData && vitalsData.id) {
        const testResultsResponse = await fetch(
          `${BASE_URL}/registerDocHs/patient-documents?patientHealthMetricsId=${vitalsData.id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Auth': `Bearer ${key}`
            }
          }
        );

        if (!testResultsResponse.ok) {
          throw new Error('Failed to fetch test results');
        }

        const testResultsData = await testResultsResponse.json();
        console.log('Fetched test results:', testResultsData);
        setTestResults(testResultsData);
      } else {
        console.error('No valid patient vitals ID found or vitalsData is null/undefined');
        // If vitalsData is not present or has no id, you might want to clear or reset testResultsData
        setTestResults(null); // Reset or clear testResultsData state
      }



      const patientDetailsResponse = await fetch(
        `${BASE_URL}/registerDocHs/patient-details-symptom?patientId=${patientId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${key}`
          }
        }
      );

      if (!patientDetailsResponse.ok) {
        throw new Error('Failed to fetch patient details');
      }

      const patientDetailsData = await patientDetailsResponse.json();
      console.log('Fetched patient details:', patientDetailsData);
      setPatientDetails(patientDetailsData || {}); // Ensure to set an empty object if response data is null or undefined
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };


  const handleInputChange = (index, type, event) => {
    const { name, value } = event.target;
    const list = [...prescriptionData[type]];
    list[index][name] = value;
    setPrescriptionData({ ...prescriptionData, [type]: list });
  };

  const handleLabChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...prescriptionData.laboratoryTests];
    list[index][name] = value;
    setPrescriptionData({ ...prescriptionData, laboratoryTests: list });
  };

  const handleAddFields = (type) => {
    if (type === 'medications') {
      setPrescriptionData({
        ...prescriptionData,
        medications: [
          ...prescriptionData.medications,
          {
            medication: '',
            frequency: '',
            dosage: '',
            days: 0,
            laboratory: ''
          }
        ]
      });
    } else if (type === 'laboratory') {
      setPrescriptionData({
        ...prescriptionData,
        laboratory: [...prescriptionData.laboratory, { laboratory: '' }]
      });
    }
  };

  const handleRemoveFields = (index, type) => {
    if (type === 'medications') {
      const list = [...prescriptionData.medications];
      list.splice(index, 1);
      setPrescriptionData({ ...prescriptionData, medications: list });
    } else if (type === 'laboratory') {
      const list = [...prescriptionData.laboratory];
      list.splice(index, 1);
      setPrescriptionData({ ...prescriptionData, laboratory: list });
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleSubmit = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      const response = await axios.post(
        `${BASE_URL}/registerDocHs/patient-prescription?patientId=${location.state.patientId}`,
        prescriptionData,
        {
          headers: {
            'Content-Type': 'application/json',
            Auth: `Bearer ${key}`
          }
        }
      );
      console.log('Prescription data submitted successfully:', response.data);

      // Show success toast notification
      toast.success('Prescription saved successfully!');

      // Reset form
      setPrescriptionData({
        medications: [
          {
            medication: '',
            frequency: '',
            dosage: '',
            days: 0,
            laboratory: ''
          }
        ],
        laboratory: [
          {
            laboratory: ''
          }
        ],
        chiefComplaints: '',
        symptoms: '',
        advice: '',
        provisionalDiagnosis: '',
        followUpDate: ''
      });
    } catch (error) {
      console.error('Error submitting prescription data:', error);
      toast.error('Error saving prescription.');
    }
  };

  const handleDocumentDownload = (documentUrl) => {
    // Function to trigger document download
    const anchor = document.createElement('a');
    anchor.href = `${BASE_URL}${documentUrl}`; // Construct full URL to the document
    anchor.target = '_blank'; // Open in new tab
    anchor.setAttribute('download', ''); // Set download attribute
    anchor.click(); // Click the anchor element to trigger download
  };




  return (
    <div style={styles.container}>
      {/* Main content */}

      <div className="row" style={{ height: '100%' }}>

        <div className="col-md-6">
          <WebRTCClient patientId={patientId} />
        </div>
        <div className="col-md-6" style={{ ...styles.vitalDetails, background: 'transparent', }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <button
              style={{
                ...styles.tabButton,
                backgroundColor: activeTab === 'Vitals' ? '#007bff' : '#BABABA',
              }}
              onClick={() => handleTabClick('Vitals')}
            >
              Vitals
            </button>
            <button
              style={{
                ...styles.tabButton,
                backgroundColor: activeTab === 'TestResults' ? '#007bff' : '#BABABA',
              }}
              onClick={() => handleTabClick('TestResults')}
            >
              Test Results
            </button>
            <button
              style={{
                ...styles.tabButton,
                backgroundColor: activeTab === 'Prescription' ? '#007bff' : '#BABABA',
              }}
              onClick={() => handleTabClick('Prescription')}
            >
              Prescription
            </button>
          </div>
          {activeTab === 'Vitals' && (
            <>
              <div style={styles.vitalDataContainer}>
                {/* Blood Group */}
                <div style={styles.vitalDataItem}>
                  <p style={styles.vitalLabel}>Blood Group</p>
                  <p style={styles.vitalValue}>{vitals ? vitals.bloodGroup : '-'}</p>
                </div>

                {/* SpO2 */}
                <div style={styles.vitalDataItem}>
                  <p style={styles.vitalLabel}>SpO2 (%)</p>
                  <p style={styles.vitalValue}>{vitals ? vitals.spO2 : '-'}</p>
                </div>

                {/* Respiration Rate */}
                <div style={styles.vitalDataItem}>
                  <p style={styles.vitalLabel}>Respiration Rate</p>
                  <p style={styles.vitalValue}>{vitals ? vitals.respirationRate : '-'}</p>
                </div>

                {/* Heart Rate */}
                <div style={styles.vitalDataItem}>
                  <p style={styles.vitalLabel}>Heart Rate</p>
                  <p style={styles.vitalValue}>{vitals ? vitals.heartRate : '-'}</p>
                </div>

                {/* Diastolic BP */}
                <div style={styles.vitalDataItem}>
                  <p style={styles.vitalLabel}>Diastolic BP (mmHg)</p>
                  <p style={styles.vitalValue}>{vitals ? vitals.diastolicBP : '-'}</p>
                </div>

                {/* Systolic BP */}
                <div style={styles.vitalDataItem}>
                  <p style={styles.vitalLabel}>Systolic BP (mmHg)</p>
                  <p style={styles.vitalValue}>{vitals ? vitals.systolicBP : '-'}</p>
                </div>

                {/* Pulse Rate */}
                <div style={styles.vitalDataItem}>
                  <p style={styles.vitalLabel}>Pulse Rate (/min)</p>
                  <p style={styles.vitalValue}>{vitals ? vitals.pulseRate : '-'}</p>
                </div>

                {/* Temperature */}
                <div style={styles.vitalDataItem}>
                  <p style={styles.vitalLabel}>Temperature</p>
                  <p style={styles.vitalValue}>{vitals ? vitals.temperature : '-'}</p>
                </div>

                {/* Hemoglobin */}
                <div style={styles.vitalDataItem}>
                  <p style={styles.vitalLabel}>Hemoglobin</p>
                  <p style={styles.vitalValue}>{vitals ? vitals.hemoglobin : '-'}</p>
                </div>


              </div>
            </>
          )}




          {activeTab === 'TestResults' && (
            <div style={{ overflowX: 'auto' }}>
              <table style={styles.table}>
                <thead>
                  <tr style={styles.tableHeaderRow}>
                    <th style={styles.tableHeaderLeft}>Document Name</th>
                    <th style={styles.tableHeaderCenter}>Document Type</th>
                    <th style={styles.tableHeaderRight}>View Document</th>
                  </tr>
                </thead>
                <tbody>
                  {testResults && testResults.length > 0 ? (
                    testResults.map((test, index) => (
                      <tr key={index}>
                        <td style={styles.tableDataLeft}>
                          <div style={styles.valueBox}>{test.documentName}</div>
                        </td>
                        <td style={styles.tableDataCenter}>
                          <div style={styles.valueBox}>{test.document}</div>
                        </td>
                        <td style={styles.tableDataRight}>
                          <div style={{ ...styles.valueBox, color: 'white' }}>
                            <p onClick={() => handleDocumentDownload(test.documentUrl)} style={styles.documentLink}>Download</p>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center' }}>
                        {testResults ? 'No test results available' : 'No test results available'} {/* Handle loading state if testResults is null */}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

{/* //========================================active tab prescription-=================================== */}
          {activeTab === 'Prescription' && (
            <>
              <div className="prescription-form">

                {patientDetails && (
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <label>Comorbidity:</label>
                      <textarea
                        name="comorbidity"
                        value={patientDetails.comorbidity || ''}
                        readOnly
                        style={{ width: "100%", height: "30px" }}
                      ></textarea>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <label>Complaints:</label>
                      <textarea
                        name="complaints"
                        value={patientDetails.complaints || ''}
                        readOnly
                        style={{ width: "100%", height: "30px" }}
                      ></textarea>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <label>Allergy:</label>
                      <textarea
                        name="allergy"
                        value={patientDetails.allergy || ''}
                        readOnly
                        style={{ width: "100%", height: "30px" }}
                      ></textarea>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <label>Other Illness:</label>
                      <textarea
                        name="otherIllness"
                        value={patientDetails.otherIllness || ''}
                        readOnly
                        style={{ width: "100%", height: "30px" }}
                      ></textarea>
                    </div>
                  </div>
                )}
                {!patientDetails && (
                  <div>Loading...</div>
                )}

                <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <label>Chief Complaints:</label>
                    <textarea
                      name="chiefComplaints"
                      value={prescriptionData.chiefComplaints}
                      onChange={(e) =>
                        setPrescriptionData({
                          ...prescriptionData,
                          chiefComplaints: e.target.value
                        })
                      }
                      style={{ width: "100%", height: "40px" }} // Full width and adjusted height
                    ></textarea>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <label>Symptoms:</label>
                    <textarea
                      name="symptoms"
                      value={prescriptionData.symptoms}
                      onChange={(e) =>
                        setPrescriptionData({
                          ...prescriptionData,
                          symptoms: e.target.value
                        })
                      }
                      style={{ width: "100%", height: "40px" }} // Full width and adjusted height
                    ></textarea>
                  </div>
                </div>



                <div style={{ height: '38vh', overflowY: 'auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px' }}>
                      <button
                        type="button"
                        onClick={() => handleAddFields('medications')}
                        style={{
                          border: 'none',
                          background: 'green',
                          color: 'white',
                          borderRadius: '50%',
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          marginTop: '10px',
                          marginBottom: '-25px'
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>


                    {prescriptionData.medications.map((medication, index) => (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', position: 'relative' }}>
                        <div style={{ marginRight: '5px' }}>
                          <label htmlFor={`medicationName-${index}`}>Medication Name:</label>
                          <input
                            type="text"
                            id={`medicationName-${index}`}
                            placeholder="Medication Name"
                            name="medication"
                            value={medication.medication}
                            onChange={(e) => handleInputChange(index, 'medications', e)}
                            style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px' }}
                          />
                        </div>

                        <div style={{ marginRight: '5px' }}>
                          <label htmlFor={`dosage-${index}`}>Dosage:</label>
                          <input
                            type="text"
                            id={`dosage-${index}`}
                            placeholder="Dosage"
                            name="dosage"
                            value={medication.dosage}
                            onChange={(e) => handleInputChange(index, 'medications', e)}
                            style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px' }}
                          />
                        </div>

                        <div style={{ marginRight: '5px' }}>
                          <label htmlFor={`frequency-${index}`}>Frequency:</label>
                          <input
                            type="text"
                            id={`frequency-${index}`}
                            placeholder="Frequency"
                            name="frequency"
                            value={medication.frequency}
                            onChange={(e) => handleInputChange(index, 'medications', e)}
                            style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px', width: '80px' }}
                          />
                        </div>

                        <div style={{ marginRight: '10px', position: 'relative' }}>
                          <label htmlFor={`days-${index}`}>Days:</label>
                          <input
                            type="number"
                            id={`days-${index}`}
                            placeholder="Days"
                            name="days"
                            value={medication.days}
                            onChange={(e) => handleInputChange(index, 'medications', e)}
                            style={{ padding: '5px', borderRadius: '5px', marginLeft: '5px', width: '60px' }}
                          />


                          {index !== 0 && (
                            <button
                              type="button"
                              onClick={() => handleRemoveFields(index, 'medications')}
                              style={{
                                border: 'none',
                                background: 'red',
                                color: 'white',
                                borderRadius: '50%',
                                width: '30px',
                                height: '30px',

                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                marginRight: '5px',

                                position: 'absolute',
                              }}
                            >
                              <i className="fas fa-minus"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', width: '100%', marginTop: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <label>Advice:</label>
                      <textarea
                        name="advice"
                        value={prescriptionData.advice}
                        onChange={(e) =>
                          setPrescriptionData({
                            ...prescriptionData,
                            advice: e.target.value
                          })
                        }
                        style={{ width: "100%", height: "50px" }} // Full width and adjusted height
                      ></textarea>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <label>Provisional Diagnosis:</label>
                      <input
                        type="text"
                        name="provisionalDiagnosis"
                        value={prescriptionData.provisionalDiagnosis}
                        onChange={(e) =>
                          setPrescriptionData({
                            ...prescriptionData,
                            provisionalDiagnosis: e.target.value
                          })
                        }
                        style={{ width: "100%", height: "50px" }} // Full width and adjusted height
                      />
                    </div>
                  </div>


<div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', width: '100%', marginTop: '10px' }}>
  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
    <label>Clinical Findings:</label>
    <textarea
      name="clinicalFinding"
      value={prescriptionData.clinicalFinding || ''}
      onChange={(e) =>
        setPrescriptionData({
          ...prescriptionData,
          clinicalFinding: e.target.value
        })
      }
      style={{ width: "100%", height: "50px" }} // Full width and adjusted height
    ></textarea>
  </div>

  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
    <label>Final Diagnosis:</label>
    <input
      type="text"
      name="finalDiagnosis"
      value={prescriptionData.finalDiagnosis || ''}
      onChange={(e) =>
        setPrescriptionData({
          ...prescriptionData,
          finalDiagnosis: e.target.value
        })
      }
      style={{ width: "100%", height: "50px" }} // Full width and adjusted height
    />
  </div>
</div>





                  <div style={{ marginBottom: '10px' }}>
                    <label>Laboratory Tests:</label>
                    {prescriptionData.laboratory.map((test, index) => (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                        <input
                          type="text"
                          placeholder="Lab Test Name"
                          name="laboratory"
                          value={test.laboratory}
                          onChange={(e) => handleInputChange(index, 'laboratory', e)}
                          style={{
                            flex: 1,
                            padding: '5px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            marginRight: '10px'
                          }}
                        />
                        {index > 0 && (
                          <button
                            type="button"
                            onClick={() => handleRemoveFields(index, 'laboratory')}
                            style={{
                              border: 'none',
                              background: 'red',
                              color: 'white',
                              borderRadius: '50%',
                              width: '30px',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              marginRight: '5px'
                            }}
                          >
                            <i className="fas fa-minus"></i>
                          </button>
                        )}
                        {index === prescriptionData.laboratory.length - 1 && (
                          <button
                            type="button"
                            onClick={() => handleAddFields('laboratory')}
                            style={{
                              border: 'none',
                              background: 'green',
                              color: 'white',
                              borderRadius: '50%',
                              width: '30px',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer'
                            }}
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        )}
                      </div>
                    ))}
                  </div>



                  <div style={{ marginBottom: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                      <label style={{ marginRight: '10px' }}>Follow Up Date:</label>
                      <input
                        type="date"
                        name="followUpDate"
                        value={prescriptionData.followUpDate}
                        onChange={(e) =>
                          setPrescriptionData({
                            ...prescriptionData,
                            followUpDate: e.target.value
                          })
                        }
                        style={{
                          padding: '5px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                          marginRight: '10px',
                          flex: '1'
                        }}
                      />
                      <button
                        type="button"
                        onClick={() => handleSubmit(prescriptionData)}
                        style={{
                          padding: '10px 20px',
                          borderRadius: '5px',
                          background: 'green',
                          color: 'white',
                          border: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        Save Prescription
                      </button>
                      <ToastContainer />
                    </div>
                  </div>
                </div>



              </div>
            </>
          )}





        </div>
      </div>
    </div>
  );
}

export default VideoConferenceInterface;

const styles = {

  container: {
    padding: '10px',
    backgroundColor: 'transparent', // Set container background to transparent
    border: '2px solid #7E7E7E',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    marginTop: '20px',
    marginBottom: '10px', // Reduce bottom margin of the container
    height: '500px', // Set the desired height of the container
  },
  doctorScreen: {
    // borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#7A7A7A',
    height: '100%', // Set the height of the doctor's screen to 100%
  },
  patientScreen: {
    // borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#7A7A7A',
    height: '100%', // Set the height of the patient's screen to 100%
  },
  videoFeed: {
    width: '100%',
    height: '70%', // Adjust the height of the video feed
    borderRadius: '8px',
    overflow: 'hidden',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoFeedText: {
    color: '#fff',
  },
  controlsBottom: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '60px', // Adjusted bottom margin of the screen's controls
  },
  controlButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '24px',
    color: 'red', // Change icon color to red
    marginRight: '10px',
    transition: 'color 0.3s ease',
  },
  vitalDetails: {
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  tabButton: {
    padding: '8px 16px',
    border: 'none',
    borderRadius: '8px',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  vitalDataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  vitalDataItem: {
    width: '30%', // Each item occupies one-third of the container's width
    padding: '10px',
    // border: '1px solid #ccc',
    borderRadius: '8px',
    marginBottom: '10px',
  },
  vitalLabel: {
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#8D8D8D',
    maxWidth: '100%', // Specify maximum width for the label
    wordWrap: 'break-word', // Allow words to break if they exceed the width
  },


  vitalValue: {
    fontSize: '16px',
    backgroundColor: '#f9f9f9',
    padding: '8px',
    borderRadius: '8px',
    width: '100%',
    boxSizing: 'border-box',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  //============================================  css for result  ======================================================
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
    overflowX: 'auto', // Add horizontal overflow handling
  },
  tableHeaderRow: {
    backgroundColor: '#0F60B3',
    color: 'white',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  tableHeaderLeft: {
    padding: '8px',
    textAlign: 'left',
    minWidth: '150px', // Set minimum width for columns
    whiteSpace: 'nowrap', // Prevent wrapping in headers
  },
  tableHeaderCenter: {
    padding: '8px',
    textAlign: 'center',
    minWidth: '150px', // Set minimum width for columns
    whiteSpace: 'nowrap', // Prevent wrapping in headers
  },
  tableHeaderRight: {
    padding: '8px',
    textAlign: 'right',
    minWidth: '150px', // Set minimum width for columns
    whiteSpace: 'nowrap', // Prevent wrapping in headers
  },
  tableDataLeft: {
    padding: '8px',
    textAlign: 'left',
    minWidth: '150px', // Set minimum width for columns
    whiteSpace: 'nowrap', // Prevent wrapping in cells
  },
  tableDataCenter: {
    padding: '8px',
    textAlign: 'center',
    minWidth: '150px', // Set minimum width for columns
    whiteSpace: 'nowrap', // Prevent wrapping in cells
  },
  tableDataRight: {
    padding: '8px',
    textAlign: 'right',
    minWidth: '150px', // Set minimum width for columns
    whiteSpace: 'nowrap', // Prevent wrapping in cells
  },
  valueBox: {
    backgroundColor: '#5596D9',
    borderRadius: '4px',
    padding: '6px 10px',
    display: 'inline-block',
    color: 'white',
    width: '150px',
    textAlign: 'center',
    whiteSpace: 'nowrap', // Prevent wrapping in value boxes
  },
  documentLink: {
    color: 'white',
    textDecoration: 'none',
    wordWrap: 'break-word', // Allow long URLs to wrap
  },

  //====================================================================presvcription css====================





  mediaQueryStyles: `
        @media only screen and (max-width: 1205px) and (min-width: 775px) {
            .vitalLabel {
                font-size: 5px; // Decrease font size for smaller screens
            }
        }
    `,
};

